import { getLocalStoreKey, LocalStoreRepository } from '../local-store';

import { CueId, CueRepository } from './cue.types';

export class LocalCueRepository implements CueRepository {
  constructor(private localStore: LocalStoreRepository) {}

  private getKey(id: CueId): string {
    return getLocalStoreKey(['@remento/cue-repository', id]);
  }

  isDismissed(id: CueId): boolean {
    const dismissed = this.localStore.getItem<boolean>(this.getKey(id));
    return dismissed ?? false;
  }

  dismiss(id: CueId): void {
    this.localStore.setItem(this.getKey(id), true);
  }

  reset(id: CueId): void {
    this.localStore.removeItem(this.getKey(id));
  }
}
