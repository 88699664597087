import { EntityResponse } from '@remento/types/entity';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';

import { CreateReactionPayload, ReactionService } from './reaction.types';

export class DefaultReactionService implements ReactionService {
  constructor(private authorizationService: AuthorizationService) {}

  async getReaction(reactionId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/reactions/${reactionId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });

    return data;
  }

  async getReactions(recordingId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/recordings/${recordingId}/reactions`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });

    return data;
  }

  async getReactionSuggestion(suggestionId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/reaction-suggestions/${suggestionId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });

    return data;
  }

  async getReactionsSuggestions(recordingId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/recordings/${recordingId}/reaction-suggestions`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });

    return data;
  }

  async createReaction(payload: CreateReactionPayload): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<EntityResponse>(`/recordings/${payload.recordingId}/reactions`, payload, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });

    return data;
  }
}
