import { AnalyticsUserProps, Events, Page } from '@/services/analytics/analytics.types';
import { IntercomBootProps, IntercomProps, IntercomService } from '@/services/analytics/intercom/intercom.types';
import { LocalStoreRepository } from '@/services/local/local-store';

let booted = false;

const INTERCOM_DATA_KEY = 'intercom-data';

export class DefaultIntercomService implements IntercomService {
  constructor(private localStorageRepository: LocalStoreRepository, private intercomAppId: string) {
    if (typeof window === 'undefined' || this.intercomAppId == null) return;

    this.loadIntercom();
    this.boot(this.localStorageRepository.getItem(INTERCOM_DATA_KEY) ?? {});
  }

  async initialize() {
    this.boot();
  }

  track(_event: Events, _payload?: Record<string, unknown>): void {
    // no-op
  }

  page(_age: Page, _payload?: Record<string, unknown>): void {
    // no-op
  }

  identify(userId: string, user: AnalyticsUserProps): void {
    if (this.intercomAppId == null) return;

    const userData = {
      user_id: userId,
      name: user.name ?? undefined,
      email: user.email ?? undefined,
      session_duration: Number.MAX_SAFE_INTEGER,
    };

    this.localStorageRepository.setItem(INTERCOM_DATA_KEY, userData);

    this.update(userData);
  }

  reset(): void {
    this.localStorageRepository.removeItem(INTERCOM_DATA_KEY);
    this.shutdown();
  }

  setChatDisplayed(display: boolean): void {
    if (display) {
      this.makeApiCall('show');
    } else {
      this.makeApiCall('hide');
    }
  }

  setBubbleDisplayed(display: boolean): void {
    this.update({ hide_default_launcher: !display });
  }

  private update(props?: IntercomProps) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.intercomSettings = { ...window.intercomSettings, ...props };
    this.makeApiCall('update', props);
  }

  private shutdown() {
    if (!booted) return;
    this.makeApiCall('shutdown');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete window.intercomSettings;
    booted = false;
  }

  private boot(props?: IntercomProps) {
    if (booted) {
      this.update(props);
      return;
    }
    const metaData: IntercomBootProps = {
      app_id: this.intercomAppId,
      ...props,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.intercomSettings = metaData;
    this.makeApiCall('boot', metaData);
    booted = true;
  }

  private makeApiCall(method: string, ...args: Array<any>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Intercom) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return window.Intercom.apply(null, [method, ...args]);
    }

    throw new Error('Intercom not initialized');
  }

  private loadIntercom() {
    const w = window;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const i = function (...args) {
        i.c(args);
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      i.q = [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      i.c = function (args) {
        i.q.push(args);
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      w.Intercom = i;

      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + this.intercomAppId;
      const x = d.getElementsByTagName('script')[0];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      x.parentNode.insertBefore(s, x);
    }
  }
}
