import { useEffect, useState } from 'react';
import {
  AssetAlternativeMetadataType,
  AssetAlternativeType,
  AssetAlternativeView,
  BaseAssetAlternativeType,
  ImageAssetAlternativeType,
} from '@remento/types/alternative';
import { AssetProcessingStatus } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';

import { useAbortableEffect } from '@/hooks';
import { useCollection, useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';

export function useAssetQuery(assetId: string | null | undefined) {
  const { assetService } = useServices();
  return useEntity(EntityType.ASSET, assetId, (id, scope) => assetService.getAsset(id, scope));
}

export function useAssetAlternativesQuery(assetId: string | null | undefined) {
  const { assetService } = useServices();
  return useCollection(EntityType.ASSET_ALTERNATIVE, assetId ? { assetId } : null, (params, scope) =>
    assetService.getAssetAlternatives(params.assetId, scope),
  );
}

export function useAlternativeType(
  alternativesIds: string[] | null | undefined,
  type: AssetAlternativeType,
  metadataType?: AssetAlternativeMetadataType,
) {
  const { assetCacheService } = useServices();
  const [alternative, setAlternative] = useState<AssetAlternativeView | null | undefined>(undefined);

  useEffect(() => {
    async function searchAlternative() {
      for (const id of alternativesIds ?? []) {
        const alternative = await assetCacheService.getAlternative(id);
        if (alternative?.type === type) {
          if (metadataType && alternative.metadata?.type !== metadataType) {
            continue;
          }
          setAlternative(alternative);
          return;
        }
      }
      setAlternative(null);
    }

    searchAlternative();
  }, [assetCacheService, alternativesIds, type, metadataType]);

  return alternative;
}

export function useAlternativeQuery(alternativeId: string | null | undefined) {
  const { assetService } = useServices();
  return useEntity(EntityType.ASSET_ALTERNATIVE, alternativeId, (id, scope) => assetService.getAlternative(id, scope));
}

export function useAlternativeFileUrl(alternativeId: string | null | undefined): string | null {
  const alternativeQuery = useAlternativeQuery(alternativeId);
  return alternativeQuery.data?.url ?? null;
}

export function useAlternativeVideoUrl(alternativeId: string | null | undefined): string | null {
  const { assetService } = useServices();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!alternativeId) {
      setUrl(null);
      return;
    }

    assetService
      .getAlternativeVideoUrl(alternativeId)
      .then(setUrl)
      .catch((error) => {
        captureException(error, true);
      });
  }, [assetService, alternativeId]);

  return url;
}

export function useAssetImageUrl(assetId: string | null, type: ImageAssetAlternativeType | BaseAssetAlternativeType) {
  const alternativesQuery = useAssetAlternativesQuery(assetId);
  const alternative = useAlternativeType(alternativesQuery.data, type);
  return useAlternativeFileUrl(alternative?.id ?? null);
}

export function usePollAssetId(
  assetId: string | null | undefined,
  waitUntilProcessingStatus: AssetProcessingStatus,
  interval?: number,
): void {
  const { assetService, entityCacheManagerService } = useServices();

  useAbortableEffect(
    (signal) => {
      if (assetId == null) {
        return;
      }

      entityCacheManagerService.observeEntity({
        entityType: EntityType.ASSET,
        entityId: assetId,
        fetchCallback: ({ signal }) => assetService.getAssetAlternatives(assetId, { signal }),
        stopCallback: (asset) => asset.processingStatus === waitUntilProcessingStatus,
        signal,
        interval,
      });
    },
    [assetService, assetId, waitUntilProcessingStatus, entityCacheManagerService, interval],
  );
}
