export type StyleKey = string | number;
export type StyleObject = Record<StyleKey, string> | Record<StyleKey, Record<StyleKey, string>>;

export function generateCssVars(a: StyleObject, prefix = ''): string {
  return Object.entries(a)
    .map(([name, value]) => {
      const fullName = prefix.length ? `${prefix}-${name}` : name;
      if (typeof value === 'object') {
        return generateCssVars(value, fullName);
      }
      return `--${fullName}: ${value};`;
    })
    .join('\n');
}
