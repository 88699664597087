import { SanityClient } from '@sanity/client';

import { CmsService } from '../cms/cms.service';

import { MarketingSource, MarketingSourceService } from './marketing-source.types';

export class MarketingSourceServiceImpl extends CmsService<MarketingSource> implements MarketingSourceService {
  constructor(client: SanityClient) {
    super(client, {
      entity: 'marketing-source',
      additionalFields: {
        id: '_id',
      },
    });
  }

  getAll(): Promise<MarketingSource[]> {
    return super.fetchAll();
  }
}
