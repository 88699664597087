import { AuditMetadata, BaseEntity, BaseEntityStatus, BaseMutation } from './base-entity';
import { EntityType } from './entity';
import { PersonName } from './person';
import { UserCommunicationChannels, UserStatus } from './user';

export enum AclGroupStatus {
  ACTIVE = 'active',
}

export enum AclGroupRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  COLLABORATOR = 'collaborator',
  VIEWER = 'viewer',
}

export const ACL_VIEW_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN, AclGroupRole.COLLABORATOR, AclGroupRole.VIEWER];
export const ACL_EDIT_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN, AclGroupRole.COLLABORATOR];
export const ACL_CREATE_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN, AclGroupRole.COLLABORATOR];
export const ACL_DELETE_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN, AclGroupRole.COLLABORATOR];

export interface AclGroup extends BaseEntity<AclGroupStatus> {
  type: EntityType;
  metadata: Record<string, any>;
  /**
   * An array of group ID. When determining if a user has privileges over a group (for example adding a member or a
   * token to that group), we determine their role based on their ACL member within that group, or any of its ancestors.
   */
  ancestors: string[];
}

export interface PublicAclGroup extends AclGroup {
  members: { id: string; userId: string }[];
}

export enum AclGroupMemberStatus {
  ACTIVE = 'active',
}

export interface AclGroupMember {
  id: string;
  status: AclGroupMemberStatus;
  audit: AuditMetadata;
  userId: string;
  groupId: string;
  role: AclGroupRole;
}

export interface PublicAclGroupMember {
  id: string;
  status: AclGroupMemberStatus;
  user: {
    id: string;
    name: PersonName | null;
    communicationChannels: UserCommunicationChannels;
    status: UserStatus | BaseEntityStatus;
    avatarUrl: string | null;
  };
  groupId: string;
  groupRefIds: Array<string>;
  groupMetadata: Record<any, any>;
  groupType: EntityType;
  role: AclGroupRole;
  audit: AuditMetadata;
}

export type AclGroupMutation = BaseMutation;

export enum AclGroupInviteeStatus {
  ACTIVE = 'active',
}

export interface AclGroupInvitee {
  id: string;
  groupId: string;
  email: string;
  role: AclGroupRole;
  status: AclGroupInviteeStatus;
  audit: AuditMetadata;
}

export enum AclGroupTokenStatus {
  ACTIVE = 'active',
}

export interface AclGroupToken {
  id: string;
  status: AclGroupTokenStatus;
  acls: Record<string, AclGroupRole>;
  metadata: Record<string, unknown>;
  expiresAt: number | null;
  audit: AuditMetadata;
}
