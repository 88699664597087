import { PromptType } from '@remento/types/project';
import dayjs from 'dayjs';

import { AnalyticsService, Events } from '../analytics.types';

import { OnboardingAnalyticsService, OnboardingCompletedEventPayload } from './onboarding-analytics.types';

export class DefaultOnboardingAnalyticsService implements OnboardingAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onOnboardingArrived(): void {
    this.analyticsService.track(Events.OnboardingArrive);
  }

  onOnboardingStarted(): void {
    this.analyticsService.track(Events.OnboardingStart);
  }

  onOnboardingPromptTypeSelected(type: PromptType): void {
    this.analyticsService.track(Events.OnboardingSubmitPromptTypeSelect, { type });
  }

  onOnboardingPromptsReviewed(count: number): void {
    this.analyticsService.track(Events.OnboardingSubmitPromptReview, { count });
  }

  onOnboardingCompleted(payload: OnboardingCompletedEventPayload): void {
    this.analyticsService.track(Events.OnboardingComplete, {
      ...payload,
      sendGift: dayjs(payload.sendGift).format('MM/DD/YYYY'),
    });
  }

  onOnboardingEnded(): void {
    this.analyticsService.track(Events.OnboardingEnd);
  }
}
