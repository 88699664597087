import { ChangeEvent, ComponentProps, forwardRef, HTMLInputTypeAttribute, useCallback, useState } from 'react';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { isBrowser } from '@/hooks/useIsBrowser';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { Fonts } from '@/styles/base/fonts';

import {
  DateIcon,
  Icon,
  Input,
  InputContainer,
  InputError,
  InputLabel,
  InputWrapper,
  TextArea,
} from './RMInput.styles';

type RMBaseInputProps = {
  /**
   * The unique input ID
   */
  id: string;
  /**
   * Input type
   */
  type?: HTMLInputTypeAttribute;
  /**
   * The label displayed alongside the input
   */
  label?: string;
  /**
   * An optional error message associated with the input
   */
  error?: string | null;
  /**
   * The font family for the input
   */
  fontFamily?: keyof typeof Fonts;
  /**
   * The icon displayed in the button
   */
  icon?: IconDefinition | null;
  /**
   * Enable or disable rendering validation errors
   */
  validation?: boolean;
};

type RMSingleInputProps = Omit<ComponentProps<'input'>, 'id'> & {
  textarea?: false;
};

type RMTextareaInputProps = Omit<ComponentProps<'textarea'>, 'id'> & {
  textarea: true;
};

export type RMInputProps = RMBaseInputProps & (RMSingleInputProps | RMTextareaInputProps);

export const RMInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, RMInputProps>(
  ({ id, type, label, error, disabled, textarea, fontFamily = 'sans', icon, validation = true, ...props }, ref) => {
    const isMobile = useIsMobileViewport();
    const isFirefox = isBrowser('firefox');
    const [textAreaHeight, setTextAreaHeight] = useState(0);

    const handleTextAreaChange = (currentTarget: EventTarget & (HTMLInputElement | HTMLTextAreaElement)) => {
      if (!currentTarget?.value) {
        setTextAreaHeight(0);
      } else {
        setTextAreaHeight(currentTarget?.scrollHeight ?? 0);
      }
    };

    const handleRef = useCallback(
      (element: HTMLInputElement | HTMLTextAreaElement | null) => {
        if (ref !== null) {
          if (typeof ref === 'function') {
            ref(element);
          } else {
            ref.current = element;
          }
        }

        if (textarea) {
          const textareaElement = element as HTMLTextAreaElement;
          setTimeout(() => handleTextAreaChange(textareaElement));
        }
      },
      [ref, textarea],
    );

    return (
      <InputContainer disabled={disabled} readOnly={props.readOnly}>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

        <InputWrapper>
          {icon && <Icon icon={icon} />}

          {textarea ? (
            <TextArea
              {...(props as ComponentProps<'textarea'>)}
              id={id}
              className={props.className}
              ref={handleRef as never}
              error={validation && !!error}
              disabled={disabled}
              fontFamily={fontFamily}
              icon={!!icon}
              style={{ height: textAreaHeight }}
              onChange={(event) => {
                props.onChange?.(event as ChangeEvent<HTMLTextAreaElement> & ChangeEvent<HTMLInputElement>);
                handleTextAreaChange(event.currentTarget);
              }}
            />
          ) : (
            <>
              <Input
                {...(props as ComponentProps<'input'>)}
                id={id}
                type={type}
                className={props.className}
                ref={ref as never}
                error={validation && !!error}
                disabled={disabled}
                fontFamily={fontFamily}
                icon={!!icon}
              />
              {type === 'date' && isMobile && !isFirefox && <DateIcon icon={faCalendar} />}
            </>
          )}
        </InputWrapper>

        {validation && error && <InputError>{error}</InputError>}
      </InputContainer>
    );
  },
);
