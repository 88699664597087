import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '@remento/types/user';

import { getSignoutPath } from '@/modules/routing';
import { useServices } from '@/Services';

export function useUser() {
  const { userService } = useServices();
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    userService.getUser().then(setUser);
    return userService.onUserChanged(setUser);
  }, [userService]);

  return user;
}

export function useSignOut() {
  const navigate = useNavigate();
  const { authService, redirectService } = useServices();

  return useCallback(
    async (redirect = true, redirectPath?: string | null) => {
      if (redirect == false) {
        await authService.signOut();
        return;
      }

      if (redirectPath != null) {
        await redirectService.registerRedirect('signed-out', redirectPath);
      }
      navigate(getSignoutPath());
    },
    [authService, navigate, redirectService],
  );
}
