import { styled } from '@linaria/react';

export const SelectInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: var(--spacing-2xs);
`;

export const SelectContent = styled.div`
  width: 18rem;
  border: 1px solid var(--inverse-border-weak);
  background-color: var(--inverse-surface);
  border-radius: var(--radius-round);
  overflow: hidden;
  box-shadow: var(--elevation-inverse-bottom-4);
  transform: translateY(72px);
  z-index: 2;

  body[data-mobile='true'] & {
    width: calc(100vw - 24px);
  }

  body[data-tablet='true'] & {
    position: fixed;
    left: var(--spacing-md);
    top: calc(3.5rem * -1);
  }
`;

export const Select = styled.div`
  display: flex;
  gap: var(--spacing-md);
  cursor: pointer;
  padding: var(--spacing-xs);
  padding-right: var(--spacing-md);
  width: 100%;
  border-radius: var(--radius-round);
  border: 1px solid var(--inverse-border-weak);
  align-items: center;
  transition: 100ms;
  user-select: none;
  z-index: 1;

  // tablet
  body[data-tablet='true'] & {
    padding-right: var(--spacing-xs);
  }

  svg {
    transition: 250ms;
  }

  :hover {
    background-color: var(--lighten-primary-hover);
  }

  :active,
  &[data-state='open'] {
    background-color: var(--lighten-primary-press);

    svg {
      rotate: 180deg;
    }
  }
`;
