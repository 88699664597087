import { styled } from '@linaria/react';

import { LottieAnimation } from '../LottieAnimation';

export const LottieLoader = styled(LottieAnimation)`
  position: relative;
  width: 6rem;
  height: 6rem;
  flex: 0 0 6rem;
`;

export const LoaderWrapper = styled.div`
  &[data-center='true'] {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
