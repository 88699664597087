import { BaseEntity, BaseMutation } from './base-entity';

interface NotificationSettings {
  reminderToAddPrompt: boolean;
  reminderToNudge: boolean;
  reminderToRecord: boolean;
  reminderToReact: boolean;
  storyRecorded: boolean;
  storyRecordedAIHighlightReel: boolean;
  reactionSent: boolean;
  timeToRecord: boolean;
  pollCreated: boolean;
}

export type EmailNotificationSettings = NotificationSettings;
export type PhoneNotificationSettings = NotificationSettings;

export enum UserNotificationSettingsStatus {
  ACTIVE = 'active',
}

export interface UserNotificationSettings extends BaseEntity<UserNotificationSettingsStatus> {
  userId: string;
  email: EmailNotificationSettings;
  phone: PhoneNotificationSettings;
}

export interface UserNotificationSettingsMutationPayload {
  email: EmailNotificationSettings;
  phone: PhoneNotificationSettings;
}

export enum UserNotificationSettingsMutationType {
  SET_SETTINGS = 'set-settings',
}

export type SetSettingsMutation = BaseMutation<
  UserNotificationSettingsMutationType.SET_SETTINGS,
  UserNotificationSettingsMutationPayload
>;

export type UserNotificationSettingsMutation = SetSettingsMutation;
