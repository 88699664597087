import { UserIdentifier } from '../../api/user/user.types';
import { AnalyticsRepository, AnalyticsUserProps, Page } from '../analytics.types';

import { DatadogTracingService, ErrorListener } from './datadog-tracing.types';

export class NoopDatadogTracingService implements DatadogTracingService, AnalyticsRepository {
  initialize(_userIdentifier: UserIdentifier): void {
    // no-op
  }

  identify(_userId: string, _user: AnalyticsUserProps): void {
    // no-op
  }

  reset(): void {
    // no-op
  }

  track(_event: string, _payload?: Record<string, unknown> | undefined): void {
    // no-op
  }

  page(_page: Page, _payload?: Record<string, unknown> | undefined): void {
    // no-op
  }

  getTracingData(): { sessionId: string; viewId: string } | undefined {
    return undefined;
  }

  wrapWebSocketUrl(url: URL): URL {
    return url;
  }

  onError(_callback: ErrorListener): () => void {
    return () => {
      // no-op
    };
  }
}
