import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getProjectPromptsPath, RementoPage } from '@/modules/routing';

import { PromptNotFoundPage } from '../components/PromptNotFoundPage/PromptNotFoundPage';

function InternalPromptNotFoundPageContainer() {
  const navigate = useNavigate();
  const params = useParams();

  // Sometimes the projectId will not be available, depending on the route.
  const handleRedirect = useCallback(() => {
    if (params.projectId != null) {
      navigate(getProjectPromptsPath(params.projectId));
      return;
    }
    navigate('/');
  }, [navigate, params.projectId]);

  return <PromptNotFoundPage onGoToPromptsPage={handleRedirect} />;
}

export function PromptNotFoundPageContainer() {
  return (
    <RementoPage type="error">
      <InternalPromptNotFoundPageContainer />
    </RementoPage>
  );
}
