import { AnalyticsService, Events } from '../analytics.types';
import { AuthProvider, UserAnalyticsService } from './user-analytics.types';

export class UserAnalyticsServiceImpl implements UserAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onSignIn(provider: AuthProvider, isNewUser: boolean): void {
    if (isNewUser) {
      this.analyticsService.track(Events.UserSignUp, { provider });
    } else {
      this.analyticsService.track(Events.UserSignIn, { provider });
    }
  }

  onSignOut(): void {
    this.analyticsService.track(Events.UserSignOut);
  }
}
