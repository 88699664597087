import { createStore, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export interface PaywallState {
  isPaywallVisible: boolean;
  projectId: string | null;
}

const store = createStore(
  subscribeWithSelector<PaywallState>(() => ({
    isPaywallVisible: false,
    projectId: null,
  })),
);

export function showProjectPaywall(projectId: string): void {
  store.setState(
    {
      isPaywallVisible: true,
      projectId,
    },
    true,
  );
}

export function closePaywall(): void {
  store.setState({ isPaywallVisible: false, projectId: null }, true);
}

export function usePaywallState(): PaywallState {
  return useStore(store);
}
