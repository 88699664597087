import {
  AnalyticsPropertyKey,
  AnalyticsPropertyRepository,
} from '@/services/analytics/analytics-property/analytics-property.types';
import { LocalStoreRepository } from '@/services/local/local-store';

const ANALYTICS_PROPERTIES_KEY = '@remento/analytics-properties';

export class AnalyticsPropertyRepositoryImpl implements AnalyticsPropertyRepository {
  constructor(private localStore: LocalStoreRepository) {}

  public set(key: AnalyticsPropertyKey, value: string): void {
    const properties = this.getAll();
    this.localStore.setItem(ANALYTICS_PROPERTIES_KEY, {
      ...properties,
      [key]: value,
    });
  }

  public multiSet(newProperties: Record<AnalyticsPropertyKey, string>): void {
    const properties = this.getAll();
    this.localStore.setItem(ANALYTICS_PROPERTIES_KEY, {
      ...properties,
      ...newProperties,
    });
  }

  public get(key: AnalyticsPropertyKey): string | null {
    const properties = this.getAll();
    return properties?.[key] ?? null;
  }

  public getAll(): Record<AnalyticsPropertyKey, string> | null {
    return this.localStore.getItem(ANALYTICS_PROPERTIES_KEY);
  }
}
