import { z } from 'zod';

export function getZodObjectPaths<T extends z.ZodTypeAny>(schema: T): string[] {
  // check if schema is nullable or optional
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional) return getZodObjectPaths(schema.unwrap());

  // check if schema is an array
  if (schema instanceof z.ZodArray) return getZodObjectPaths(schema.element);

  // check if schema is an object
  if (schema instanceof z.ZodObject) {
    const entries = Object.entries(schema.shape);
    // loop through key/value pairs
    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested = value instanceof z.ZodType ? getZodObjectPaths(value).map((subKey) => `${key}.${subKey}`) : [];
      // return nested keys
      return [key, ...nested];
    });
  }
  return [];
}
