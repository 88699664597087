import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledPhoneCollectionOnboardingDialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--surface);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 var(--spacing-3xl);
  height: 5rem;
  border-bottom: 1px solid var(--border-hairline);

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-lg);
    height: 3.5rem;
  }
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-2xl);
  max-width: 500px;
  padding: var(--spacing-xl) var(--spacing-2xl);
  overflow: auto;

  body[data-mobile='true'] & {
    padding: var(--spacing-lg) var(--spacing-md);
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
  width: 100%;
`;

export const PhoneIcon = styled.img``;
