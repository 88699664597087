import { EntityResponse, EntityType } from './entity';
import { Person } from './person';
import { User } from './user';

export enum ErrorType {
  // Generic Errors
  UNAUTHORIZED = 'unauthorized',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'not-found',
  INVALID_REQUEST = 'invalid-request',
  CONFLICT = 'conflict',
  ONGOING_MIGRATION = 'ongoing-migration',
  NETWORK_ERROR = 'network-error',
  UNKNOWN = 'unknown',
}

export interface SerializableError {
  name: string;
  message: string;
  cause?: SerializableError;
  stack?: string;
}

export interface SerializableRementoError<T = unknown> {
  message: string;
  type: ErrorType;
  retriable: boolean;
  data?: T | null;
  cause?: SerializableError | null;
}

export interface RementoErrorConstructor<T = unknown> {
  message: string;
  type: ErrorType;
  retriable: boolean;
  data?: T | null;
  cause?: Error | null;
}

export class RementoError<T = unknown> extends Error {
  public message: string;
  public type: ErrorType;
  public retriable: boolean;
  public data?: T | null;
  public cause?: Error | null;

  constructor(data: RementoErrorConstructor<T>) {
    super(data.message, { cause: data.cause });
    this.message = data.message;
    this.type = data.type;
    this.retriable = data.retriable;
    this.data = data.data;
    this.cause = data.cause;

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, RementoError.prototype);
  }
}

// ************ GENERIC ERRORS ************ \\

/**
 * This will should be used when there's no signed in user.
 */
export class UnauthorizedError extends RementoError {
  constructor(message?: string, cause?: Error | null) {
    super({
      message: message ?? 'unauthorized',
      type: ErrorType.UNAUTHORIZED,
      retriable: false,
      cause,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

/**
 * This will should be used when there's a signed in user, but the user
 * does not have permission on the resource.
 */
export class ForbiddenError extends RementoError {
  constructor(message?: string, cause?: Error | null) {
    super({
      message: message ?? 'forbidden',
      type: ErrorType.FORBIDDEN,
      retriable: false,
      cause,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export interface NotFoundErrorData {
  origin: 'entity';
  entityType: EntityType;
  entityId?: string | null;
}

export class NotFoundError extends RementoError<NotFoundErrorData> {
  constructor(message: string, data?: NotFoundErrorData | null, cause?: Error | null) {
    super({
      message,
      type: ErrorType.NOT_FOUND,
      retriable: false,
      cause,
      data,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class InvalidRequestError extends RementoError {
  constructor(message: string, cause?: Error | null) {
    super({
      message,
      type: ErrorType.INVALID_REQUEST,
      retriable: false,
      cause,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, InvalidRequestError.prototype);
  }
}

export enum ConflictErrorType {
  // Generic
  VCLOCK_MISMATCH = 'vclock-mismatch',
  // Acl
  EMAIL_ALREADY_PART_OF_ACL_GROUP = 'email-already-part-of-acl-group',
  // User
  USER_ALREADY_EXISTS = 'user-already-exists',
  STORYTELLER_ALREADY_EXISTS = 'storyteller-already-exists',
  STORYTELLER_SAME_USER = 'storyteller-same-user',
  // Story
  STORY_SOCIAL_SHARE_LINK_ALREADY_EXISTS = 'story-social-share-link-already-exists',
}

export interface VclockMismatchConflictErrorData {
  type: ConflictErrorType.VCLOCK_MISMATCH;
  updatedEntities: EntityResponse | null;
}

export interface UserAlreadyExistsConflictErrorData {
  type: ConflictErrorType.USER_ALREADY_EXISTS;
  existingUser?: User;
  existingPerson?: Person;
}

export interface GenericConflictErrorData {
  // We need to remove all types that have a custom data interface
  type: Exclude<ConflictErrorType, ConflictErrorType.VCLOCK_MISMATCH | ConflictErrorType.USER_ALREADY_EXISTS>;
}

export type ConflictErrorData =
  | VclockMismatchConflictErrorData
  | UserAlreadyExistsConflictErrorData
  | GenericConflictErrorData;

export class ConflictError extends RementoError<ConflictErrorData> {
  constructor(message: string, data: ConflictErrorData, cause?: Error | null) {
    super({
      message,
      type: ErrorType.CONFLICT,
      retriable: false,
      data,
      cause,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, ConflictError.prototype);
  }
}

export class OngoingMigrationError extends RementoError {
  constructor(message?: string, cause?: Error | null) {
    super({
      message: message ?? 'ongoing-migration',
      type: ErrorType.ONGOING_MIGRATION,
      retriable: true,
      cause,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, OngoingMigrationError.prototype);
  }
}

export class NetworkError extends RementoError {
  constructor(message?: string, cause?: Error | null) {
    super({
      message: message ?? 'network-error',
      type: ErrorType.NETWORK_ERROR,
      retriable: true,
      cause,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}

export class UnknownError extends RementoError {
  constructor(message: string, cause?: Error | null) {
    super({
      message,
      type: ErrorType.UNKNOWN,
      retriable: true,
      cause,
    });

    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, UnknownError.prototype);
  }
}

// ************ UTILS ************ \\

export function isSerializableRementoError(error: unknown): error is SerializableRementoError {
  if (typeof error !== 'object' || error === null) {
    return false;
  }

  if ('type' in error && typeof error.type === 'string') {
    return Object.values(ErrorType).includes(error.type as ErrorType);
  }

  return false;
}

export function serializeError(error: Error): SerializableError {
  return {
    name: error.name,
    message: error.message,
    cause: error.cause instanceof Error ? serializeError(error.cause) : undefined,
    stack: error.stack,
  };
}

export function serializeRementoError(error: RementoError): SerializableRementoError {
  return {
    type: error.type,
    message: error.message,
    retriable: error.retriable,
    data: error.data,
    cause: error.cause ? serializeError(error.cause) : null,
  };
}

export function deserializeRementoError(error: SerializableRementoError): RementoError {
  switch (error.type) {
    // Generic Errors
    case ErrorType.UNAUTHORIZED:
      return new UnauthorizedError(error.message, error.cause);
    case ErrorType.FORBIDDEN:
      return new ForbiddenError(error.message, error.cause);
    case ErrorType.NOT_FOUND:
      return new NotFoundError(error.message, error.data as NotFoundErrorData, error.cause);
    case ErrorType.INVALID_REQUEST:
      return new InvalidRequestError(error.message, error.cause);
    case ErrorType.CONFLICT:
      return new ConflictError(error.message, error.data as ConflictErrorData, error.cause);
    case ErrorType.ONGOING_MIGRATION:
      return new OngoingMigrationError(error.message, error.cause);
    case ErrorType.NETWORK_ERROR:
      return new NetworkError(error.message, error.cause);
    case ErrorType.UNKNOWN:
      return new UnknownError(error.message, error.cause);
  }
}
