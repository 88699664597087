import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { UserOnboardingActionType } from '@remento/types/user';
import { notNull } from '@remento/utils/array/notNull';

import { RoutePath } from '@/modules/routing/types/routing.types';
import { AclCacheService, hasRole } from '@/services/api/acl';
import { AuthorizationService } from '@/services/api/authorization';
import { ProjectCacheService } from '@/services/api/project';
import { UserService } from '@/services/api/user/user.types';

import { OnboardingDialog, OnboardingDialogRepository, OnboardingDialogService } from './onboarding-dialog.types';

export class DefaultOnboardingDialogService implements OnboardingDialogService {
  constructor(
    private repository: OnboardingDialogRepository,
    private userService: UserService,
    private projectCacheService: ProjectCacheService,
    private aclCacheService: AclCacheService,
    private authorizationService: AuthorizationService,
    private onboardingDialogs: OnboardingDialog[],
    private defaultRoutesAllowlist: RoutePath[],
  ) {}

  isOnboardingDialogAvailable(): boolean {
    return this.repository.isOnboardingDialogPermitted();
  }

  async getNextOnboardingDialog(route: RoutePath, projectId: string | null): Promise<OnboardingDialog | null> {
    const user = await this.userService.getUser();
    if (user == null) {
      return null;
    }

    const userAclGroupMemberIds = await this.aclCacheService.getCurrentUserAclGroupMembers();
    const userAclGroupMembers = await Promise.all(
      userAclGroupMemberIds.map((id) => this.aclCacheService.getAclGroupMember(id)),
    );
    const tokenPermissions = this.authorizationService.getTokenPermissions();

    for (const onboardingDialog of this.onboardingDialogs) {
      const done = user.onboardingHistory[onboardingDialog.id]?.done ?? false;
      if (done == true) {
        continue;
      }

      if (onboardingDialog.requiredRoles?.[EntityType.PROJECT] != null) {
        if (projectId == null) {
          continue;
        }

        // Check if the user has the appropriate role to the project.
        const project = await this.projectCacheService.getProject(projectId);
        if (project == null) {
          throw new NotFoundError('project-not-found', {
            origin: 'entity',
            entityType: EntityType.PROJECT,
            entityId: projectId,
          });
        }
        const userRoles = userAclGroupMembers
          .filter(notNull)
          .filter((aclGroupMember) => aclGroupMember.groupRefIds.some((groupRefId) => project.acl.includes(groupRefId)))
          .map((aclGroupMember) => aclGroupMember.role);
        const tokenRoles = project.acl
          .map((aclGroupId) => {
            return tokenPermissions[aclGroupId];
          })
          .filter(notNull);
        const allRoles = Array.from(new Set([...userRoles, ...tokenRoles]));
        const requiredRoles = onboardingDialog.requiredRoles[EntityType.PROJECT];
        const canAccess = hasRole(requiredRoles, allRoles);

        if (canAccess == false) {
          continue;
        }
      }

      if (onboardingDialog.shouldShow != null) {
        const shouldShow = await onboardingDialog.shouldShow(user);
        if (shouldShow != true) {
          continue;
        }
      }

      // Check if the dialog should be shown for the current route
      if (onboardingDialog.routesBlocklist != null && onboardingDialog.routesBlocklist.length > 0) {
        if (onboardingDialog.routesBlocklist.includes(route)) {
          continue;
        }
      }

      const routesAllowlist = [...this.defaultRoutesAllowlist, onboardingDialog.routesAllowlist];
      if (routesAllowlist.includes(route) == false) {
        continue;
      }

      return onboardingDialog;
    }

    return null;
  }

  async updateOnboardingDialogHistory(id: UserOnboardingActionType, done?: boolean | undefined): Promise<void> {
    await this.userService.updateOnboardingHistory(id, done);
    this.repository.setOnboardingDialogShown(true);
  }
}
