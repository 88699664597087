import { UserTag } from '@remento/types/user';

import { Services } from '@/Services';
import { captureException } from '@/utils/captureException';

import { getRouteFromPathname } from '../routes';
import { INTERCOM_WIDGET_ANONYMOUS_BLACKLIST, INTERCOM_WIDGET_BLACKLIST } from '../routes.config';
import {
  ActiveRoute,
  PageNotFoundRedirect,
  PageRedirect,
  RoutePath,
  RouteType,
  SignInPageRedirect,
} from '../types/routing.types';
import { isInApp, isNewSigninAttempt } from '../utils/app';

import { validateDefaultRoute } from './validator.default';
import { validateRecordRoute } from './validator.record';

export interface ValidateRoutePayload {
  pathname: string;
  params: Readonly<Record<string, string | undefined>>;
  searchParams: URLSearchParams;
  routeType: RouteType;
  services: Services;
}

interface InvalidRoute {
  valid: false;
  redirect: {
    pathname: string;
    searchParams?: URLSearchParams;
  };
}

interface ValidRoute {
  valid: true;
  searchParams: URLSearchParams;
}

export type RouteValidationResult = InvalidRoute | ValidRoute;

async function consumeURLParams(urlSearchParams: URLSearchParams, services: Services) {
  // Load access codes
  const { authorizationService, analyticsService, browserDataService } = services;

  // Set permission token
  const permissionTokens = urlSearchParams.getAll('token');
  if (permissionTokens.length) {
    authorizationService.addPermissionTokens(permissionTokens);
  }

  // Consume the campaign
  const campaign = urlSearchParams.get('campaign');
  if (campaign != null) {
    analyticsService.setProperty('campaign', campaign);
    urlSearchParams.delete('campaign');
  }

  // Restore browser data
  const browserDataSecretKey = urlSearchParams.get('backup-id');
  if (browserDataSecretKey != null) {
    try {
      await browserDataService.restoreData(browserDataSecretKey);
    } catch (e) {
      captureException(e, true);
    } finally {
      urlSearchParams.delete('backup-id');
    }
  }

  // Consume  ruid
  const utmRuid = urlSearchParams.get('utm_ruid');
  if (utmRuid !== null) {
    await analyticsService.identify(utmRuid, {}, true);
    urlSearchParams.delete('utm_ruid');
  }
}

function isRouteAllowedInApp(route: RoutePath): boolean {
  switch (route) {
    case RoutePath.DebugDeleteAccount:
    case RoutePath.Signout:
    case RoutePath.InApp:
    case RoutePath.Signin:
    case RoutePath.Root:
      return true;
  }

  return (
    route.startsWith(RoutePath.Checkout) ||
    route.startsWith(RoutePath.Onboarding) ||
    route.startsWith(RoutePath.QuestionnaireRoot)
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isOnboardingRoute(route: RoutePath): boolean {
  switch (route) {
    case RoutePath.Onboarding:
    case RoutePath.OnboardingIntro:
    case RoutePath.SetupPromptTypeSelection:
    case RoutePath.SetupTextPromptSelection:
    case RoutePath.SetupPromptsReview:
      return true;
    default:
      return false;
  }
}

/**
 * Check if the route is a main route of the app.
 * A main route is a route that the user will usually access while signed in,
 * excluding recording and project setup routes.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isMainRoute(route: RoutePath): boolean {
  switch (route) {
    case RoutePath.Stories:
    case RoutePath.Questions:
    case RoutePath.ProjectSettings:
    case RoutePath.AccountSettings:
      return true;
    default:
      return false;
  }
}

export async function validateRoute({
  pathname,
  params,
  searchParams,
  routeType,
  services,
}: ValidateRoutePayload): Promise<RouteValidationResult> {
  const currentRoutePath = getRouteFromPathname(pathname);
  if (currentRoutePath == null) {
    throw new Error('Route not found');
  }

  const urlSearchParams = new URLSearchParams(Array.from(searchParams.entries()));

  if (currentRoutePath === RoutePath.DebugDeleteAccount) {
    return {
      valid: true,
      searchParams: urlSearchParams,
    };
  }

  // Signout the user and redirect to the appropriate page
  if (currentRoutePath === RoutePath.Signout) {
    // We need to fetch the redirect before calling signOut.
    // The auth service will clear the local storage.
    const redirect = await services.redirectService.consumeRedirect('signed-out');
    await services.authService.signOut();

    return {
      valid: false,
      redirect: {
        pathname: redirect ?? '/signin',
      },
    };
  }

  // We don't want to consume any parameters when the user is signed-in
  // and navigating to the sign-in route. This scenario typically arises
  // when the user opens an email sign-in link using the incorrect browser.
  // Our intention is to enable users to copy the URL and paste it into
  // a different browser. If we were to consume the URL parameters, the
  // copied URL would lose its validity for the purpose of signing in.
  if (!(await isNewSigninAttempt(pathname as RoutePath, urlSearchParams, services))) {
    await consumeURLParams(urlSearchParams, services);
  }

  const currentRoute: ActiveRoute = {
    path: pathname,
    route: currentRoutePath,
    params: params,
    search: urlSearchParams,
  };

  // The function isInApp checks if the app is running in an embedded browser,
  // such as the LinkedIn or Instagram browser. It is not possible for a user
  // to sign-in/sign-up from these browsers - they do not support popups used
  // for signing-in with 3rd party auth providers and they cannot be opened via
  // an email link. When such browser is detected, we redirect the user to a
  // page that lets them know it is unsupported and that gives them instructions
  // on what to do..
  if (isInApp() && isRouteAllowedInApp(currentRoutePath) === false) {
    const params = new URLSearchParams();
    params.set('redirect', window.location.toString());
    return {
      valid: false,
      redirect: {
        pathname: RoutePath.InApp,
        searchParams: params,
      },
    };
  }

  const user = await services.userService.getUser();

  // If the user is signed in and does not have a name yet, redirect to the account setup page
  if (user !== null) {
    const person = await services.personCacheService.getPerson(user.personId);
    if (person?.name == null) {
      if (currentRoute.route === RoutePath.AccountName) {
        return { valid: true, searchParams: currentRoute.search };
      }

      await services.redirectService.registerRedirect('account-setup', currentRoute.path + '?' + currentRoute.search);
      return {
        valid: false,
        redirect: {
          pathname: RoutePath.AccountName,
        },
      };
    }

    //  If the user is signed in and does not have a phone yet, redirect to the account phone page
    // if ((user.onboardingHistory.phoneCollected?.done ?? false) === false) {
    //   if (currentRoute.route === RoutePath.AccountPhone) {
    //     return { valid: true, searchParams: currentRoute.search };
    //   }

    //   // Only redirect if the user is trying to access a main route or the onboarding
    //   if (isMainRoute(currentRoute.route) || isOnboardingRoute(currentRoute.route)) {
    //     await services.redirectService.registerRedirect('account-setup', currentRoute.path + '?' + currentRoute.search);
    //     return {
    //       valid: false,
    //       redirect: {
    //         pathname: RoutePath.AccountPhone,
    //       },
    //     };
    //   }
    // }
  }

  const purchaserOnboardingDone = (user?.onboardingHistory.purchaser?.done ?? false) == true;
  const collaboratorOnboardingDone = (user?.onboardingHistory.collaborator?.done ?? false) == true;

  // If the user has access to no project and no pending subscription always takes them to the onboarding intro
  if (user != null && user.availableSubscriptions.length === 0) {
    const projects = await services.projectCacheService.getProjects();

    if (
      projects.length === 0 &&
      !currentRoute.path.startsWith(RoutePath.Checkout) &&
      purchaserOnboardingDone == true &&
      currentRoute.route !== RoutePath.Invite &&
      currentRoute.route !== RoutePath.Poll &&
      currentRoute.route !== RoutePath.StoryStandalone &&
      currentRoute.route !== RoutePath.StoryHighlightReel
    ) {
      return {
        valid: false,
        redirect: {
          pathname: RoutePath.CheckoutAudience,
        },
      };
    }

    if (
      !currentRoute.path.startsWith(RoutePath.Checkout) &&
      currentRoute.route !== RoutePath.OnboardingIntro &&
      currentRoute.route !== RoutePath.Invite &&
      currentRoute.route !== RoutePath.Poll &&
      currentRoute.route !== RoutePath.StoryStandalone &&
      currentRoute.route !== RoutePath.StoryHighlightReel &&
      ((purchaserOnboardingDone == false &&
        collaboratorOnboardingDone == false &&
        (user.tags.includes(UserTag.STORYTELLER) == false || user.tags.includes(UserTag.HOST))) ||
        projects.length == 0)
    ) {
      await services.redirectService.registerRedirect('user-onboarded', currentRoute.path + '?' + currentRoute.search);

      return {
        valid: false,
        redirect: {
          pathname: RoutePath.OnboardingIntro,
        },
      };
    }
  }

  try {
    switch (routeType as RouteType) {
      case 'default':
        await validateDefaultRoute(currentRoute, services);
        break;
      case 'record':
        await validateRecordRoute(currentRoute, services);
        break;
      case 'empty':
        break;
    }

    const allIntercomBlacklistedRoutes = [
      ...INTERCOM_WIDGET_BLACKLIST,
      ...(user === null ? [...INTERCOM_WIDGET_ANONYMOUS_BLACKLIST] : []),
    ];

    services.intercomService?.setBubbleDisplayed(!allIntercomBlacklistedRoutes.includes(currentRoute.route));
    return {
      valid: true,
      searchParams: currentRoute.search,
    };
  } catch (err) {
    if (!(err instanceof PageRedirect)) {
      throw err;
    }

    if (err instanceof PageNotFoundRedirect && (await services.userService.getUser()) === null) {
      await services.redirectService.registerRedirect(
        'signed-in',
        `${currentRoute.path}${urlSearchParams.size ? '?' + urlSearchParams : ''}`,
      );
    }

    if (err instanceof SignInPageRedirect) {
      await services.redirectService.registerRedirect(
        'signed-in',
        `${err.redirectPath}${err.redirectParams.size ? '?' + err.redirectParams : ''}`,
      );
    }

    return {
      valid: false,
      redirect: {
        pathname: err.path,
        searchParams: err.params,
      },
    };
  }
}
