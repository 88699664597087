import { createContext, PropsWithChildren, useContext } from 'react';

import { NavbarStore } from './navbar.state';

const NavbarStoreContext = createContext<NavbarStore | null>(null);

export type NavbarStoreProviderProps = PropsWithChildren<{
  store: NavbarStore;
}>;

export function NavbarStoreProvider({ store, children }: NavbarStoreProviderProps) {
  return <NavbarStoreContext.Provider value={store}>{children}</NavbarStoreContext.Provider>;
}

export function useNavbarStore(): NavbarStore {
  const context = useContext(NavbarStoreContext);
  if (!context) {
    throw new Error('useNavbarStore must be used inside a NavbarStoreProvider');
  }

  return context;
}
