import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const NavbarWrapper = styled.div`
  flex: 1;
`;
