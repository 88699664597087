import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMInput } from '@/components/RMInput';

export const RMDialogContent = styled(RMDialog.Content)`
  body[data-mobile='false'][data-tablet='false'] & {
    max-width: 46.75rem;
  }
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: white;

  body[data-mobile='true'] & {
    flex-direction: column;
    padding-top: calc(var(--spacing-lg) + var(--spacing-2xl));
    padding-bottom: var(--spacing-md);
  }
`;

export const CloseButton = styled(RMCloseButton)`
  position: absolute;
  right: var(--spacing-2xs);
  top: var(--spacing-2xs);
`;

export const BodyImage = styled.img`
  max-width: 340px;
  width: 100%;
  height: 537px;
  object-fit: cover;

  body[data-mobile='true'] & {
    height: 218px;
    max-width: unset;
    padding: 0 var(--spacing-lg);
  }
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-4xl) var(--spacing-2xl) 0;

  body[data-mobile='true'] & {
    padding: var(--spacing-xl) var(--spacing-lg) 0;
  }
`;

export const Input = styled(RMInput)`
  background-color: white;
`;

export const InputButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputButtonGroupInput = styled(Input)`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const InputButtonGroupButton = styled(RMButton)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top: 1px solid var(--border-strong) !important;
  border-right: 1px solid var(--border-strong) !important;
  border-bottom: 1px solid var(--border-strong) !important;
  border-left: none !important;
`;
