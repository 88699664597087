import { DeepPartial, Paths, PathValueType } from '../@types-utils';

function parsePropertyPath(pathPart: string): string | number {
  try {
    const numPathPart = parseInt(pathPart);
    return isNaN(numPathPart) ? pathPart : numPathPart;
  } catch {
    return pathPart;
  }
}

function createParentPropertyType(pathPart: string | number) {
  return typeof pathPart === 'string' ? {} : [];
}

function cloneParent<T extends object | Array<unknown>>(parent: T): T {
  return Array.isArray(parent) ? ([...parent] as T) : { ...parent };
}

/**
 * Set the provided value as a nested property of the provided obj. If any of the objects along they path
 * do not exist, they are instantiated as an object or array, which is determined by the corresponding
 * path part (a number leads to the creation of an array, otherwise it is an object).
 */
export function set<T, P extends Paths<T> | null>(
  obj: DeepPartial<T> | null,
  path: P,
  value?: PathValueType<T, P> | null,
): DeepPartial<T> {
  const pathParts = (path as string).split('.').map(parsePropertyPath);
  if (pathParts.length === 0) {
    throw new Error('No path parts provided');
  }
  const mutableRootObj = obj == null ? createParentPropertyType(pathParts[0]) : cloneParent(obj);
  let parentObj = mutableRootObj;
  let isChanged = obj == null;
  for (let i = 0; i < pathParts.length; i++) {
    const pathPart = pathParts[i];
    if (i === pathParts.length - 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (parentObj[pathPart] === value) {
        break;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parentObj[pathPart] = value;
      isChanged = true;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (parentObj[pathPart] == null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        parentObj[pathPart] = createParentPropertyType(pathParts[i + 1]);
        isChanged = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        parentObj[pathPart] = cloneParent(parentObj[pathPart]);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parentObj = parentObj[pathPart];
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return isChanged ? mutableRootObj : obj;
}
