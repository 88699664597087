import {
  BookColor,
  BookTemplate,
  BookTemplateMutationType,
  SetBookTemplateColorMutation,
  SetBookTemplateCoverPhotoMutation,
  SetBookTemplateHideQrCodeMutation,
  SetBookTemplateStoriesMutation,
  SetBookTemplateSubTitleMutation,
  SetBookTemplateTitleMutation,
} from '@remento/types/book';
import { EntityResponse, EntityType } from '@remento/types/entity';
import { ShippingAddress, ShippingAddressValidationResult } from '@remento/types/shipping';

import { BookOrderCheckoutRequest, BookOrderCheckoutResponse, BookService } from '@/services/api/book';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';
import { EntityMutation } from '../cache';

export class DefaultBookService implements BookService {
  constructor(private authorizationService: AuthorizationService) {}

  async getBook(bookId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/books/${bookId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getBooksByProjectId(projectId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/projects/${projectId}/books`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async checkoutBookOrder(request: BookOrderCheckoutRequest): Promise<BookOrderCheckoutResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<BookOrderCheckoutResponse>(`/checkout/book`, request, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async getBookOrder(bookOrderId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/book-orders/${bookOrderId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getProjectBookOrders(projectId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/projects/${projectId}/book-orders`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getBookPreview(bookId: string, scope?: RequestScope | undefined): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/books/${bookId}/preview`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async validateAddress(address: ShippingAddress): Promise<ShippingAddressValidationResult> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<ShippingAddressValidationResult>(`/checkout/validate-address`, address, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  createSetBookTitleMutation(book: BookTemplate, title: string): EntityMutation<SetBookTemplateTitleMutation> {
    return {
      id: book.id,
      type: EntityType.BOOK_TEMPLATE,
      mutations: [
        {
          type: BookTemplateMutationType.SET_BOOK_TITLE,
          value: title,
          vclock: book.vclock,
          version: book.version,
        },
      ],
    };
  }

  createSetBookSubtitleMutation(book: BookTemplate, subtitle: string): EntityMutation<SetBookTemplateSubTitleMutation> {
    return {
      id: book.id,
      type: EntityType.BOOK_TEMPLATE,
      mutations: [
        {
          type: BookTemplateMutationType.SET_BOOK_SUBTITLE,
          value: subtitle,
          vclock: book.vclock,
          version: book.version,
        },
      ],
    };
  }

  createSetBookColorMutation(book: BookTemplate, color: BookColor): EntityMutation<SetBookTemplateColorMutation> {
    return {
      id: book.id,
      type: EntityType.BOOK_TEMPLATE,
      mutations: [
        {
          type: BookTemplateMutationType.SET_BOOK_COLOR,
          value: color,
          vclock: book.vclock,
          version: book.version,
        },
      ],
    };
  }

  createSetBookCoverPhotoMutation(
    book: BookTemplate,
    assetId: string | null,
  ): EntityMutation<SetBookTemplateCoverPhotoMutation> {
    return {
      id: book.id,
      type: EntityType.BOOK_TEMPLATE,
      mutations: [
        {
          type: BookTemplateMutationType.SET_BOOK_COVER_PHOTO,
          value: assetId,
          vclock: book.vclock,
          version: book.version,
        },
      ],
    };
  }

  createSetBookStoriesMutation(
    book: BookTemplate,
    storiesIds: string[],
  ): EntityMutation<SetBookTemplateStoriesMutation> {
    return {
      id: book.id,
      type: EntityType.BOOK_TEMPLATE,
      mutations: [
        {
          type: BookTemplateMutationType.SET_BOOK_STORIES,
          value: storiesIds,
          vclock: book.vclock,
          version: book.version,
        },
      ],
    };
  }

  createSetBookHideQrCodeMutation(
    book: BookTemplate,
    hideQrCode: boolean,
  ): EntityMutation<SetBookTemplateHideQrCodeMutation> {
    return {
      id: book.id,
      type: EntityType.BOOK_TEMPLATE,
      mutations: [
        {
          type: BookTemplateMutationType.SET_BOOK_HIDE_QR_CODE,
          value: hideQrCode,
          vclock: book.vclock,
          version: book.version,
        },
      ],
    };
  }
}
