import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const StyledNavbarRoot = styled(motion.div)`
  display: flex;
  height: 100%;
  padding: 2rem 1rem;
  width: 20rem;
  flex-direction: column;
  align-items: center;
  background-color: var(--inverse-surface);
  overflow: hidden;

  body[data-tablet='true'] & {
    width: auto;
    padding: var(--spacing-md);
    overflow: visible;
    position: relative;
    z-index: 21;
  }

  body[data-mobile='true'] & {
    width: 100vw;
    position: absolute;
    z-index: 50;
    padding-top: var(--spacing-xs);
    padding-left: var(--spacing-md);
    padding-right: var(--spacing-md);
  }
`;

export const NavbarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--spacing-xs);
  gap: var(--spacing-lg);
  width: 100%;

  &[data-mobile='true'] {
    padding: 0;
  }
`;

export const Logo = styled.img<{ mobile: boolean }>`
  width: ${({ mobile }) => (mobile ? '2.1875rem' : '2.5rem')};
  height: ${({ mobile }) => (mobile ? '2.1875rem' : '2.5rem')};
`;

export const Wordmark = styled(RMWordmark)`
  width: 7rem;
  body[data-mobile='true'] & {
    width: 6.18rem;
  }
`;

export const NavbarItems = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid var(--lighten-primary-press);
  margin-bottom: var(--spacing-2xl);
  padding-top: var(--spacing-xs);
  padding-bottom: 0;
  overflow: auto;

  body[data-mobile='true'] & {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: var(--spacing-2xl);
    gap: var(--spacing-2xs);
  }

  body[data-tablet='true'] & {
    width: 3rem;
    margin-bottom: var(--spacing-lg);
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: -2rem;
  pointer-events: none;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
`;

export const CloseIcon = styled(RMIconButton)`
  margin-left: auto;
`;

export const NewProjectButton = styled(RMButton)`
  margin-top: auto;
  margin-bottom: var(--spacing-2xl);
  flex: 0 0 48px;

  body[data-mobile='true'] & {
    margin-top: var(--spacing-2xl);
  }
`;
