import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { useRMDialog } from './RMDialog.context';

type RMDialogTransitionProps = PropsWithChildren<{
  animation?: 'rtl' | 'ltr' | 'default';
  overflow?: 'auto';
}>;

const transitions = {
  rtl: {
    initial: {
      translateX: '100%',
    },
    animate: {
      translateX: '0%',
      transition: { duration: 0.2, ease: 'easeOut' },
    },
    exit: {
      translateX: '-100%',
      transition: { duration: 0.15, ease: 'easeIn' },
    },
  },
  ltr: {
    initial: {
      translateX: '-100%',
    },
    animate: {
      translateX: '0%',
      transition: { duration: 0.2, ease: 'easeOut' },
    },
    exit: {
      translateX: '100%',
      transition: { duration: 0.15, ease: 'easeIn' },
    },
  },
  default: {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 0.25, ease: 'easeIn' },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.25, ease: 'easeIn' },
    },
  },
};

export function RMDialogTransition({ animation, overflow, children }: RMDialogTransitionProps) {
  const rootAnimation = useRMDialog()?.animation || 'default';
  const currentAnimation = animation || rootAnimation;

  return (
    <motion.div
      initial={transitions[currentAnimation].initial}
      animate={transitions[currentAnimation].animate}
      exit={transitions[currentAnimation].exit}
      style={{ overflow }}
    >
      {children}
    </motion.div>
  );
}
