import { Colors } from '@/styles/base/colors';

import { RoutePath } from './types/routing.types';

export const INTERCOM_WIDGET_BLACKLIST: RoutePath[] = [
  RoutePath.RecordingIntro,
  RoutePath.RecordingTypeSelection,
  RoutePath.Recording,
  RoutePath.RecordingFinish,
  RoutePath.Stories,
  RoutePath.Story,
  RoutePath.StoryStandalone,
];

export const INTERCOM_WIDGET_ANONYMOUS_BLACKLIST: RoutePath[] = [RoutePath.StoryHighlightReel];

export const ONBOARDING_DIALOG_BLACKLIST: RoutePath[] = [
  RoutePath.Stories,
  RoutePath.Questions,
  RoutePath.ProjectSettings,
];

export const ROUTE_THEME: Partial<Record<RoutePath, string>> = {
  [RoutePath.ProjectRecord]: Colors.neutral['93'],
  [RoutePath.ProjectRecordCustomize]: Colors.neutral['93'],
  [RoutePath.RecordingTypeSelection]: Colors.neutral['93'],
  [RoutePath.Recording]: Colors.neutral['93'],
  [RoutePath.RecordingIntro]: Colors.spruce['10'],
  [RoutePath.RecordingFinish]: Colors.spruce['10'],
  [RoutePath.RecordingPromptRecorded]: Colors.spruce['10'],
  [RoutePath.RecordingRecovery]: Colors.neutral['93'],
};
