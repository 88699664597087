import { datadogRum } from '@datadog/browser-rum';

export function captureException(error: any, catchException = false) {
  datadogRum.addError(error);

  const errorMessage = typeof error === 'string' ? error : error.message;
  console.error(`ERROR - ${errorMessage}${error.stack ? `\n${error.stack}` : ''}`);

  if (!catchException) {
    throw error;
  }
}
