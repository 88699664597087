import { PropsWithChildren } from 'react';

import { RMText } from '../RMText/RMText';

export function RMErrorText({ children }: PropsWithChildren) {
  return (
    <RMText align="center" size="s" type="sans">
      {children}
    </RMText>
  );
}
