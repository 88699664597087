import { styled } from '@linaria/react';

import { RMText } from '../RMText/RMText';

export const ToastContent = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  width: 100%;
`;

export const LottieWrapper = styled.div`
  width: 1.5rem;
`;

export const Cancel = styled(RMText)`
  margin-left: auto;
`;
