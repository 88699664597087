import { useCallback } from 'react';
import { PromptType } from '@remento/types/project';
import { createStore, useStore } from 'zustand';

import { FilestackHandle } from '@/utils/filestack';

export interface TextPromptDraft {
  id: string;
  type: PromptType.TEXT;
  question: string;
  edited: boolean;
  template: {
    id: string;
    tagIds: string[];
  } | null;
}

export interface PhotoPromptDraft {
  id: string;
  type: PromptType.PHOTO;
  question: string;
  edited: boolean;
  photo: FilestackHandle;
}

export type PromptDraft = TextPromptDraft | PhotoPromptDraft;

export interface PromptDraftsState {
  prompts: PromptDraft[];
  /**
   * Maps each template id to a list of prompts ids created from that template.
   */
  templatePromptsMap: Map<string, Set<string>>;
}

export type PromptDraftsStore = ReturnType<typeof createPromptDraftsStore>;

export function createPromptDraftsStore() {
  return createStore<PromptDraftsState>(() => ({
    prompts: [],
    templatePromptsMap: new Map(),
  }));
}

export function resetPromptDrafts(store: PromptDraftsStore): void {
  store.setState({
    prompts: [],
    templatePromptsMap: new Map(),
  });
}

export function addPromptDraft(store: PromptDraftsStore, prompt: PromptDraft): void {
  store.setState((state) => {
    const templatePromptsMap = new Map(state.templatePromptsMap);
    if (prompt.type === PromptType.TEXT && prompt.template) {
      const templatePrompts = new Set(templatePromptsMap.get(prompt.template.id) ?? []);
      templatePrompts.add(prompt.id);
      templatePromptsMap.set(prompt.template.id, templatePrompts);
    }

    return {
      ...state,
      templatePromptsMap,
      prompts: [...state.prompts, prompt],
    };
  });
}

export function removePromptDrafts(store: PromptDraftsStore, ids: string[]): void {
  store.setState((state) => {
    const prompts = state.prompts.filter((p) => ids.includes(p.id));
    if (prompts.length === 0) {
      return state;
    }

    const templatePromptsMap = new Map(state.templatePromptsMap);
    for (const prompt of prompts) {
      if (prompt.type !== PromptType.TEXT || prompt.template === null) {
        continue;
      }

      const templatePrompts = new Set(templatePromptsMap.get(prompt.template.id) ?? []);
      templatePrompts.delete(prompt.id);

      if (templatePrompts.size === 0) {
        templatePromptsMap.delete(prompt.template.id);
      } else {
        templatePromptsMap.set(prompt.template.id, templatePrompts);
      }
    }

    return {
      ...state,
      templatePromptsMap,
      prompts: state.prompts.filter((p) => {
        return ids.includes(p.id) === false;
      }),
    };
  });
}

export function removePromptDraft(store: PromptDraftsStore, id: string): void {
  return removePromptDrafts(store, [id]);
}

export function removePromptDraftByTemplateId(store: PromptDraftsStore, templateId: string): void {
  const promptsToRemove = store.getState().prompts.filter((p) => {
    if (p.type !== PromptType.TEXT) {
      return false;
    }
    return p.template?.id === templateId;
  });
  if (promptsToRemove.length === 0) {
    return;
  }
  removePromptDrafts(
    store,
    promptsToRemove.map((p) => p.id),
  );
}

export function updatePromptDraftQuestion(store: PromptDraftsStore, id: string, question: string): void {
  store.setState((state) => {
    const prompt = state.prompts.find((p) => p.id === id);
    if (!prompt) {
      return state;
    }

    // Remove the template reference when editing a text prompt from a template
    const templatePromptsMap = new Map(state.templatePromptsMap);
    if (prompt.type === PromptType.TEXT && prompt.template !== null) {
      const templatePrompts = new Set(templatePromptsMap.get(prompt.template.id) ?? []);
      templatePrompts.delete(prompt.id);

      if (templatePrompts.size === 0) {
        templatePromptsMap.delete(prompt.template.id);
      } else {
        templatePromptsMap.set(prompt.template.id, templatePrompts);
      }
    }

    return {
      ...state,
      templatePromptsMap,
      prompts: state.prompts.map((prompt) => {
        if (prompt.id !== id) {
          return prompt;
        }
        if (prompt.type === PromptType.PHOTO) {
          return { ...prompt, question, edited: true };
        }
        return {
          ...prompt,
          question,
          edited: true,
          template: null,
        };
      }),
    };
  });
}

export function updatePromptDraftPhoto(store: PromptDraftsStore, id: string, photo: FilestackHandle): void {
  store.setState((state) => ({
    ...state,
    prompts: state.prompts.map((prompt) => {
      if (prompt.id === id) {
        if (prompt.type !== PromptType.PHOTO) {
          throw new Error('Invalid prompt type');
        }
        return { ...prompt, photo, edited: true };
      }
      return prompt;
    }),
  }));
}

export function getPromptDraft(store: PromptDraftsStore, id: string): PromptDraft | null {
  return store.getState().prompts.find((p) => p.id === id) ?? null;
}

export function usePromptDraft(store: PromptDraftsStore, id: string): PromptDraft | null {
  return useStore(
    store,
    useCallback(
      (state) => {
        return state.prompts.find((p) => p.id === id) ?? null;
      },
      [id],
    ),
  );
}

export function usePromptDraftByTemplateId(store: PromptDraftsStore, templateId: string): PromptDraft | null {
  return useStore(
    store,
    useCallback(
      (state) => {
        return (
          state.prompts.find((p) => {
            if (p.type !== PromptType.TEXT) {
              return false;
            }
            return p.template?.id === templateId;
          }) ?? null
        );
      },
      [templateId],
    ),
  );
}

export function useIsPromptTemplateSelected(store: PromptDraftsStore, templateId: string): boolean {
  return useStore(
    store,
    useCallback(
      (state) => {
        return state.templatePromptsMap.has(templateId);
      },
      [templateId],
    ),
  );
}

export function useIsPromptDraftEdited(store: PromptDraftsStore, id: string): boolean {
  const prompt = usePromptDraft(store, id);
  return prompt?.edited ?? false;
}

export function isPromptDraftEdited(store: PromptDraftsStore, id: string): boolean {
  return getPromptDraft(store, id)?.edited ?? false;
}

export function usePromptDrafts(store: PromptDraftsStore): PromptDraft[] {
  return useStore(
    store,
    useCallback((state) => state.prompts, []),
  );
}

export function usePromptDraftIds(store: PromptDraftsStore): string[] {
  return useStore(
    store,
    useCallback((state) => state.prompts.map((p) => p.id), []),
  );
}

export function getPromptDrafts(store: PromptDraftsStore): PromptDraft[] {
  return store.getState().prompts;
}

export function usePromptDraftsCount(store: PromptDraftsStore): number {
  return useStore(
    store,
    useCallback((state) => state.prompts.length, []),
  );
}

export function movePromptDraft(store: PromptDraftsStore, promptId: string, newIndex: number): void {
  store.setState((state) => {
    const currentIndex = state.prompts.findIndex((p) => p.id === promptId);
    if (currentIndex === -1) {
      return state;
    }

    const newPrompts = [...state.prompts];
    newPrompts.splice(currentIndex, 1); // Remove the element from the current index
    newPrompts.splice(newIndex, 0, state.prompts[currentIndex]); // Insert the element at the new index

    return {
      ...state,
      prompts: newPrompts,
    };
  });
}
