import { PropsWithChildren } from 'react';

import { Header, Wordmark } from './RecordingLayoutHeader.styles';

type RecordingLayoutHeaderProps = PropsWithChildren<{
  dark?: boolean;
  showLogo?: boolean;
}>;

export function RecordingLayoutHeader({ dark = false, showLogo = false, children }: RecordingLayoutHeaderProps) {
  return (
    <Header data-logo={showLogo}>
      {showLogo && <Wordmark color={dark ? 'neutral' : 'primary'} />}
      {children}
    </Header>
  );
}
