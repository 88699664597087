import { AssetAlternativeMetadataType } from '@remento/types/alternative';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';

import { AssetService } from '@/services/api/asset';

import { RecordingSessionService } from './recording-session.types';

export class DefaultRecordingSessionService implements RecordingSessionService {
  constructor(private assetService: AssetService) {}

  async startUpload(sessionId: string, promptId: string): Promise<void> {
    await this.assetService.startAssetUpload({
      id: sessionId,
      type: AssetType.RECORDING_DATA,
      entity: {
        type: EntityType.PROMPT,
        id: promptId,
      },
    });
  }

  async uploadChunk(sessionId: string, chunkIndex: number, data: Uint8Array): Promise<void> {
    await this.assetService.uploadChunk(sessionId, chunkIndex, data);
  }

  async finishUpload(sessionId: string, promptId: string): Promise<void> {
    await this.assetService.finishAssetUpload(sessionId, {
      type: AssetAlternativeMetadataType.RECORDING_DATA,
      promptId,
    });
  }
}
