import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserSubscriptionStatus } from '@remento/types/user';

import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { getAccountReactivatePath } from '../routing';

import { showProjectPaywall } from './paywall.state';

export function useShowPaywall(projectId: string | null | undefined): () => boolean {
  const projectQuery = useProjectQuery(projectId);

  return useCallback(() => {
    if (projectQuery.data?.subscriptionStatus === UserSubscriptionStatus.INACTIVE) {
      showProjectPaywall(projectQuery.data.id);
      return true;
    }
    return false;
  }, [projectQuery.data?.id, projectQuery.data?.subscriptionStatus]);
}

export function useIsBillingOwner(projectId: string | null | undefined): boolean {
  const user = useUser();
  const projectQuery = useProjectQuery(projectId);

  return useMemo<boolean>(
    () => user?.refIds.includes(projectQuery.data?.ownerUserId ?? '') ?? false,
    [projectQuery.data?.ownerUserId, user?.refIds],
  );
}

export function useReactivate(projectId: string | null | undefined) {
  const navigate = useNavigate();

  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.notifications.recipientPersonIds[0]);
  const isBillingOwner = useIsBillingOwner(projectId);

  return useCallback(() => {
    if (isBillingOwner) {
      navigate(getAccountReactivatePath());
      return;
    }

    const email = 'support@remento.co';
    const subject = 'Reactivate Remento account';
    const body = [
      'Hi Remento Customer Support,',
      '',
      `I'm reaching out to reactivate the following Remento storyteller's account: ${storytellerPersonQuery.data?.name?.full}.`,
      '',
      'Can you please send me instructions on how to do so?',
    ].join('\n');

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  }, [isBillingOwner, navigate, storytellerPersonQuery.data?.name?.full]);
}
