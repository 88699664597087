import { getActiveFormattingMask, guessCountryByPartialPhoneNumber } from 'react-international-phone';
import { UserPhone } from '@remento/types/user';
import { z } from 'zod';

export function isPhoneNumberEmpty(phone: UserPhone | null | undefined): boolean {
  if (phone == null || phone.countryCode.length === 0 || phone.number.length === 0) {
    return true;
  }
  return false;
}

export function formatPhoneNumber(phone: UserPhone | null | undefined): string | null {
  if (phone == null || isPhoneNumberEmpty(phone)) {
    return null;
  }

  const fullPhone = phone.countryCode + phone.number;
  const { country } = guessCountryByPartialPhoneNumber({ phone: fullPhone });
  if (country == null) {
    return fullPhone;
  }
  const mask = getActiveFormattingMask({
    phone: fullPhone,
    country,
  });

  let result = '';
  let charsPlaced = 0;

  for (const maskChar of mask) {
    if (maskChar === '.') {
      result += phone.number[charsPlaced];
      charsPlaced += 1;
    } else {
      result += maskChar;
    }
  }

  return '+' + phone.countryCode + ' ' + result;
}

export function createUserPhoneSchema(allowEmpty = true) {
  return z
    .object({
      countryCode: z.string(),
      number: z.string(),
    })
    .refine(
      (phone) => {
        if (phone.countryCode.length === 0 || phone.number.length === 0) {
          return allowEmpty;
        }
        const { country } = guessCountryByPartialPhoneNumber({ phone: phone.countryCode + phone.number });
        if (country == null) {
          return allowEmpty;
        }
        const mask = getActiveFormattingMask({
          phone: phone.countryCode + phone.number,
          country,
        });
        const numberOfDigits = mask.split('').filter((c) => c === '.').length;
        return numberOfDigits === phone.number.length;
      },
      { message: 'Enter a valid phone number' },
    );
}
