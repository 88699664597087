import { useCallback } from 'react';

import { useSearchParams } from './useSearchParams';

export type UseQueryParamReturn<T> = [T, (value: T | null) => void];

export function useQueryParam(name: string, type: 'string'): UseQueryParamReturn<string | null>;
export function useQueryParam(name: string, type: 'string', defaultValue: string): UseQueryParamReturn<string>;
export function useQueryParam(name: string, type: 'boolean'): UseQueryParamReturn<boolean | null>;
export function useQueryParam(name: string, type: 'boolean', defaultValue: boolean): UseQueryParamReturn<boolean>;
export function useQueryParam(name: string, type: 'number'): UseQueryParamReturn<number | null>;
export function useQueryParam(name: string, type: 'number', defaultValue: number): UseQueryParamReturn<number>;

export function useQueryParam(
  name: string,
  type: 'string' | 'boolean' | 'number',
  defaultValue?: string | boolean | number,
):
  | UseQueryParamReturn<string | null>
  | UseQueryParamReturn<string>
  | UseQueryParamReturn<boolean | null>
  | UseQueryParamReturn<boolean>
  | UseQueryParamReturn<number | null>
  | UseQueryParamReturn<number> {
  const { searchParams, setSearchParam } = useSearchParams();
  const value = searchParams.get(name);

  const setValue = useCallback(
    (newValue: string | boolean | number | null) => {
      setSearchParam(name, newValue !== null ? String(newValue) : null);
    },
    [name, setSearchParam],
  );

  switch (type) {
    case 'string': {
      return [value ?? defaultValue ?? null, setValue];
    }
    case 'boolean': {
      const parsedValue = value != null ? value == 'true' : null;
      return [parsedValue ?? defaultValue ?? null, setValue];
    }
    case 'number': {
      const parsedValue = value != null ? Number(value) : null;
      return [parsedValue ?? defaultValue ?? null, setValue];
    }
  }
}

export function getQueryParam(name: string): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}
