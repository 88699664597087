import { Project } from '@remento/types/project';
import { User, UserOnboardingActionType } from '@remento/types/user';

export enum ProjectBannerType {
  PROJECT_PAUSED = 'project-paused',
  MISSING_PROMPT = 'missing-prompt',
  SUBSCRIPTION_WILL_EXPIRE = 'subscription-will-expire',
  SUBSCRIPTION_WILL_NOT_RENEW = 'subscription-will-not-renew',
  SUBSCRIPTION_INACTIVE = 'subscription-inactive',
  COUPONS_AVAILABLE = 'coupons-available',
}

export interface ProjectBanner {
  type: ProjectBannerType;
  message: string;
  style?: 'danger' | 'info';
  actionLabel?: string;
  onboardingActionType?: UserOnboardingActionType;
  closeable?: boolean;
  /**
   * Set to true if this banner should be shown again in the next
   * browser session when closed. Otherwise, it will not be shown again
   * until the user signs in.
   */
  sessionOnly?: boolean;
}

export interface ProjectBannerService {
  getProjectBanner(user: User, project: Project, pendingPromptsCount: number): Promise<ProjectBanner | null>;
  closeProjectBanner(banner: ProjectBanner): Promise<void>;
}
