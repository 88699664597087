import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMError } from '@/components/RMError';

export interface GenericErrorPageProps {
  onGoHome: () => void;
}

export function GenericErrorPage({ onGoHome }: GenericErrorPageProps) {
  return (
    <RMError.Root
      icon={faTriangleExclamation}
      title="Something went wrong"
      Action={
        <RMButton leftIcon={faHome} onClick={onGoHome}>
          Return home
        </RMButton>
      }
      Message={
        <RMError.Text>
          If the problem continues, let us know at{' '}
          <RMError.Link href="mailto:support@remento.co">support@remento.co</RMError.Link>
        </RMError.Text>
      }
    />
  );
}
