import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NoConnectionPage } from '../components/NoConnectionPage/NoConnectionPage';

export interface NoConnectionPageProps {
  onTryAgain: () => void;
}

export function NoConnectionPageContainer() {
  const navigate = useNavigate();

  const handleTryAgain = useCallback(() => {
    if (window.navigator.onLine) {
      navigate(0);
    }
  }, [navigate]);

  return <NoConnectionPage onTryAgain={handleTryAgain} />;
}
