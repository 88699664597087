import { z } from 'zod';

import { BaseEntity, BaseMutation } from './base-entity';

export enum PersonStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export interface PersonName {
  full: string;
  first: string;
  last: string;
}

export interface PersonDigitalSubscription {
  stripeSubscriptionId?: string;
  stripePaymentMethodId?: string;
  subscriptionValid: boolean;
  subscriptionExpiresOn: number;
  subscriptionStartsOn: number;
  purchaserUserId: string;
  purchaserPersonId: string;
}

export interface PersonCredits {
  book: {
    total: number;
    consumed: number;
  };
  digital?: PersonDigitalSubscription;
}

export interface Person extends BaseEntity<PersonStatus> {
  /**
   * The name of the person
   */
  name?: PersonName | null;

  /**
   * The ID of the asset used for the avatar of the person
   */
  avatarAssetId?: string | null;

  /**
   * The date of birth of the person
   */
  dateOfBirth?: number | null;

  /**
   * A list of ACL groups that grant permissions on the project.
   */
  acl: string[];
}

export enum PersonMutationType {
  SET_NAME = 'set-name',
  SET_AVATAR = 'set-avatar',
  SET_DATE_OF_BIRTH = 'set-date-of-birth',
  ADD_ACL = 'add-acl',
}

export type SetPersonNameMutation = BaseMutation<PersonMutationType.SET_NAME, Omit<PersonName, 'full'>>;
export type SetPersonAvatarMutation = BaseMutation<PersonMutationType.SET_AVATAR, string | null>;
export type SetPersonDateOfBirthMutation = BaseMutation<PersonMutationType.SET_DATE_OF_BIRTH, number | null>;
export type AddPersonAclMutation = BaseMutation<PersonMutationType.ADD_ACL, string>;

export type PersonMutation =
  | SetPersonNameMutation
  | SetPersonAvatarMutation
  | SetPersonDateOfBirthMutation
  | AddPersonAclMutation;

export const PersonMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PersonMutationType.SET_AVATAR),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(PersonMutationType.SET_NAME),
    value: z.object({
      first: z.string(),
      last: z.string(),
    }),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(PersonMutationType.SET_DATE_OF_BIRTH),
    value: z.number(),
    vclock: z.number(),
    version: z.number(),
  }),
]);
