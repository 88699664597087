import React, { PropsWithChildren, useContext } from 'react';

import { useUser } from '@/services/api/auth/auth.service.hook';

interface ABContext {
  developer: boolean;
}

export const ABProviderContext = React.createContext<ABContext | null>(null);

export function ABProvider({ children }: PropsWithChildren) {
  const user = useUser();
  const context: ABContext = {
    developer: user != null && user.communicationChannels.email.endsWith('@remento.co'),
  };
  return <ABProviderContext.Provider value={context}>{children}</ABProviderContext.Provider>;
}

export function useABContext() {
  const context = useContext(ABProviderContext);
  if (context == null) {
    throw new Error('Cannot access the AB context');
  }
  return context;
}
