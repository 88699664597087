import { Link } from 'react-router-dom';
import { styled } from '@linaria/react';

export const StyledNavbarAccount = styled(Link)`
  gap: var(--spacing-md);
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 100%;
  position: relative;
  padding: var(--spacing-xs);

  &:after {
    border-radius: var(--radius-round);
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  :hover {
    &:after {
      background-color: var(--lighten-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--lighten-primary-press);
    }
  }
`;

export const NavbarProfile = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-2xs);
`;
