import { BaseEntityStatus } from '@remento/types/base-entity';
import { EntityType } from '@remento/types/entity';
import { Prompt, PromptMutation, PromptMutationType, PromptStatus } from '@remento/types/project';
import { notNull } from '@remento/utils/array/notNull';

import { EntityCacheManagerService, OptimisticMutationManager, OptimisticMutationResult } from '../cache';

export class OptimisticPromptMutationManager implements OptimisticMutationManager<EntityType.PROMPT, PromptMutation> {
  constructor(private readonly cacheManager: EntityCacheManagerService) {}

  mutate(mutation: PromptMutation, data: Prompt): OptimisticMutationResult<EntityType.PROMPT>[] {
    switch (mutation.type) {
      case PromptMutationType.MOVE: {
        return this.doMoveMutation(data, mutation.value);
      }
      default:
        return [];
    }
  }

  private getPromptsByProject(projectId: string): Prompt[] {
    const ids = this.cacheManager.getCollection(EntityType.PROMPT, { projectId })?.value ?? [];
    return ids.map((id) => this.cacheManager.getEntity(EntityType.PROMPT, id)?.value).filter(notNull);
  }

  private doMoveMutation(movedPrompt: Prompt, newIndex: number): OptimisticMutationResult<EntityType.PROMPT>[] {
    const prompts = this.getPromptsByProject(movedPrompt.projectId);
    const oldIndex = movedPrompt.index;
    const results: OptimisticMutationResult<EntityType.PROMPT>[] = [];
    const newPromptIndexes: { id: string; index: number; status: PromptStatus | BaseEntityStatus }[] = [];

    for (const prompt of prompts) {
      if (prompt.id === movedPrompt.id) {
        newPromptIndexes.push({ id: prompt.id, index: newIndex, status: prompt.status });
        continue;
      }

      const decreaseIndex = oldIndex < newIndex && prompt.index > oldIndex && prompt.index <= newIndex;
      const increaseIndex = oldIndex > newIndex && prompt.index >= newIndex && prompt.index < oldIndex;

      if (decreaseIndex || increaseIndex) {
        newPromptIndexes.push({ id: prompt.id, index: prompt.index + (decreaseIndex ? -1 : 1), status: prompt.status });

        const currentPromptUpdatedIndex = prompt.index + (decreaseIndex ? -1 : 1);
        results.push({
          mutationType: 'entity',
          entity: {
            type: EntityType.PROMPT,
            id: prompt.id,
          },
          data: {
            ...prompt,
            index: currentPromptUpdatedIndex,
          },
        });
      } else {
        newPromptIndexes.push({ id: prompt.id, index: prompt.index, status: prompt.status });
      }
    }

    results.push({
      mutationType: 'entity',
      entity: {
        type: EntityType.PROMPT,
        id: movedPrompt.id,
      },
      data: {
        ...movedPrompt,
        index: newIndex,
      },
    });

    // We also need to update the collection, because it needs to be sorted by the index
    results.push({
      mutationType: 'collection',
      type: EntityType.PROMPT,
      params: { projectId: movedPrompt.projectId },
      ids: newPromptIndexes.sort((a, b) => a.index - b.index).map((p) => p.id),
    });

    return results;
  }
}
