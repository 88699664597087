import { RoutePath } from './types/routing.types';

function generateRouteRegex(route: string) {
  const paramRegex = /:(\w+)/g;
  let regexPattern = route.replace(paramRegex, (_, paramName) => `(?<${paramName}>[^/]+)`);
  regexPattern = `^${regexPattern}$`;
  return new RegExp(regexPattern);
}

export const Routes = Object.values(RoutePath);

const routesRegex = Routes.map((route) => generateRouteRegex(route));

export function getRouteFromPathname(pathname: string): RoutePath | null {
  const index = routesRegex.findIndex((routeRegex) => pathname.match(routeRegex));
  return Routes[index] ?? null;
}
