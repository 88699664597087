import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Body = styled(motion.div)`
  flex: 1;
  overflow: auto;

  padding: 0 var(--spacing-xl) 0 var(--spacing-xl);
  :last-child {
    padding-bottom: var(--spacing-xl);
  }

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-md) 0 var(--spacing-md);

    :last-child {
      padding-bottom: var(--spacing-xl);
    }
  }
`;
