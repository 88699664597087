import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { useIsTabletViewport } from '@/hooks/useIsMobileViewport';

import { NavbarProfile, StyledNavbarAccount } from './NavbarAccount.styles';

export interface NavbarAccountProps {
  to: string;
  userInitials: string;
  userAvatarUrl?: string | null;
  userEmail: string;
  onClick?: () => void;
}

export function NavbarAccount({ to, userInitials, userAvatarUrl, userEmail, onClick }: NavbarAccountProps) {
  const isTablet = useIsTabletViewport();

  return (
    <RMTooltip
      message={
        isTablet ? (
          <>
            <RMText type="sans" size="xxs" bold color="inverse-on-surface-primary">
              My account:
            </RMText>
            <br />
            <RMText type="sans" size="xxs" color="inverse-on-surface-primary">
              {userEmail}
            </RMText>
          </>
        ) : null
      }
    >
      <StyledNavbarAccount to={to} onClick={onClick}>
        <RMAvatar placeholder={userInitials} background="blend" shape="circle" src={userAvatarUrl} />

        {!isTablet && (
          <NavbarProfile>
            <RMText type="sans" size="xs" bold color="inverse-on-surface-primary">
              My account
            </RMText>
            <RMText type="sans" size="xxs" color="inverse-on-surface-secondary" style={{ wordBreak: 'break-word' }}>
              {userEmail}
            </RMText>
          </NavbarProfile>
        )}
      </StyledNavbarAccount>
    </RMTooltip>
  );
}
