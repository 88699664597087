import { DeleteMutation } from '@remento/types/base-entity';
import { EntityResponse } from '@remento/types/entity';
import {
  AddStoryImageMutation,
  RemoveStoryImageMutation,
  SetStorySummaryMutation,
  SetStoryTitleMutation,
  Story,
  StoryDataType,
  StoryGenerateSummaryPayload,
  StoryShareLinkType,
} from '@remento/types/story';

import { RequestScope } from '../api.types';
import { EntityMutation } from '../cache';

export enum StoryIssueType {
  SHORT_SUMMARY = 'short-summary',
  LOW_RESOLUTION_IMAGE = 'low-resolution-image',
  MISSING_TITLE = 'missing-title',
}

export interface StoryIssue {
  storyId: string;
  type: StoryIssueType;
}

export const STORY_ISSUE_TITLE: Record<StoryIssueType, string> = {
  [StoryIssueType.LOW_RESOLUTION_IMAGE]: 'Low resolution image',
  [StoryIssueType.MISSING_TITLE]: 'Missing title',
  [StoryIssueType.SHORT_SUMMARY]: 'Story text is missing or very short',
};

export const STORY_ISSUE_DESCRIPTION: Record<StoryIssueType, string> = {
  [StoryIssueType.LOW_RESOLUTION_IMAGE]:
    'This story’s image has a resolution that is lower than our recommended 816 by 960 pixels and may appear blurry in your book. We recommend replacing it with a higher resolution image.',
  [StoryIssueType.MISSING_TITLE]: 'This story has no title.',
  [StoryIssueType.SHORT_SUMMARY]: 'This story is missing written text.',
};

export interface StoryService {
  getStory(storyId: string, scope?: RequestScope): Promise<EntityResponse>;
  getProjectStories(bookId: string, scope?: RequestScope): Promise<EntityResponse>;
  getPromptStory(promptId: string, scope?: RequestScope): Promise<EntityResponse>;

  regenerateShareLink(storyId: string): Promise<string>;
  getShareLink(
    storyId: string,
    type: StoryShareLinkType.SOCIAL | StoryShareLinkType.HIGHLIGHT,
    scope?: RequestScope,
  ): Promise<string>;

  getStoryRecordByPromptId(promptId: string, scope?: RequestScope): Promise<EntityResponse>;

  createSetStoryTitleMutation(
    story: Story,
    value: string,
    dataType: StoryDataType,
    settings: Record<string, string | undefined>,
  ): EntityMutation<SetStoryTitleMutation>[];
  createSetStorySummaryMutation(
    story: Story,
    value: string,
    dataType: StoryDataType,
    settings: Record<string, string | undefined>,
  ): EntityMutation<SetStorySummaryMutation>[];
  createDeleteStoryMutation(story: Story): DeleteMutation;
  createDeleteStoryPhotoMutation(story: Story, removedAssetId: string): EntityMutation<RemoveStoryImageMutation>[];
  createAddStoryPhotoMutation(story: Story, removedAssetId: string): EntityMutation<AddStoryImageMutation>[];

  deleteStory(projectId: string, mutation: DeleteMutation): Promise<EntityResponse>;

  generateStorySummary(
    storyId: string,
    payload: StoryGenerateSummaryPayload,
    onMessageText: (textChunk: string) => void,
    signal?: AbortSignal,
  ): Promise<void>;
}

export interface StoryCacheService {
  getStory(storyId: string): Promise<Story | null>;
  getPromptStory(promptId: string): Promise<Story | null>;
  getProjectStories(projectId: string): Promise<string[]>;

  loadStoryRecordByPromptId(promptId: string): Promise<void>;
  invalidateStoryRecordByPromptIdCache(promptId: string): Promise<void>;

  regenerateShareLink(storyId: string): Promise<string>;

  deleteStory(storyId: string, mutation: DeleteMutation): Promise<void>;

  getStoryIssues(story: Story): Promise<StoryIssue[]>;
}
