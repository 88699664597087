import { useCallback, useSyncExternalStore } from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

import { Comparator, equalityCompare, getState, Select, StateStore, StateType, subscribe } from './index';

export function useStore<S extends StateType, SS = S>(store: StateStore<S>, delayed = false) {
  const observer = useCallback((cb: () => void) => subscribe(store, cb, delayed), [store]);
  const getter = useCallback(() => getState(store), [store]);
  return useSyncExternalStore(observer, getter);
}

export function useStoreSelector<S extends StateType, SS = S>(
  store: StateStore<S>,
  select: Select<S, SS>,
  delayed = false,
  comparator: Comparator<SS> = equalityCompare,
) {
  const observer = useCallback((cb: () => void) => subscribe(store, cb, delayed), [store]);
  const getter = useCallback(() => getState(store), [store]);
  return useSyncExternalStoreWithSelector(observer, getter, getter, select, comparator);
}
