import { EntityType } from '@remento/types/entity';
import { Recording } from '@remento/types/recording';
import { QueryClient } from '@tanstack/react-query';

import { RecordingCacheService, RecordingService } from '@/services/api/recording/recording.types';

import { EntityCacheManagerService } from '../cache/entity-cache-manager.types';

export class DefaultRecordingCacheService implements RecordingCacheService {
  constructor(
    private remoteService: RecordingService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  getRecording(recordingId: string): Promise<Recording | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.RECORDING, recordingId, () =>
        this.remoteService.getRecording(recordingId),
      ),
    );
  }
}
