import { createContext, PropsWithChildren, useContext } from 'react';

export interface RMDialogContextData {
  variant?: 'full-screen' | 'regular' | 'large' | 'extra-large';
  animation?: 'ltr' | 'rtl' | 'default';
}

type RMDialogProviderProps = PropsWithChildren<{
  value: RMDialogContextData;
}>;

export const RMDialogContext = createContext<RMDialogContextData | null>(null);

export function RMDialogProvider({ value, children }: RMDialogProviderProps) {
  return <RMDialogContext.Provider value={value}>{children}</RMDialogContext.Provider>;
}

export function useRMDialog(): RMDialogContextData {
  const context = useContext(RMDialogContext);
  if (!context) {
    throw new Error('RMDialogProvider must be used within a RMDialogContext');
  }

  return context;
}
