import { useCallback } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

import { getQueryParam } from '@/hooks/useQueryParam';

export interface ReferralState {
  isDialogOpen: boolean;
}

export type ReferralStore = StoreApi<ReferralState>;

export function createReferralStore(): ReferralStore {
  return createStore<ReferralState>(() => ({ isDialogOpen: getQueryParam('referral-open') === 'true' }));
}

export function openReferralDialog(store: ReferralStore): void {
  store.setState({ isDialogOpen: true }, true);
}

export function closeReferralDialog(store: ReferralStore): void {
  store.setState({ isDialogOpen: false }, true);
}

export function useIsReferralDialogOpen(store: ReferralStore): boolean {
  return useStore(
    store,
    useCallback((s) => s.isDialogOpen, []),
  );
}
