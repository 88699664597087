import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/Services';

import { useUser } from '../auth/auth.service.hook';

export function useReferralLink() {
  const { referralService } = useServices();
  const user = useUser();

  return useQuery({
    queryKey: ['referral-link'],
    queryFn: async ({ signal }) => {
      const { link } = await referralService.getReferralLink({ signal });
      return link;
    },
    enabled: user != null,
  });
}
