import { api } from '@/services/api/api';
import { BrowserDataService } from '@/services/api/browser-data/browser-data.types';
import { LocalStoreRepository } from '@/services/local/local-store';
import { secureUuid } from '@/utils/uuid';

export const DUMP_SECRET_KEY = '@remento/backup-dump-secret';

export class BrowserDataServiceImpl implements BrowserDataService {
  constructor(
    private persistentStore: LocalStoreRepository,
    private localStores: Record<string, LocalStoreRepository>,
  ) {}

  async backupData(prefixes = ['@remento']): Promise<string> {
    const backupId = secureUuid();
    const localStorageDump: Record<string, Record<string, string>> = {};

    for (const [storeType, store] of Object.entries(this.localStores)) {
      localStorageDump[storeType] = {};

      for (const [key, value] of store.entries()) {
        const shouldBackupKey = prefixes.some((prefix) => key.startsWith(prefix));
        if (shouldBackupKey === false) {
          continue;
        }
        localStorageDump[storeType][key] = value;
      }
    }

    await api.post(`/browser-data/${backupId}`, JSON.stringify(localStorageDump), {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    this.persistentStore.setItem(DUMP_SECRET_KEY, backupId);

    return backupId;
  }

  async restoreData(secret: string): Promise<void> {
    const localSecret = this.persistentStore.getItem(DUMP_SECRET_KEY);
    if (secret === localSecret) return;

    const { data } = await api.get(`/browser-data/${secret}`);
    const parsedData = JSON.parse(data.data);
    for (const [storeType, store] of Object.entries(parsedData) as [string, Record<string, string>][]) {
      for (const [key, value] of Object.entries(store)) {
        this.localStores[storeType]?.setItem(key, value);
      }
    }
  }
}
