import { styled } from '@linaria/react';

export const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  gap: var(--spacing-2xs);
`;

export const Dot = styled.div`
  background-color: var(--on-primary);
  width: 6px;
  height: 6px;
  border-radius: var(--radius-full);
  animation: bouncing-loader 0.6s infinite alternate;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bouncing-loader {
    from {
      transform: translateY(4px);
    }
    to {
      transform: translateY(-4px);
    }
  }
`;
