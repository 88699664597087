import { useCallback } from 'react';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import { EntityType } from '@remento/types/entity';
import {
  ForbiddenError,
  NetworkError,
  NotFoundError,
  OngoingMigrationError,
  UnauthorizedError,
} from '@remento/types/error';

import { GenericErrorPage } from './modules/error/components/GenericErrorPage/GenericErrorPage';
import { NotFoundPage } from './modules/error/components/NotFoundPage/NotFoundPage';
import { StoryNotFoundPage } from './modules/error/components/StoryNotFoundPage/StoryNotFoundPage';
import { MaintenancePageContainer } from './modules/error/containers/MaintenancePage.container';
import { NoConnectionPageContainer } from './modules/error/containers/NoConnectionPage.container';
import { PollExpiredPageContainer } from './modules/error/containers/PollExpiredPage.container';
import { PromptNotFoundPageContainer } from './modules/error/containers/PromptNotFoundPage.container';
import { UnauthorizedPageContainer } from './modules/error/containers/UnauthorizedPage.container';

// This will only catch errors from the createServices, route validations and useQuery hooks.
// Mutations should be handled manually when executing the mutation.
export function AppRouterErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  const handleRedirectHome = useCallback(
    (forceRefresh: boolean) => {
      navigate('/');

      if (forceRefresh === true) {
        navigate(0);
      }
    },
    [navigate],
  );

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFoundPage onGoHome={() => handleRedirectHome(false)} />;
    }
  }

  if (error instanceof ForbiddenError || error instanceof UnauthorizedError) {
    return <UnauthorizedPageContainer />;
  }

  if (error instanceof OngoingMigrationError) {
    return <MaintenancePageContainer />;
  }

  if (error instanceof NetworkError) {
    return <NoConnectionPageContainer />;
  }

  if (error instanceof NotFoundError) {
    if (error.data?.entityType === EntityType.STORY) {
      return <StoryNotFoundPage onGoHome={() => handleRedirectHome(false)} />;
    }
    if (error.data?.entityType === EntityType.PROMPT) {
      return <PromptNotFoundPageContainer />;
    }
    if (error.data?.entityType === EntityType.POLL) {
      return <PollExpiredPageContainer finished={false} pollId={error.data.entityId ?? ''} />;
    }

    return <NotFoundPage onGoHome={() => handleRedirectHome(false)} />;
  }

  return <GenericErrorPage onGoHome={() => handleRedirectHome(true)} />;
}
