import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { PromptType } from '@remento/types/project';

import { useUser } from '@/services/api/auth/auth.service.hook';

import { useInputValue } from '../form/input';

import { useOnboardingForm } from './onboarding.form.context';

interface OnboardingContextState {
  storytellerFirstName: string;
  promptsType: PromptType | null;
  setPromptsType: (promptTypes: PromptType) => void;
}

const OnboardingContext = createContext<OnboardingContextState | null>(null);

export type OnboardingContextProviderProps = PropsWithChildren<unknown>;

export function OnboardingContextProvider({ children }: OnboardingContextProviderProps) {
  const user = useUser();
  const onboardingForm = useOnboardingForm();
  const customStorytellerFirstName = useInputValue(onboardingForm, 'recipient.person.firstName');
  const storytellerFirstName = useMemo(() => {
    if (customStorytellerFirstName) {
      return customStorytellerFirstName;
    }
    return user?.availableSubscriptions[0]?.storyteller?.firstName ?? '';
  }, [customStorytellerFirstName, user?.availableSubscriptions]);

  const [promptsType, setPromptsType] = useState<PromptType | null>(null);

  const value = useMemo(
    () => ({ storytellerFirstName, promptsType, setPromptsType }),
    [promptsType, storytellerFirstName],
  );

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useOnboardingContext(): OnboardingContextState {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('useOnboardingContext must be used inside a OnboardingContextProvider');
  }
  return context;
}
