import { styled } from '@linaria/react';

export type RMIconButtonColor = 'primary' | 'neutral' | 'white' | 'danger';
export type RMIconButtonBackgroundColor = 'transparent' | 'neutral' | 'white' | 'dark';
export type RMIconButtonStateColor = 'darken-primary' | 'darken-neutral' | 'lighten-neutral' | 'lighten-white';
export type RMIconButtonOutlineColor = 'weak' | 'strong';
export type RMIconButtonSize = 'lg' | 'sm' | 'xl' | 'xs';

interface IconButtonProps {
  color: RMIconButtonColor;
  backgroundColor: RMIconButtonBackgroundColor;
  stateColor: RMIconButtonStateColor;
  outline: boolean | RMIconButtonOutlineColor;
  shadow: boolean;
  size: RMIconButtonSize;
}

function getBackgroundColor(props: IconButtonProps): string {
  switch (props.backgroundColor) {
    case 'transparent':
      return 'transparent';
    case 'neutral':
      return 'var(--surface-dim)';
    case 'white':
      return 'var(--color-white-100-8)';
    case 'dark':
      return 'var(--scrim-strong)';
  }
}

function getColor(props: IconButtonProps): string {
  switch (props.color) {
    case 'primary':
      return 'var(--primary)';
    case 'neutral':
      return 'var(--on-surface-tertiary)';
    case 'white':
      return 'var(--inverse-on-surface-primary)';
    case 'danger':
      return 'var(--danger)';
  }
}

function getOutlineColor(props: IconButtonProps): string {
  switch (props.outline) {
    case false:
      return 'transparent';
    case true:
    case 'weak':
      return 'var(--border-weak)';
    case 'strong':
      return 'var(--border-strong)';
  }
}

type ButtonState = 'hover' | 'press';
const stateColors: Record<RMIconButtonStateColor, Record<ButtonState, string | null>> = {
  'darken-neutral': {
    hover: 'var(--darken-neutral-hover)',
    press: 'var(--darken-neutral-press)',
  },
  'darken-primary': {
    hover: 'var(--darken-primary-hover)',
    press: 'var(--darken-primary-press)',
  },
  'lighten-neutral': {
    hover: 'var(--lighten-neutral-hover)',
    press: 'var(--lighten-neutral-press)',
  },
  'lighten-white': {
    hover: 'var(--lighten-white-hover)',
    press: 'var(--lighten-white-press)',
  },
};

function getStateColor(state: ButtonState) {
  return (props: IconButtonProps) => {
    return stateColors[props.stateColor][state] ?? 'unset';
  };
}

function getSize(props: IconButtonProps): string {
  switch (props.size) {
    case 'xl':
      return '48px';
    case 'lg':
      return '40px';
    case 'sm':
      return '32px';
    case 'xs':
      return '24px';
  }
}

const dropshadows = [
  'drop-shadow(rgba(0, 0, 0, 0.15) 1px 0px 0px)',
  'drop-shadow(rgba(0, 0, 0, 0.15) 0px 1px 0px)',
  'drop-shadow(rgba(0, 0, 0, 0.15) -1px 0px 0px)',
  'drop-shadow(rgba(0, 0, 0, 0.15) 0px -1px 0px)',
];

export const IconButton = styled.button<IconButtonProps>`
  position: relative;
  width: ${getSize};
  height: ${getSize};
  flex: 0 0 ${getSize};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${getBackgroundColor};
  border-radius: var(--radius-full);
  border-width: 1px;
  border-style: solid;
  border-color: ${getOutlineColor};
  font-size: 1rem;
  box-sizing: border-box;
  backdrop-filter: ${({ shadow }) => (shadow ? 'blur(10px)' : 'none')};
  cursor: pointer;

  svg,
  img {
    color: ${getColor};
    z-index: 1;
    filter: ${({ shadow }) => (shadow ? dropshadows.join(' ') : 'none')} !important;
  }

  &::after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  :hover {
    &::after {
      background-color: ${getStateColor('hover')};
    }
  }

  :active {
    &::after {
      background-color: ${getStateColor('press')};
    }
  }
`;
