import LaptopSrc from '@/assets/laptop.svg';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { Container, Description, Laptop } from './MaintenancePage.styles';

export function MaintenancePage() {
  return (
    <Container>
      <Laptop src={LaptopSrc} />
      <RMSpacer spacing="2xl" direction="column" />
      <RMText type="serif" size="l" color="on-surface-primary">
        We'll be back soon!
      </RMText>
      <RMSpacer spacing="md" direction="column" />
      <Description>
        <RMText type="sans" size="s" color="on-surface-primary">
          Our web app is temporarily offline for scheduled maintenance.
          <br />
          This page will automatically refresh when we're ready.
          <br />
          Thank you for your understanding.
        </RMText>
      </Description>
    </Container>
  );
}
