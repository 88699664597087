import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 var(--spacing-2xl);

  > span {
    text-align: center;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    text-align: center;
  }
`;

export const Laptop = styled.img`
  width: 100%;
`;
