import { notNull } from '@remento/utils/array/notNull';

import { LocalStoreRepository } from './local-store.types';

export class LocalSessionStoreRepository implements LocalStoreRepository {
  getItem<T = string>(key: string): T | null {
    const value = sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  setItem<T = string>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(prefix?: string) {
    if (prefix) {
      const keys = this.keys(prefix);
      for (const key of keys) {
        this.removeItem(key);
      }
    } else {
      sessionStorage.clear();
    }
  }

  keys(prefix?: string): string[] {
    let keys = Object.keys(sessionStorage);
    if (prefix) {
      keys = keys.filter((key) => key.startsWith(prefix));
    }
    return keys;
  }

  entries<T = string>(prefix?: string): [string, T][] {
    const keys = this.keys(prefix);
    return keys
      .map((key) => {
        try {
          return [key, this.getItem<T>(key) as T];
        } catch {
          return [key, sessionStorage.getItem(key)];
        }
      })
      .filter(notNull) as [string, T][];
  }
}
