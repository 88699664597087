import { StoryPerspectiveType } from '@remento/types/story';

import { AnalyticsService, Events } from '../analytics.types';

import { ProjectAnalyticsService } from './project-analytics.types';

export class DefaultProjectAnalyticsService implements ProjectAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onProjectDefaultPerspectiveChanged(perspective: StoryPerspectiveType, origin: 'onboarding' | 'settings'): void {
    this.analyticsService.track(Events.ProjectDefaultPespectiveChange, {
      perspective,
      origin,
    });
  }

  onProjectDefaultPerspectiveDialogViewed(): void {
    this.analyticsService.track(Events.ProjectDefaultPespectiveDialogViewed);
  }
}
