import { ComponentProps } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMTooltip } from '../RMTooltip/RMTooltip';

import {
  IconButton,
  RMIconButtonBackgroundColor,
  RMIconButtonColor,
  RMIconButtonOutlineColor,
  RMIconButtonSize,
  RMIconButtonStateColor,
} from './RMIconButton.styles';

export interface RMIconButtonProps extends ComponentProps<'button'> {
  /**
   * The icon displayed in the button
   */
  icon?: IconDefinition;
  /**
   * The color of the icon
   */
  color?: RMIconButtonColor;
  /**
   * The background color of the button
   */
  backgroundColor?: RMIconButtonBackgroundColor;
  /**
   * The state color of the button
   */
  stateColor?: RMIconButtonStateColor;
  /**
   * Determines if the button is outlined
   */
  outline?: boolean | RMIconButtonOutlineColor;
  /**
   * Determines the html element type of the button
   * Used to avoid nesting buttons.
   */
  as?: React.ElementType;
  /**
   * Determines tooltip on hover the button
   */
  tooltip: {
    position: 'top' | 'right' | 'bottom' | 'left';
    label: string;
  } | null;
  /**
   * Determines if the icon has shadow
   */
  shadow?: boolean;
  size?: RMIconButtonSize;
  iconSize?: RMIconButtonSize;
}

/**
 * Renders an icon button/;''
 */
export function RMIconButton({
  icon,
  color = 'primary',
  backgroundColor = 'neutral',
  stateColor = 'darken-neutral',
  size = 'lg',
  iconSize = size,
  outline = false,
  as,
  shadow = false,
  children,
  tooltip,
  ...props
}: RMIconButtonProps) {
  const button = (
    <IconButton
      color={color}
      backgroundColor={backgroundColor}
      stateColor={stateColor}
      outline={outline}
      shadow={shadow}
      as={as}
      size={size}
      {...props}
    >
      {icon && <FontAwesomeIcon icon={icon} size={iconSize} />}
      {children}
    </IconButton>
  );

  if (tooltip == null) return button;

  return (
    <RMTooltip message={tooltip.label} side={tooltip.position}>
      {button}
    </RMTooltip>
  );
}
