import { faHome } from '@fortawesome/pro-solid-svg-icons';

import pollExpiredSrc from '@/assets/poll-expired.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { Body, Footer, Image, Page } from './PollExpiredPage.styles';

export interface PollExpiredPageProps {
  finished: boolean;
  onRedirect: () => void;
}

export function PollExpiredPage({ finished, onRedirect }: PollExpiredPageProps) {
  return (
    <Page>
      <Body>
        <Image src={pollExpiredSrc} />
        <RMSpacer direction="column" spacing="2xl" />

        <RMText type="sans" size="m" bold color="on-surface-primary">
          This poll has ended!
        </RMText>
        <RMSpacer direction="column" spacing="xs" />
        <RMText type="sans" size="xs" color="on-surface-primary">
          Polls are open for 24 hours.
        </RMText>
      </Body>

      <Footer>
        <RMButton background="primary" leftIcon={finished ? null : faHome} fullWidth autoLoading onClick={onRedirect}>
          {finished ? 'See the winner' : 'Return home'}
        </RMButton>
      </Footer>
    </Page>
  );
}
