import { faWifi } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMError } from '@/components/RMError';

export interface NoConnectionPageProps {
  onTryAgain: () => void;
}

export function NoConnectionPage({ onTryAgain }: NoConnectionPageProps) {
  return (
    <RMError.Root
      icon={faWifi}
      title="Unable to connect"
      Action={<RMButton onClick={onTryAgain}>Try again</RMButton>}
      Message={
        <RMError.Text>No internet connection can be found. Check your internet connection and try again.</RMError.Text>
      }
    />
  );
}
