import { NavbarAccount } from './NavbarAccount';
import { NavbarDivider } from './NavbarDivider';
import { NavbarItem } from './NavbarItem';
import { NavbarRoot } from './NavbarRoot';

export const Navbar = {
  Root: NavbarRoot,
  Item: NavbarItem,
  Divider: NavbarDivider,
  Account: NavbarAccount,
} as const;
