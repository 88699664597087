import { useMemo } from 'react';
import { EntityType } from '@remento/types/entity';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries } from '@tanstack/react-query';

import { useCollection, useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';

export interface PollTimer {
  hours: string;
  minutes: string;
  seconds: string;
  full: string;
}

export function useProjectPollsQuery(projectId: string | null) {
  const { pollService } = useServices();
  return useCollection(EntityType.POLL, projectId ? { projectId } : null, (params, scope) =>
    pollService.getProjectPolls(params.projectId, scope),
  );
}

export function usePollQuery(pollId: string | null) {
  const { pollService } = useServices();
  return useEntity(EntityType.POLL, pollId, (id, scope) => pollService.getPoll(id, scope));
}

export function usePollVotesQuery(pollId: string | null) {
  const { pollService } = useServices();
  return useCollection(EntityType.POLL_VOTE, pollId ? { pollId } : null, (params, scope) =>
    pollService.getPollVotes(params.pollId, scope),
  );
}

export function usePollVoteQuery(voteId: string | null) {
  const { pollService } = useServices();
  return useEntity(EntityType.POLL_VOTE, voteId, (id, scope) => pollService.getPollVote(id, scope));
}

export function usePollVotes(ids: string[] | null) {
  const { pollService, entityCacheManagerService } = useServices();
  return useQueries({
    combine: (queries) => queries.map((q) => q.data).filter(notNull),
    queries: useMemo(
      () =>
        ids?.map((id) => {
          return entityCacheManagerService.buildEntityQuery(EntityType.POLL_VOTE, id, () => {
            return pollService.getPollVote(id);
          });
        }) ?? [],
      [entityCacheManagerService, ids, pollService],
    ),
  });
}
