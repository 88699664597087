import { AnalyticsService, Events } from '../analytics.types';

import {
  RecordingSettingsNotificationChangedPayload,
  StorytellingAnalyticsService,
  StorytellingRecordingType,
  StorytellingVariant,
} from './storytelling-analytics.types';

export class DefaultStorytellingAnalyticsService implements StorytellingAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onStorytellingArrived(variant: StorytellingVariant, referrerRoute: string | null | undefined): void {
    this.analyticsService.track(Events.StorytellingArrive, {
      variant,
      referrerRoute,
    });
  }

  onStorytellingResponseStarted(variant: StorytellingVariant, referrerRoute: string | null | undefined): void {
    this.analyticsService.track(Events.StorytellingResponseStart, {
      variant,
      referrerRoute,
    });
  }

  onStorytellingLearnMorePressed(variant: StorytellingVariant, referrerRoute: string | null | undefined): void {
    this.analyticsService.track(Events.StorytellingLearnMore, {
      variant,
      referrerRoute,
    });
  }

  onStorytellingInputSelected(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingInputsSelect, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingInputPermissionDeclined(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingInputsDecline, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingInputBrowseOpened(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingInputsBrowse, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingInputChanged(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingInputsChange, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRecordingStarted(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingStart, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingNextPrompt(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingNextPrompt, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRestarted(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingRestart, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRecordingEnded(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    duration: number,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingEnd, {
      variant,
      recordingType,
      duration,
      referrerRoute,
    });
  }

  onStorytellingRecordingFinished(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    duration: number,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingFinish, {
      variant,
      recordingType,
      duration,
      referrerRoute,
    });
  }

  onStorytellingRecordingSucceeded(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    duration: number,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingSuccess, {
      variant,
      recordingType,
      duration,
      referrerRoute,
    });
  }

  onStorytellingRecordingInterruptedError(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingInterruptError, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRecordingNoDataError(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingNoDataError, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRecordingQueueDisconnectedError(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.StorytellingRecordingQueueDisconnectError, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingActionPerformed(cta: 'view-stories', referrerRoute: string | null | undefined): void {
    this.analyticsService.track(Events.StorytellingAction, { cta, referrerRoute });
  }

  onRecordingSettingsNotificationChanged(
    payload: RecordingSettingsNotificationChangedPayload,
    referrerRoute: string | null | undefined,
  ): void {
    this.analyticsService.track(Events.RecordingSettingsNotificationChanged, { ...payload, referrerRoute });
  }
}
