import { SanityClient } from '@sanity/client';

import { CmsService } from '../cms/cms.service';
import { CmsEntity } from '../cms/cms.types';

import { OnboardingService, OnboardingStep, OnboardingStepType } from './onboarding.types';

export class OnboardingServiceImpl extends CmsService<OnboardingStep> implements OnboardingService {
  constructor(client: SanityClient) {
    super(client, {
      // The entity will always be replaced in the fetch method.
      entity: 'onboarding-purchaser-biography-step',
      additionalFields: {
        id: '_id',
        desktopAssetUrl: 'desktopAsset.asset->url',
        desktopAssetMimeType: 'desktopAsset.asset->mimeType',
        mobileAssetUrl: 'mobileAsset.asset->url',
        mobileAssetMimeType: 'mobileAsset.asset->mimeType',
      },
      sort: ['position asc'],
    });
  }

  getOnboardingSteps(type: OnboardingStepType): Promise<OnboardingStep[]> {
    let entity: CmsEntity;
    switch (type) {
      case 'collaborator.autobiography':
        entity = 'onboarding-collaborator-autobiography-step';
        break;
      case 'collaborator.biography':
        entity = 'onboarding-collaborator-biography-step';
        break;
      case 'collaborator.babybook':
        entity = 'onboarding-collaborator-babybook-step';
        break;
      case 'purchaser.babybook':
        entity = 'onboarding-purchaser-babybook-step';
        break;
      case 'purchaser.autobiography':
        entity = 'onboarding-purchaser-autobiography-step';
        break;
      case 'purchaser.biography':
        entity = 'onboarding-purchaser-biography-step';
        break;
    }

    return this.fetchAll([], {}, { entity });
  }
}
