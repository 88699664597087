import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';

import { ReferralLink, ReferralService } from './referral.types';

export class DefaultReferralService implements ReferralService {
  constructor(private authorizationService: AuthorizationService) {}

  async getReferralLink(scope?: RequestScope): Promise<ReferralLink> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<ReferralLink>('/referral/link', {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async sendReferralEmail(email: string): Promise<void> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post(
      '/referral/send',
      { email },
      {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
      },
    );
    return data;
  }
}
