import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '@/services/api/api';
import { captureException } from '@/utils/captureException';

import { MaintenancePage } from '../components/MaintenancePage/MaintenancePage';

interface AppStatus {
  status: 'migrating' | 'healthy';
}

export function MaintenancePageContainer() {
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = window.setInterval(async () => {
      try {
        // We do not have access to the services in this page, because they are not loaded.
        // That's why we call the api directly.
        const { data } = await api.get<AppStatus>('/app/status');
        if (data.status !== 'healthy') {
          return;
        }

        clearInterval(intervalId);

        // Force a page refresh after redirecting.
        // This will make sure that all the services are initialized again.
        navigate(0);
      } catch (error) {
        captureException(error, true);
      }
    }, 5000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [navigate]);

  return <MaintenancePage />;
}
