import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { StoryStatus } from '@remento/types/story';
import { UserSubscriptionStatus } from '@remento/types/user';

import { Services } from '@/Services';

import { getRecordingPaywallPath, getRecordingPromptRecordedPath } from '../paths';
import { ActiveRoute, PageErrorRedirect, PageRedirect, RoutePath } from '../types/routing.types';

export async function validateRecordRoute(route: ActiveRoute, services: Services): Promise<void> {
  switch (route.route) {
    case RoutePath.Recording:
    case RoutePath.RecordingIntro:
    case RoutePath.RecordingTypeSelection:
      break;
    default:
      throw new PageErrorRedirect();
  }

  const promptId = route.params.promptId ?? null;
  const projectId = route.params.projectId ?? null;
  if (promptId === null || projectId == null) {
    throw new PageErrorRedirect();
  }

  await services.storyCacheService.loadStoryRecordByPromptId(promptId);

  const prompt = await services.projectCacheService.getPrompt(promptId);
  if (prompt === null) {
    throw new NotFoundError('prompt-not-found', {
      origin: 'entity',
      entityType: EntityType.PROMPT,
      entityId: promptId,
    });
  }

  const story = await services.storyCacheService.getPromptStory(prompt.id);
  if (story === null) {
    throw new NotFoundError('story-not-found', {
      origin: 'entity',
      entityType: EntityType.STORY,
    });
  }

  if (story.status === StoryStatus.PROCESSING || story.status === StoryStatus.RECORDED) {
    throw new PageRedirect(getRecordingPromptRecordedPath(projectId, promptId));
  }

  const project = await services.projectCacheService.getProject(projectId);
  if (project === null) {
    throw new NotFoundError('project-not-found', {
      origin: 'entity',
      entityType: EntityType.PROJECT,
      entityId: projectId,
    });
  }

  if (project.subscriptionStatus === UserSubscriptionStatus.INACTIVE) {
    throw new PageRedirect(getRecordingPaywallPath(projectId, promptId));
  }
}
