import { toast } from '@/components/RMToast/RMToast';

import { writeToClipboard } from './clipboard';
import { isDesktopDevice } from './isMobile';

export interface ShareSheetData {
  url: string | Promise<string>;
  title?: string;
  text?: string;
}

export type ShareResult = 'shared' | 'copied-to-clipboard' | 'aborted';

export function canOpenShareSheet(data: ShareSheetData): boolean {
  if (navigator.share == null) {
    return false;
  }
  if (navigator.canShare == null) {
    return true;
  }

  // navigator.share is also available in windows 10 ans macos 15, but we still want
  // to copy to the clipboard when in desktop.
  if (isDesktopDevice()) {
    return false;
  }

  // If the url is a promise, there's no way to verify if the link is really shareable.
  // We should let navigator.share throw if necessary, but in practice this should never happen.
  if (typeof data.url != 'string') {
    return true;
  }

  return navigator.canShare({ title: data.title, url: data.url });
}

export async function openShareSheet(data: ShareSheetData): Promise<ShareResult> {
  if (!canOpenShareSheet(data)) {
    await writeToClipboard(data.url);
    return 'copied-to-clipboard';
  }

  try {
    await navigator.share({
      title: data.title,
      text: data.text,
      // The url can also be a promise so the api is basically the same as the `writeToClipboard`
      url: await data.url,
    });
    return 'shared';
  } catch (error) {
    if (error instanceof Error && error.name == 'AbortError') {
      return 'aborted';
    }
    throw error;
  }
}

export function showShareSheetToast(result: ShareResult, text = 'Link copied to clipboard') {
  switch (result) {
    case 'copied-to-clipboard': {
      toast(text);
      break;
    }
    default: {
      break;
    }
  }
}
