import { UserIdentifier } from '../api/user/user.types';

import { AnalyticsRepository, Events, Page } from './analytics.types';

export class ConsoleAnalyticsRepository implements AnalyticsRepository {
  initialize(userIdentifier: UserIdentifier): void {
    console.log(`[CONSOLE-ANALYTICS] Initialize "${userIdentifier.type}" "${userIdentifier.id}"`);
  }

  identify(id: string, props: Record<string, unknown>): void {
    console.log(`[CONSOLE-ANALYTICS] Identify "${id}" with props ${JSON.stringify(props)}`);
  }

  track(event: Events, payload?: Record<string, unknown>): void {
    console.log(`[CONSOLE-ANALYTICS] Track "${event}", payload: `, payload);
  }

  page(page: Page, payload?: Record<string, unknown>): void {
    console.log(`[CONSOLE-ANALYTICS] Page "${page}", payload: `, payload);
  }

  reset(): void {
    console.log(`[CONSOLE-ANALYTICS] Reset tester`);
  }
}
