import z, { ZodIssue } from 'zod';

import { DeepPartial, Paths, PathValueType } from '@/modules/form/@types-utils';
import { StateStore } from '@/modules/jimbos';

export type Unsubscribe = () => void;

export interface InputErrorType {
  id: string;
  type: z.ZodIssueCode;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export class InputError extends Error implements InputErrorType {
  constructor(public readonly id: string, message: string, public readonly type: z.ZodIssueCode = 'custom') {
    super(message);
  }
}

export class FormInputError<In extends object, Out extends object = In> extends InputError {
  constructor(public form: Form<In, Out>, public path: Paths<In>, message: string, type: z.ZodIssueCode = 'custom') {
    super(`${path}.${type}`, message, type);
  }
}

export interface ValidValidationResult<Out> {
  isValid: true;
  value: Out;
}

export interface InvalidValidationResult<In> {
  isValid: false;
  value: DeepPartial<In> | null;
  errors: Array<ZodIssue>;
}

export type ValidationResult<In, Out> = ValidValidationResult<Out> | InvalidValidationResult<In>;

export type ValidationMode = 'onChange' | 'onBlur' | 'onSubmit';
export type ValidationDisplayMode = 'onTouched' | 'onSubmitted' | 'onDirty';

export type Validator<In, Out> = () => Promise<ValidationResult<In, Out>>;

export interface InputValidationState {
  // The input value is valid
  isValid: boolean | null;
  // The validation errors based on the input value
  errors: Array<InputErrorType> | null;
}

export interface InputInteractionState {
  // The input has been changed
  isDirty: boolean;
  // The input has been blurred
  isTouched: boolean;
}

export interface InputState<T> extends InputInteractionState, InputValidationState {
  value?: T | null;
}

export type InternalInputState<T> = InputState<T>;

export type FormInputStates<In extends object> = {
  [K in Paths<In>]?: InternalInputState<unknown>;
};

export interface FormState<In extends object, Out extends object = In> {
  inputs: FormInputStates<In>;
  inputsValue: DeepPartial<In> | null;
  value: Out | null;
  isSubmitted: boolean;
  pendingSubmitCount: number;
  isValid?: boolean;
  isDirty: boolean;
  isTouched: boolean;
  pendingValidationCount: number;
}

export type Form<In extends object, Out extends object = In> = {
  defaultValues?: DeepPartial<In>;
  validation: {
    mode: ValidationMode;
    display: ValidationDisplayMode;
  };
  store: StateStore<FormState<In, Out>>;
  validate: Validator<In, Out>;
};
