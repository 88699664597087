import { AssetAlternativeMetadataType, BaseAssetAlternativeType } from '@remento/types/alternative';
import { DeleteMutation } from '@remento/types/base-entity';
import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { Story } from '@remento/types/story';
import { notNull } from '@remento/utils/array/notNull';
import { getStorySummary, getStoryTitle } from '@remento/utils/entity/story';
import { QueryClient } from '@tanstack/react-query';

import { AssetCacheService } from '@/services/api/asset';
import { EntityCacheManagerService } from '@/services/api/cache';
import { StoryCacheService, StoryIssue, StoryIssueType, StoryService } from '@/services/api/story/story.types';

export class DefaultStoryCacheService implements StoryCacheService {
  constructor(
    private remoteService: StoryService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
    private assetCacheService: AssetCacheService,
  ) {}

  getStory(storyId: string): Promise<Story | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.STORY, storyId, () =>
        this.remoteService.getStory(storyId),
      ),
    );
  }

  async getPromptStory(promptId: string): Promise<Story | null> {
    const storyIds = await this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.STORY, { promptId }, () =>
        this.remoteService.getPromptStory(promptId),
      ),
    );

    return this.getStory(storyIds[0]);
  }

  getProjectStories(projectId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.STORY, { projectId }, () =>
        this.remoteService.getProjectStories(projectId),
      ),
    );
  }

  async invalidateStoryRecordByPromptIdCache(promptId: string): Promise<void> {
    await this.queryClient.invalidateQueries({
      exact: true,
      queryKey: ['story-record', { promptId }],
    });
  }

  async loadStoryRecordByPromptId(promptId: string): Promise<void> {
    const data = await this.queryClient.fetchQuery({
      queryKey: ['story-record', { promptId }],
      queryFn: () => {
        return this.remoteService.getStoryRecordByPromptId(promptId);
      },
    });
    await this.entityCacheManagerService.cacheResponse(data);

    // Check if the prompt has not been deleted
    const prompt = this.entityCacheManagerService.getEntity(EntityType.PROMPT, promptId)?.value ?? null;
    if (prompt === null) {
      throw new NotFoundError('prompt-not-found', {
        origin: 'entity',
        entityType: EntityType.PROMPT,
        entityId: promptId,
      });
    }
  }

  async regenerateShareLink(storyId: string): Promise<string> {
    const link = await this.remoteService.regenerateShareLink(storyId);
    this.entityCacheManagerService.setCachedValue(['story-share-link', storyId], link);
    return link;
  }

  async deleteStory(storyId: string, mutation: DeleteMutation): Promise<void> {
    const response = await this.remoteService.deleteStory(storyId, mutation);
    await this.entityCacheManagerService.cacheResponse(response);
  }

  async getStoryIssues(story: Story): Promise<StoryIssue[]> {
    const issues: StoryIssue[] = [];

    const title = getStoryTitle(story);
    if (title == null || title.trim().length == 0) {
      issues.push({
        storyId: story.id,
        type: StoryIssueType.MISSING_TITLE,
      });
    }

    const summary = getStorySummary(story);
    if (summary == null || summary.trim().length == 0) {
      issues.push({
        storyId: story.id,
        type: StoryIssueType.SHORT_SUMMARY,
      });
    }

    if (story.imageAssetIds.length > 0) {
      const alternativesIds = (
        await Promise.all(story.imageAssetIds.map((assetId) => this.assetCacheService.getAssetAlternatives(assetId)))
      ).flat();
      const alternatives = (
        await Promise.all(alternativesIds.map((alternativeId) => this.assetCacheService.getAlternative(alternativeId)))
      ).filter(notNull);

      const originalAlternatives = alternatives.filter(
        (alternative) => alternative.type === BaseAssetAlternativeType.ORIGINAL,
      );
      for (const alternative of originalAlternatives) {
        const metadata = alternative.metadata;
        if (metadata?.type === AssetAlternativeMetadataType.IMAGE && metadata.width < 816 && metadata.height < 960) {
          issues.push({
            storyId: story.id,
            type: StoryIssueType.LOW_RESOLUTION_IMAGE,
          });
          break;
        }
      }
    }

    return issues;
  }
}
