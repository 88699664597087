import { BaseEntity, BaseMutation } from './base-entity';
import { BookColor, BookShippingAddress } from './book';

export enum TrackingProvider {
  FEDEX = 'fedex',
  UPS = 'ups',
  PUROLATOR = 'purolator',
}

export enum BookOrderType {
  EBOOK = 'ebook',
  BOOK = 'book',
}

interface BaseBookOrder<T> extends BaseEntity<T> {
  projectId: string;
  bookTemplateId: string;
  purchaserUserId: string;
  creatorUserId: string;
  color: BookColor;
  title: string;
  subtitle: string | null;
  coverAssetId: string | null;
  pdfAssetId: string;
  stories: Array<{
    storyId: string;
    title: string;
    summary: string;
    imageAssetsIds: Array<string>;
  }>;
  fullCoverAssetId?: string | null;
  stripeInvoiceId?: string | null;
  hideQrCode?: boolean;
  submittedOn: number;
  canceledOn: number | null;
  paidOn: number | null;
}

export enum EBookOrderStatus {
  AWAITING_PAYMENT = 'awaiting-payment',
  PROCESSING = 'processing',
  EMAILED = 'emailed',
  CANCELED = 'canceled',
}

export interface EBookOrder extends BaseBookOrder<EBookOrderStatus> {
  type: BookOrderType.EBOOK;
  emailedOn: number | null;
  quantity: 0 | 1;
  ebookAssetId: string | null;
}

export enum BookOrderStatus {
  AWAITING_PAYMENT = 'awaiting-payment',
  PROCESSING = 'processing',
  PRINTING = 'printing',
  SHIPPED = 'shipped',
  CANCELED = 'canceled',
}

export interface BookOrderTracker {
  id: string;
  provider: TrackingProvider;
}

export interface PhysicalBookOrder extends BaseBookOrder<BookOrderStatus> {
  type: BookOrderType.BOOK;
  quantity: number;
  credits: number;
  shipping: BookShippingAddress;
  shippedOn: number | null;
  tracker: BookOrderTracker | null;
  rpiCustomerOrderId?: string | null;
  submittedToPrinterOn: number | null;
}

export type BookOrder = EBookOrder | PhysicalBookOrder;

export type BookOrderView = BookOrder & {
  purchaserPersonId: string;
  creatorPersonId: string;
};

export enum BookOrderMutationType {
  SET_PROCESSING = 'set-processing',
  SET_PRINTING = 'set-book-order-printing',
  SET_SHIPPED = 'set-book-order-shipped',
  SET_EMAILED = 'set-book-order-email',
  SET_CANCELED = 'set-book-order-canceled',
  SET_FULL_COVER_ASSET_ID = 'set-full-cover-asset-id',
}

export type SetBookOrderProcessingMutation = BaseMutation<
  BookOrderMutationType.SET_PROCESSING,
  {
    stripeInvoiceId: string | null;
    quantity?: number;
    credits?: number;
    ebookAssetId?: string;
    fullCoverAssetId?: string;
  }
>;
export type SetBookOrderPrintingMutation = BaseMutation<
  BookOrderMutationType.SET_PRINTING,
  { fullCoverAssetId: string; rpiCustomerOrderId: string }
>;
export type SetBookOrderShippedMutation = BaseMutation<
  BookOrderMutationType.SET_SHIPPED,
  { tracker: BookOrderTracker; shippedOn: number }
>;
export type SetBookOrderEmailedMutation = BaseMutation<
  BookOrderMutationType.SET_EMAILED,
  { emailedOn: number; ebookAssetId: string }
>;
export type SetBookOrderCanceledMutation = BaseMutation<BookOrderMutationType.SET_CANCELED, undefined>;
export type SetBookOrderFullCoverAssetIdMutation = BaseMutation<BookOrderMutationType.SET_FULL_COVER_ASSET_ID, string>;

export type BookOrderMutation =
  | SetBookOrderProcessingMutation
  | SetBookOrderPrintingMutation
  | SetBookOrderShippedMutation
  | SetBookOrderEmailedMutation
  | SetBookOrderCanceledMutation
  | SetBookOrderFullCoverAssetIdMutation;
