import { SurveyEvent, SurveyService } from './survey.types';

interface TypedWindow {
  wisepops?: (type: 'event', name: string) => void;
}

export class WisepopsSurveyService implements SurveyService {
  triggerEvent(name: SurveyEvent): void {
    const typedWindow = window as TypedWindow;
    if (!typedWindow.wisepops) {
      throw new Error('Missing wisepops setups script');
    }
    typedWindow.wisepops('event', name);
  }
}
