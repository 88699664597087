import { styled } from '@linaria/react';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  margin: 0 calc(var(--spacing-md) * -1);

  body[data-mobile='true'] & {
    height: 4rem;
  }

  &[data-logo='true'] {
    margin-left: 0;
  }

  > * {
    flex-shrink: 0;
  }
`;

export const Wordmark = styled(RMWordmark)`
  width: 7rem;
`;

export const WordmarkPlaceholder = styled.div`
  width: 7rem;
`;
