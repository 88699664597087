import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { createPromptDraftsStore, PromptDraftsStore } from './prompt-drafts.state';

const PromptDraftsStoreContext = createContext<PromptDraftsStore | null>(null);

type PromptDraftsStoreProviderProps = PropsWithChildren<unknown>;

export function PromptDraftsStoreProvider({ children }: PromptDraftsStoreProviderProps) {
  const store = useMemo(() => createPromptDraftsStore(), []);

  return <PromptDraftsStoreContext.Provider value={store}>{children}</PromptDraftsStoreContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function usePromptDraftsStore() {
  const context = useContext(PromptDraftsStoreContext);
  if (!context) {
    throw new Error('You must use usePromptDraftsStore within a PromptDraftsStoreProvider');
  }

  return context;
}
