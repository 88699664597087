import { ComponentProps } from 'react';
import { Navigate, Params, useParams, useSearchParams } from 'react-router-dom';

export function NavigateWithParams({ to, ...props }: ComponentProps<typeof Navigate>) {
  const [searchParams] = useSearchParams();

  return <Navigate to={`${to}?${searchParams.toString()}`} {...props} />;
}

interface NavigateWithParamsCallbackProps extends Omit<ComponentProps<typeof Navigate>, 'to'> {
  to: (searchParams: URLSearchParams, params: Readonly<Params<string>>) => string;
}

export function NavigateWithParamsCallback({ to, ...props }: NavigateWithParamsCallbackProps) {
  const [searchParams] = useSearchParams();
  const params = useParams();

  return <Navigate to={to(searchParams, params)} {...props} />;
}
