import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMError } from '@/components/RMError';
import { RMText } from '@/components/RMText/RMText';

export interface NotFoundPageProps {
  onGoHome: () => void;
}

export function NotFoundPage({ onGoHome }: NotFoundPageProps) {
  return (
    <RMError.Root
      icon={faTriangleExclamation}
      title="No page found"
      Action={
        <RMButton leftIcon={faHome} onClick={onGoHome}>
          Return home
        </RMButton>
      }
      Message={<RMError.Text>The page you’re looking for doesn’t exist.</RMError.Text>}
      Footer={
        <RMText align="center" size="xs" type="sans">
          If the problem continues, let us know at{' '}
          <a href="mailto:support@remento.co">
            <RMText type="sans" size="xs" color="primary" underline>
              support@remento.co
            </RMText>
          </a>
        </RMText>
      }
    />
  );
}
