import { AnalyticsService, Events } from '../analytics.types';

import {
  PromptAction,
  PromptAnalyticsService,
  PromptBankAction,
  PromptBankPromptAddedEvent,
  PromptBankPromptEditedEvent,
  PromptBankQuestionAddedEvent,
  PromptBankQuestionSelectedEvent,
  UpcomingPromptsAction,
} from './prompt-analytics.types';

export class DefaultPromptAnalyticsService implements PromptAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onUpcomingPromptsArrived(): void {
    this.analyticsService.track(Events.UpcomingPromptsArrive, {});
  }

  onUpcomingPromptsNewPromptPressed(variant: 'photo' | 'question'): void {
    this.analyticsService.track(Events.UpcomingPromptsNewPrompt, {
      variant,
    });
  }

  onUpcomingPromptsReordered(): void {
    this.analyticsService.track(Events.UpcomingPromptsReorder, {});
  }

  onUpcomingPromptsActionPerformed(cta: UpcomingPromptsAction): void {
    this.analyticsService.track(Events.UpcomingPromptsAction, {
      cta,
    });
  }

  onPromptBankArrived(): void {
    this.analyticsService.track(Events.PromptBankArrive);
  }

  onPromptBankQuestionSelected(payload: PromptBankQuestionSelectedEvent): void {
    this.analyticsService.track(Events.PromptBankQuestionSelected, payload);
  }

  onPromptBankPromptAdded(payload: PromptBankPromptAddedEvent): void {
    this.analyticsService.track(Events.PromptBankPromptAdded, payload);
  }

  onPromptBankQuestionAdded(payload: PromptBankQuestionAddedEvent): void {
    this.analyticsService.track(Events.PromptBankQuestionAdded, payload);
  }

  onPromptBankPromptsAdded(count: number): void {
    this.analyticsService.track(Events.PromptBankPromptsAdded, {
      count,
    });
  }

  onPromptBankPromptEdited(payload: PromptBankPromptEditedEvent): void {
    this.analyticsService.track(Events.PromptBankPromptEdited, payload);
  }

  onPromptBankActionPerformed(cta: PromptBankAction): void {
    this.analyticsService.track(Events.PromptBankAction, {
      cta,
    });
  }

  onPromptBankCustomPromptAdded(): void {
    this.analyticsService.track(Events.PromptBankPromptAddedCustom);
  }

  onPromptArrived(variant: 'in-progress' | 'upcoming'): void {
    this.analyticsService.track(Events.PromptArrive, { variant });
  }

  onPromptActionPerformed(cta: PromptAction): void {
    this.analyticsService.track(Events.PromptAction, {
      cta,
    });
  }

  onPromptPhotoPickerOpened(variant: 'quick-action' | 'prompt-bank' | 'prompt'): void {
    this.analyticsService.track(Events.PromptPhotoBrowse, {
      variant,
    });
  }

  onPromptPhotoSelected(): void {
    this.analyticsService.track(Events.PromptPhotoSelect, {});
  }
}
