import SpinnerLottieAnimation from '@/assets/spinner.lottie.json';

import { LoaderWrapper, LottieLoader } from './RMLoader.styles';

interface RMLoaderProps {
  center?: boolean;
}

export function RMLoader({ center = false }: RMLoaderProps) {
  return (
    <LoaderWrapper data-center={center}>
      <LottieLoader loop animationData={SpinnerLottieAnimation} />
    </LoaderWrapper>
  );
}
