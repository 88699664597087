import { AclGroupRole } from '@remento/types/acl';
import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { jwtDecode } from 'jwt-decode';

import { LocalStoreRepository } from '@/services/local/local-store';

import { AuthService } from '../auth/auth.types';

import { AuthorizationService, BasePermissionTokenPayload } from './authorization.types';

export class DefaultAuthorizationService implements AuthorizationService {
  constructor(private authService: AuthService, private localStore: LocalStoreRepository) {}

  addPermissionTokens(newTokens: string[]): void {
    const tokens = new Set(this.getPermissionTokens() ?? []);
    newTokens.forEach((token) => tokens.add(token));
    this.localStore.setItem('tokens', Array.from(tokens));
  }

  getPermissionTokens(): string[] {
    return this.localStore.getItem('tokens') ?? [];
  }

  getPermissionTokenPayload(jwt: string): BasePermissionTokenPayload {
    return jwtDecode(jwt);
  }

  getTokenPermissions(): Record<string, AclGroupRole> {
    return this.getPermissionTokens().reduce((permissions, token) => {
      return {
        ...permissions,
        ...this.getPermissionTokenPayload(token).acls,
      };
    }, {});
  }

  async getCredentialsForRequest(): Promise<{ params: URLSearchParams; headers: RawAxiosRequestHeaders }> {
    const params = new URLSearchParams();
    const tokens = this.getPermissionTokens();
    for (const token of tokens) {
      params.append('token', token);
    }

    const headers: AxiosRequestConfig['headers'] = {};
    const userToken = await this.authService.getAuthToken();
    if (userToken) {
      headers.Authorization = `Bearer ${userToken}`;
    }

    return {
      params,
      headers,
    };
  }
}
