import { AnalyticsService, Events } from '../analytics.types';
import { StoryPage } from '../story-viewer-analytics/story-viewer-analytics.types';

import { CollaborationAnalyticsService, CollaborationInviteSourcePage } from './collaboration-analytics.types';

export class DefaultCollaborationAnalyticsService implements CollaborationAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onInviteDialogOpened(page: CollaborationInviteSourcePage): void {
    this.analyticsService.track(Events.CollaborationInvite, { page });
  }

  onInvitedViaEmail(invites: number, location: 'onboarding' | 'invite-dialog' | 'poll-dialog'): void {
    this.analyticsService.track(Events.CollaborationInviteViaEmail, { invites, location });
  }

  onInvitedViaLink(location: 'onboarding' | 'invite-dialog' | 'poll-dialog'): void {
    this.analyticsService.track(Events.CollaborationInviteViaLink, { location });
  }

  onInvitationAccepted(from: 'email' | 'share-link'): void {
    this.analyticsService.track(Events.CollaborationAcceptInvite, { from });
  }

  onInvitePermissionsChanged(action: 'resend-invite' | 'remove'): void {
    this.analyticsService.track(Events.CollaborationManagePermissions, { action });
  }

  onHelpPressed(): void {
    this.analyticsService.track(Events.CollaborationGetHelp);
  }

  onAccessRequested(page: StoryPage, feature: 'edit-story' | 'reaction'): void {
    this.analyticsService.track(Events.CollaborationAccessRequested, {
      page,
      feature,
    });
  }
}
