import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import lottie, { AnimationItem } from 'lottie-web';

import { fillContainerClassName, LottieContainer } from './RMPageAnimation.styles';

export type LottieAnimationProps = PropsWithChildren<{
  animationData: unknown;
  loop?: boolean;
  fillContainer?: boolean;
  className?: string;
  onComplete?: () => void;
}>;

export function LottieAnimation({
  animationData,
  loop,
  fillContainer,
  className,
  onComplete,
  children,
}: LottieAnimationProps) {
  const onCompleteRef = useRef(onComplete);
  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);

  const animationRef = useRef<AnimationItem | null>();
  const lottieRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node === null) {
        animationRef.current?.destroy();
        animationRef.current = null;
        return;
      }

      const animation = lottie.loadAnimation({
        container: node,
        loop: loop ?? false,
        autoplay: true,
        animationData,
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMax slice',
        },
      });
      animationRef.current = animation;
      const unsubscribe = animation.addEventListener('complete', () => {
        onCompleteRef.current?.();
        unsubscribe();
      });
    },
    [animationData, loop],
  );

  return (
    <div className={clsx(className, fillContainer && fillContainerClassName)}>
      <LottieContainer ref={lottieRef} />
      {children}
    </div>
  );
}
