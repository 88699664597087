import { EntityResponse, EntityType } from '@remento/types/entity';
import {
  Person,
  PersonMutationType,
  PersonName,
  SetPersonAvatarMutation,
  SetPersonNameMutation,
} from '@remento/types/person';

import { EntityMutation } from '@/services/api/cache';
import { PersonService } from '@/services/api/person';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';

export class DefaultPersonService implements PersonService {
  constructor(private authorizationService: AuthorizationService) {}

  async getPerson(personId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/people/${personId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  createSetAvatarImageMutation(person: Person, newAvatarAssetId: string): EntityMutation<SetPersonAvatarMutation>[] {
    return [
      {
        type: EntityType.PERSON,
        id: person.id,
        mutations: [
          {
            type: PersonMutationType.SET_AVATAR,
            value: newAvatarAssetId,
            vclock: person.vclock,
            version: person.version,
          },
        ],
      },
    ];
  }

  createSetPersonNameMutation(
    person: Person,
    newName: Omit<PersonName, 'full'>,
  ): EntityMutation<SetPersonNameMutation>[] {
    return [
      {
        type: EntityType.PERSON,
        id: person.id,
        mutations: [
          {
            type: PersonMutationType.SET_NAME,
            value: newName,
            vclock: person.vclock,
            version: person.version,
          },
        ],
      },
    ];
  }
}
