import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import ReactivateDialogImageSrc from '@/assets/reactivate-dialog.webp';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import {
  Body,
  ContentWrapper,
  FeaturesList,
  FeaturesListItem,
  FeaturesListItemIcon,
  Header,
  Image,
} from './PaywallDialog.styles';

const FEATURES = [
  'Create and send new prompts',
  'Record new stories or edit old ones',
  'Use the AI writing assistant',
  'Manage photos in your stories',
  'Start unlimited new book projects',
  'Order books any time',
];

export interface PaywallDialogProps {
  isBillingOwner: boolean;
  showClose?: boolean;
  onReactivate: () => void;
  onClose?: () => void;
}

export function PaywallDialog({ isBillingOwner, showClose = true, onReactivate, onClose }: PaywallDialogProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMDialog.Root open variant="large" onClose={showClose ? onClose : undefined}>
      <RMDialog.Content>
        <Header
          title={isMobile ? 'This feature requires a paid Remento subscription.' : null}
          rightAdornment={showClose && <RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Body>
          <Body>
            {isMobile == false && <Image src={ReactivateDialogImageSrc} />}
            <ContentWrapper>
              {isMobile == false && (
                <RMText type="serif" size="xl" color="on-surface-primary">
                  This feature requires a paid Remento subscription.
                </RMText>
              )}
              <RMText type="sans" size="xs" color="on-surface-primary">
                When you rejoin Remento, you can:
              </RMText>

              <FeaturesList>
                {FEATURES.map((feature) => (
                  <FeaturesListItem key={feature}>
                    <FeaturesListItemIcon icon={faCheck} size="sm" />
                    <RMText type="sans" size="xs" color="on-surface-primary">
                      {feature}
                    </RMText>
                  </FeaturesListItem>
                ))}
              </FeaturesList>

              <RMText type="sans" size="xs" color="on-surface-primary">
                Until then, all your recordings and stories are safe and accessible in Remento.
              </RMText>

              <RMButton background="primary" fullWidth autoLoading onClick={onReactivate}>
                {isBillingOwner ? 'View renewal options' : 'Contact us'}
              </RMButton>
            </ContentWrapper>
          </Body>
        </RMDialog.Body>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
