import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ColorsTokens } from '@/styles/theme/colorTokens';

import { LoaderContainer } from './RMDialogLoader.styles';

export function RMDialogLoader() {
  return (
    <LoaderContainer>
      <FontAwesomeIcon className="animate-spin" icon={faSpinnerThird} fontSize={48} color={ColorsTokens.primary} />
    </LoaderContainer>
  );
}
