import { AnalyticsService, Events } from '../analytics.types';

import { PollAnalyticsService } from './poll-analytics.types';

export class DefaultPollAnalyticsService implements PollAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onPollCreated(): void {
    this.analyticsService.track(Events.PollCreated, {});
  }

  onPollDeleted(): void {
    this.analyticsService.track(Events.PollDeleted, {});
  }

  onPollQuestionsShuffled(): void {
    this.analyticsService.track(Events.PollQuestionsShuffled, {});
  }

  onPollVoted(): void {
    this.analyticsService.track(Events.PollVoted, {});
  }
}
