import { AnalyticsService, Events, Page } from '../analytics.types';

import { WebappAnalyticsService } from './webapp-analytics.types';

export class WebappAnalyticsServiceImpl implements WebappAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onError(errorUrl: string): void {
    this.analyticsService.track(Events.Error, { errorUrl });
  }

  onMicrophonePermissionShown(): void {
    this.analyticsService.track(Events.PermissionMicrophoneShown, {});
  }

  onMicrophonePermissionGranted(): void {
    this.analyticsService.track(Events.PermissionMicrophoneGranted, {});
  }

  onMicrophonePermissionDenied(): void {
    this.analyticsService.track(Events.PermissionMicrophoneDenied, {});
  }

  onCameraPermissionShown(): void {
    this.analyticsService.track(Events.PermissionCameraShown, {});
  }

  onCameraPermissionGranted(): void {
    this.analyticsService.track(Events.PermissionCameraGranted, {});
  }

  onCameraPermissionDenied(): void {
    this.analyticsService.track(Events.PermissionCameraDenied, {});
  }

  onProjectSwapped(source: 'global-navigation' | 'settings'): void {
    this.analyticsService.track(Events.ActionSwapProject, { source });
  }

  onStoriesPressed(): void {
    this.analyticsService.track(Events.ActionStories, {});
  }

  onUpcomingPromptsPressed(): void {
    this.analyticsService.track(Events.ActionUpcomingPrompts, {});
  }

  onProjectSettingsPressed(): void {
    this.analyticsService.track(Events.ActionBookSettings, {});
  }

  onViewBookPressed(): void {
    this.analyticsService.track(Events.ActionViewBook, {});
  }

  onMyAccountPressed(): void {
    this.analyticsService.track(Events.ActionMyAccount, {});
  }

  onOrderBooksPressed(): void {
    this.analyticsService.track(Events.ActionOrderBooks, {});
  }

  onGiftPressed(): void {
    this.analyticsService.track(Events.ActionGiftRemento, {});
  }

  onReferralPressed(): void {
    this.analyticsService.track(Events.ActionReferRemento, {});
  }

  onPageArrived(page: Page, payload?: Record<string, unknown>): void {
    this.analyticsService.page(page, payload);
  }
}
