import { diff } from 'deep-object-diff';
import get from 'lodash/get';
import { z } from 'zod';

import { create, getState, Observer, setState, subscribe, subscribeSelector } from '@/modules/jimbos';
import { useStoreSelector } from '@/modules/jimbos/react';

import { Form, FormInputError, FormInputStates, FormState, ValidationDisplayMode, ValidationMode } from './@types';
import { DeepPartial, Paths } from './@types-utils';
import { setInputError } from './input';
import { debounceAsync } from './utils/debounce';
import { getZodObjectPaths } from './utils/zod';
import { validate } from './validation';

interface FormProps<In extends object, Out extends object = In> {
  defaultValues?: DeepPartial<In>;
  schema: z.Schema<Out, z.ZodTypeDef, In>;
  validation: {
    mode?: ValidationMode;
    display?: ValidationDisplayMode;
  };
  debug?: 'immediate' | 'delayed';
}

export function logState<In extends object, Out extends object = In>(
  count: number,
  state: FormState<In, Out>,
  prevState: FormState<In, Out>,
) {
  const countPrefix = count.toString().padStart(3, ' ');
  if (state === prevState) {
    console.log(`${countPrefix}: [FORM] State unchanged`);
    return;
  }
  console.groupCollapsed(`${countPrefix}: [FORM] State change`);
  console.group('[DIFF]');
  console.log(JSON.stringify(diff(prevState, state), null, 2));
  console.groupEnd();
  console.groupCollapsed('[CURRENT]');
  console.log(JSON.stringify(state, null, 2));
  console.groupEnd();
  console.groupCollapsed('[PREVIOUS]');
  console.log(JSON.stringify(prevState, null, 2));
  console.groupEnd();
  console.groupEnd();
}

export function createForm<In extends object, Out extends object = In>({
  defaultValues,
  validation: { display, mode },
  schema,
  debug,
}: FormProps<In, Out>): Form<In, Out> {
  // Initialize all inputs state
  const inputs: FormInputStates<In> = {};
  const inputPaths = getZodObjectPaths(schema) as Paths<In>[];
  for (const inputPath of inputPaths) {
    inputs[inputPath] = {
      isValid: null,
      errors: null,
      isDirty: false,
      isTouched: false,
    };
  }

  const store = create<FormState<In, Out>>({
    inputs,
    inputsValue: defaultValues ?? null,
    value: null,
    isSubmitted: false,
    pendingSubmitCount: 0,
    isValid: undefined,
    isDirty: false,
    isTouched: false,
    pendingValidationCount: 0,
  });
  const validator = debounceAsync(() => validate(store, schema, debug != null));
  if (schema != null && defaultValues != null) {
    validator().catch(() => null);
  }
  if (debug) {
    let count = 0;
    subscribe(store, (s, prevS) => logState(count++, s, prevS), debug === 'delayed');
  }
  return {
    defaultValues,
    store,
    validation: {
      mode: mode ?? 'onSubmit',
      display: display ?? 'onSubmitted',
    },
    validate: validator,
  };
}

export function resetForm<In extends object, Out extends object = In>(form: Form<In, Out>) {
  setState(form.store, (state) => {
    const inputs = Object.keys(state.inputs).reduce((pInputs, p) => {
      const path = p as Paths<In>;
      const inputState = state.inputs[path];
      pInputs[path] = {
        ...inputState,
        isValid: null,
        errors: null,
        isDirty: false,
        isTouched: false,
        value: inputState != null && inputState.value != null ? get(form.defaultValues, path) : undefined,
      };
      return pInputs;
    }, {} as FormInputStates<In>);
    return {
      ...state,
      inputs,
      inputsValue: form.defaultValues ?? null,
      value: null,
      isValid: undefined,
      isSubmitted: false,
      isDirty: false,
      isTouched: false,
    };
  });
  form.validate().catch(() => null);
}

export async function submitForm<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  callback: (value: Out) => Promise<unknown> | unknown,
  errorCallback?: (err?: unknown) => Promise<unknown> | unknown,
): Promise<void> {
  setState(form.store, (s) => ({
    ...s,
    pendingSubmitCount: s.pendingSubmitCount + 1,
  }));

  let value: Out | null = null;
  let error: unknown;

  try {
    const result = await form.validate();
    if (result.isValid) {
      value = result.value;
    }
  } catch (err) {
    error = err;

    if (err instanceof FormInputError) {
      const inputError = err as FormInputError<In>;
      // It's important to ditch the error instance to only have native values
      // in the state.
      setInputError(form, inputError.path, {
        id: `${inputError.path}:${inputError.type}`,
        type: inputError.type,
        message: inputError.message,
      });
    }

    setState(form.store, (s) => ({
      ...s,
      isValid: false,
    }));
  } finally {
    setState(form.store, (s) => ({
      ...s,
      isSubmitted: true,
      pendingSubmitCount: s.pendingSubmitCount - 1,
    }));
  }

  if (value !== null) {
    await callback(value);
  } else {
    await errorCallback?.(error);
  }
}

// ======================= ACCESSORS ============================ //
// Define get, watch and use function to get data out of the form //
// ============================================================== //
const _getFormValue = <In extends object, Out extends object = In>(state: FormState<In, Out>) => state.value;
export const getFormValue = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _getFormValue(getState(form.store));
export const watchFormValue = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<Out | null>,
) => subscribeSelector(form.store, observer, _getFormValue, true);
export const useFormValue = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _getFormValue, true);

const _getFormInputsValue = <In extends object, Out extends object = In>(state: FormState<In, Out>) =>
  state.inputsValue;
export const getFormInputsValue = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _getFormInputsValue(getState(form.store));
export const watchFormInputsValue = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<DeepPartial<In> | null>,
) => subscribeSelector(form.store, observer, _getFormInputsValue, true);
export const useFormInputsValue = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _getFormInputsValue, true);

const _isFormValidating = <In extends object, Out extends object = In>(state: FormState<In, Out>) =>
  state.pendingValidationCount > 0;
export const isFormValidating = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _isFormValidating(getState(form.store));
export const subscribeIsFormValidating = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<boolean>,
) => subscribeSelector(form.store, observer, _isFormValidating, true);
export const useIsFormValidating = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _isFormValidating, true);

const _isFormValid = <In extends object, Out extends object = In>(state: FormState<In, Out>) => state.isValid;
export const isFormValid = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _isFormValid(getState(form.store));
export const subscribeIsFormValid = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<boolean | undefined>,
) => subscribeSelector(form.store, observer, _isFormValid, true);
export const useIsFormValid = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _isFormValid, true);

const _isFormSubmitted = <In extends object, Out extends object = In>(state: FormState<In, Out>) => state.isSubmitted;
export const isFormVSubmitted = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _isFormSubmitted(getState(form.store));
export const subscribeIsFormSubmitted = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<boolean>,
) => subscribeSelector(form.store, observer, _isFormSubmitted, true);
export const useIsFormSubmitted = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _isFormSubmitted, true);

const _isFormSubmitting = <In extends object, Out extends object = In>(state: FormState<In, Out>) =>
  state.pendingSubmitCount > 0;
export const isFormVSubmitting = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _isFormSubmitting(getState(form.store));
export const subscribeIsFormSubmitting = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<boolean>,
) => subscribeSelector(form.store, observer, _isFormSubmitting, true);
export const useIsFormSubmitting = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _isFormSubmitting, true);

const _isFormTouched = <In extends object, Out extends object = In>(state: FormState<In, Out>) => state.isTouched;
export const isFormTouched = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _isFormTouched(getState(form.store));
export const subscribeIsFormTouched = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<boolean | undefined>,
) => subscribeSelector(form.store, observer, _isFormTouched, true);
export const useIsFormTouched = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _isFormTouched, true);

const _isFormDirty = <In extends object, Out extends object = In>(state: FormState<In, Out>) => state.isDirty;
export const isFormDirty = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  _isFormDirty(getState(form.store));
export const subscribeIsFormDirty = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  observer: Observer<boolean | undefined>,
) => subscribeSelector(form.store, observer, _isFormDirty, true);
export const useIsFormDirty = <In extends object, Out extends object = In>(form: Form<In, Out>) =>
  useStoreSelector(form.store, _isFormDirty, true);
