import { PropsWithChildren } from 'react';
import { AnimatePresence } from 'framer-motion';

import { Body } from './RMDialogBody.styles';

export function RMDialogBody({ children }: PropsWithChildren) {
  return (
    <Body>
      <AnimatePresence initial={false} mode="wait">
        {children}
      </AnimatePresence>
    </Body>
  );
}
