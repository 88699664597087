import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  border-radius: var(--radius-regular);
  overflow: hidden;
  min-width: fit-content;
  width: fit-content;
  box-sizing: border-box;

  :disabled {
    background-color: var(--disabled) !important;
    box-shadow: none;

    span {
      color: var(--on-primary) !important;
    }
  }

  span {
    font-weight: 700;
    font-family: 'FKGroteskNeue';
    font-size: 0.875rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &[data-icon-size='medium'] {
    svg,
    img {
      height: 1rem;
      width: 1rem;
    }
  }

  &[data-icon-size='large'] {
    svg,
    img {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &[data-icon-size='extra-large'] {
    svg,
    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  span,
  svg,
  img {
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const ButtonContent = styled.div<{ hidden: boolean }>`
  display: contents;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

// Background variants

export const transparentBackgroundVariant = css`
  span,
  svg {
    color: var(--primary);
  }

  :hover {
    &:after {
      background-color: var(--darken-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-primary-press);
    }
  }
`;

export const transparentNeutralBackgroundVariant = css`
  span,
  svg {
    color: var(--primary);
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const outlinedBackgroundVariant = css`
  border: 1px solid var(--border-primary);

  span,
  svg {
    color: var(--primary);
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const neutralBackgroundVariant = css`
  border: 1px solid var(--border-weak);
  background-color: var(--surface-dim);

  span,
  svg {
    color: var(--primary);
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const neutralOnInverseBackgroundVariant = css`
  background-color: var(--color-spruce-15);

  span,
  svg {
    color: var(--secondary);
  }

  :hover {
    &:after {
      background-color: var(--lighten-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--lighten-primary-press);
    }
  }
`;

export const primaryBackgroundVariant = css`
  background-color: var(--primary);
  box-shadow: var(--elevation-bottom-2);

  span,
  svg {
    color: var(--on-primary);
  }

  :hover {
    &:after {
      background-color: var(--lighten-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--lighten-primary-press);
    }
  }
`;

export const gradientBackgroundVariant = css`
  background: var(--secondary-gradient-top-bottom) padding-box, var(--border-gradient-top-bottom) border-box;
  border: 1px solid transparent;

  span,
  svg {
    color: var(--on-surface-primary);
  }

  :hover {
    opacity: 0.9;
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    opacity: 0.6;
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const dangerBackgroundVariant = css`
  background-color: var(--danger);

  span,
  svg {
    color: var(--on-danger);
  }

  :hover {
    &:after {
      background-color: var(--lighten-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--lighten-primary-press);
    }
  }
`;

export const recordingBackgroundVariant = css`
  background-color: var(--recording);

  svg,
  span {
    color: var(--on-danger);
  }

  :hover {
    &:after {
      background-color: var(--darken-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-primary-press);
    }
  }
`;

export const dashedBackgroundVariant = css`
  border: 1px dashed var(--border-strong);

  span,
  svg {
    color: var(--primary);
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const affirmativeBackgroundVariant = css`
  background-color: var(--affirmative);

  svg,
  span {
    color: var(--on-danger);
  }

  :hover {
    &:after {
      background-color: var(--darken-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-primary-press);
    }
  }
`;

// Size variants

export const extraSmallSizeVariant = css`
  height: 32px;
  padding: 0 var(--spacing-md);
`;

export const smallSizeVariant = css`
  height: 40px;
  padding: 0 var(--spacing-md);
`;

export const mediumSizeVariant = css`
  height: 48px;
  padding: 0 var(--spacing-lg);
`;

export const largeSizeVariant = css`
  height: 56px;

  span {
    font-size: 1rem;
  }
`;

export const massiveSizeVariant = css`
  height: 80px;
  min-height: 80px;
  padding: 0 var(--spacing-2xl);
  border-radius: var(--radius-round);
  gap: 0.5rem;

  span {
    font-size: 1rem;
  }
`;

// Color variants

export const recordingColorVariant = css`
  svg,
  span {
    color: var(--recording);
  }
`;

export const whiteColorVariant = css`
  border-color: var(--inverse-on-surface-primary);

  svg,
  span {
    color: var(--inverse-on-surface-primary);
  }
`;

export const surfaceColorVariant = css`
  border-color: var(--surface);

  svg,
  span {
    color: var(--surface);
  }

  :hover {
    &:after {
      background-color: var(--lighten-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--lighten-primary-press);
    }
  }
`;

export const secondaryColorVariant = css`
  border-color: var(--secondary);

  svg,
  span {
    color: var(--secondary);
  }

  :hover {
    &:after {
      background-color: var(--darken-neutral-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--darken-neutral-press);
    }
  }
`;

export const dangerColorVariant = css`
  svg,
  span {
    color: var(--danger);
  }
`;

export const fullWidthVariant = css`
  width: 100%;
`;
