import { logger } from '@/logger';

/**
 * Returns the current location pathname.
 * This should be used instead of window.location.pathname to
 * avoid returning the app basename by mistake.
 * Note that the react-router `useLocation` hook will never return the
 * location with the basename appended.
 *
 */
export function getPathname(): string {
  const basename: string = import.meta.env.VITE_PATH_BASENAME ?? '';
  const { pathname } = window.location;
  if (basename.length === 0) {
    return pathname;
  }
  if (!pathname.startsWith(basename)) {
    logger.warn('PATH_MISSING-BASENAME', { pathname, basename });
    return pathname;
  }
  return pathname.substring(basename.length);
}
