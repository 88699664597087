import { z, ZodError } from 'zod';

import { merge } from '@/modules/form/utils/merge';
import { getParentPaths } from '@/modules/form/utils/paths';
import { getState, setState, StateStore } from '@/modules/jimbos';

import {
  FormInputStates,
  FormState,
  InputErrorType,
  InvalidValidationResult,
  ValidationResult,
  ValidValidationResult,
} from './@types';
import { Paths } from './@types-utils';

export function validate<In extends object, Out extends object = In>(
  store: StateStore<FormState<In, Out>>,
  schema: z.Schema<Out, z.ZodTypeDef, In>,
  debug?: boolean,
): Promise<ValidationResult<In, Out>> {
  const { inputsValue: value } = getState(store);
  if (debug) {
    console.groupCollapsed('[FORM-VALIDATION] Validating');
    console.log(JSON.stringify(value, null, 2));
    console.groupEnd();
  }
  setState(store, (s) => ({
    ...s,
    pendingValidationCount: s.pendingValidationCount + 1,
  }));
  const onUnknownError = (err: unknown) => {
    setState(store, (s) => ({
      ...s,
      isValid: undefined,
      pendingValidationCount: s.pendingValidationCount - 1,
    }));
    console.error('[FORM] Validation failure', err);
    return err;
  };

  const resetInputErrors = (state: FormState<In, Out>): FormInputStates<In> => {
    const updatedInputStates: FormInputStates<In> = {};
    for (const inputPath of Object.keys(state.inputs)) {
      const inputState = state.inputs[inputPath as Paths<In>];
      updatedInputStates[inputPath as Paths<In>] = {
        ...inputState,
        isValid: true,
        errors: null,
      };
    }
    return updatedInputStates;
  };

  return schema
    .parseAsync(value)
    .then((validValue): ValidValidationResult<Out> => {
      if (debug) {
        console.groupCollapsed('[FORM-VALIDATION] Valid');
        console.log(JSON.stringify(validValue, null, 2));
        console.groupEnd();
      }

      setState(store, (state) => {
        return merge(state, {
          isValid: true,
          value: validValue,
          inputs: resetInputErrors(state),
          pendingValidationCount: state.pendingValidationCount - 1,
        });
      });
      return { value: validValue, isValid: true };
    })
    .catch((err): InvalidValidationResult<In> => {
      if (!(err instanceof ZodError)) {
        throw onUnknownError(err);
      }
      if (debug) {
        console.groupCollapsed('[FORM-VALIDATION] Invalid');
        console.log(JSON.stringify(err.issues, null, 2));
        console.groupEnd();
      }
      try {
        // Group the errors by path
        const errorsByPath = err.issues.reduce((errors, issue) => {
          const path = issue.path.join('.') as Paths<In>;
          const parentPaths = getParentPaths(path) as Array<Paths<In>>;
          [path, ...parentPaths].forEach((p) => {
            let pathErrors = errors.get(p);
            if (pathErrors == null) {
              pathErrors = [];
              errors.set(p, pathErrors);
            }
            pathErrors.push({
              id: `${path}:${issue.code}`,
              type: issue.code,
              message: issue.message,
            });
          });
          return errors;
        }, new Map<Paths<In>, Array<InputErrorType>>());

        // Update the state
        setState(store, (state) => {
          const updatedInputStates = resetInputErrors(state);

          // Add all errors, including to inputs may not have a state yet
          Array.from(errorsByPath.entries()).map(([inputPath, errors]) => {
            const inputState = state.inputs[inputPath];
            updatedInputStates[inputPath] = {
              ...inputState,
              isValid: false,
              errors,
            };
          });

          return merge(state, {
            inputs: updatedInputStates,
            value: null,
            isValid: err.issues.length == 0,
            pendingValidationCount: state.pendingValidationCount - 1,
          });
        });
        return {
          value,
          isValid: false,
          errors: err.issues,
        };
      } catch (err) {
        throw onUnknownError(err);
      }
    });
}
