export const Elevations = {
  1: '0px 1px 2px 0px rgba(40, 62, 62, 0.12)',
  2: '0px 2px 8px -1px rgba(40, 62, 62, 0.08), 0px 2px 2px -1px rgba(40, 62, 62, 0.04)',
  3: '0px 8px 16px -2px rgba(40, 62, 62, 0.12), 0px 2px 2px -1px rgba(40, 62, 62, 0.04)',
  4: '0px 16px 24px -6px rgba(40, 62, 62, 0.16), 0px 2px 2px -1px rgba(40, 62, 62, 0.04)',
  'bottom-1': '0px 1px 2px 0px rgba(20, 31, 31, 0.12)',
  'bottom-2': '0px 2px 8px -1px rgba(20, 31, 31, 0.08), 0px 2px 2px -1px rgba(20, 31, 31, 0.04)',
  'bottom-3': '0px 8px 16px -2px rgba(20, 31, 31, 0.12), 0px 2px 2px -1px rgba(20, 31, 31, 0.04)',
  'bottom-4': '0px 16px 24px -6px rgba(20, 31, 31, 0.16), 0px 2px 2px -1px rgba(20, 31, 31, 0.04)',
  'inverse-bottom-4': '0px 16px 24px -6px rgba(20, 31, 31, 0.36), 0px 2px 2px -1px rgba(20, 31, 31, 0.24)',
  'top-1': '0px -1px 2px 0px rgba(20, 31, 31, 0.12)',
  'top-2': '0px -2px 8px -1px rgba(20, 31, 31, 0.08), 0px -2px 2px -1px rgba(20, 31, 31, 0.04)',
  'top-3': '0px -8px 16px -2px rgba(20, 31, 31, 0.12), 0px -2px 2px -1px rgba(20, 31, 31, 0.04)',
  'top-4': '0px -16px 24px -6px rgba(20, 31, 31, 0.16), 0px -2px 2px -1px rgba(20, 31, 31, 0.04)',
} as const;
