import { DependencyList, useEffect, useState } from 'react';

export type AbortableEffectCallback = (signal: AbortSignal) => void | (() => void);

export function useAbortableEffect(effect: AbortableEffectCallback, deps?: DependencyList): void {
  return useEffect(() => {
    const abortController = new AbortController();
    const cleanup = effect(abortController.signal);
    return () => {
      abortController.abort();
      if (cleanup != undefined) {
        cleanup();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export type AsyncEffectCallback = (checkpoint: () => void, signal: AbortSignal) => Promise<void>;

export function useAsyncEffect(effect: AsyncEffectCallback, deps?: DependencyList): void {
  const [error, setError] = useState<Error | null>(null);

  useAbortableEffect((signal) => {
    effect(() => {
      if (signal.aborted) {
        throw new DOMException('Aborted', 'AbortError');
      }
    }, signal).catch((e) => {
      if (e instanceof DOMException && e.name === 'AbortError') {
        return;
      }
      setError(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  if (error != null) {
    throw error;
  }
}
