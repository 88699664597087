import { EntityType } from '@remento/types/entity';
import { Person } from '@remento/types/person';
import { QueryClient } from '@tanstack/react-query';

import { PersonCacheService, PersonService } from '@/services/api/person';

import { EntityCacheManagerService } from '../cache/entity-cache-manager.types';

export class DefaultPersonCacheService implements PersonCacheService {
  constructor(
    private remoteService: PersonService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  getPerson(personId: string): Promise<Person | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.PERSON, personId, () =>
        this.remoteService.getPerson(personId),
      ),
    );
  }

  // getStorytellerPeople(): Promise<string[]> {
  //   return this.queryClient.fetchQuery(
  //     this.entityCacheManagerService.buildCollectionQuery(EntityType.PERSON, {}, () =>
  //       this.remoteService.getStorytellerPeople(),
  //     ),
  //   );
  // }
}
