import { notNull } from '@remento/utils/array/notNull';

import { LocalStoreRepository } from './local-store.types';

export class LocalMemoryStoreRepository implements LocalStoreRepository {
  private store: Map<string, string> = new Map<string, string>();

  getItem<T = string>(key: string): T | null {
    const value = this.store.get(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  setItem<T = string>(key: string, value: T): void {
    this.store.set(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    this.store.delete(key);
  }

  clear(prefix?: string) {
    if (prefix) {
      const keys = this.keys(prefix);
      for (const key of keys) {
        this.removeItem(key);
      }
    } else {
      this.store.clear();
    }
  }

  keys(prefix?: string): string[] {
    let keys = Object.keys(localStorage);
    if (prefix) {
      keys = keys.filter((key) => key.startsWith(prefix));
    }
    return keys;
  }

  entries<T = string>(prefix?: string): [string, T][] {
    const keys = this.keys(prefix);
    return keys
      .map((key) => {
        try {
          return [key, this.getItem<T>(key) as T];
        } catch {
          return [key, this.store.get(key)];
        }
      })
      .filter(notNull) as [string, T][];
  }
}
