import { BookTemplate } from '@remento/types/book';
import { EntityType } from '@remento/types/entity';
import { QueryClient } from '@tanstack/react-query';

import { BookCacheService, BookService } from '@/services/api/book';
import { EntityCacheManagerService } from '@/services/api/cache';

export class DefaultBookCacheService implements BookCacheService {
  constructor(
    private remoteService: BookService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  getBook(bookId: string): Promise<BookTemplate | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.BOOK_TEMPLATE, bookId, () =>
        this.remoteService.getBook(bookId),
      ),
    );
  }

  getBooksByProjectId(projectId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.BOOK_TEMPLATE, { projectId }, () =>
        this.remoteService.getBooksByProjectId(projectId),
      ),
    );
  }
}
