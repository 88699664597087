import clsx from 'clsx';

import { RMText } from '../RMText/RMText';

import {
  Avatar,
  blendBackgroundVariant,
  circleShapeVariant,
  darkBackgroundVariant,
  largeSizeVariant,
  primaryBackgroundVariant,
  roundedShapeVariant,
  smallSizeVariant,
} from './RMAvatar.styles';

type RMAvatarSizeVariant = 'small' | 'large';
type RMAvatarBackgroundVariant = 'blend' | 'dark' | 'primary';
type RMAvatarShapeVariant = 'circle' | 'rounded';

const sizeVariants: Record<RMAvatarSizeVariant, string> = {
  small: smallSizeVariant,
  large: largeSizeVariant,
};

const backgroundVariants: Record<RMAvatarBackgroundVariant, string> = {
  blend: blendBackgroundVariant,
  primary: primaryBackgroundVariant,
  dark: darkBackgroundVariant,
};

const shapeVariants: Record<RMAvatarShapeVariant, string> = {
  circle: circleShapeVariant,
  rounded: roundedShapeVariant,
};

type RMAvatarProps = {
  src?: string | null;
  placeholder: string;
  size?: RMAvatarSizeVariant;
  shape?: RMAvatarShapeVariant;
  background?: RMAvatarBackgroundVariant;
};

export function RMAvatar({ src, placeholder, size = 'small', shape = 'circle', background = 'blend' }: RMAvatarProps) {
  return (
    <Avatar className={clsx(sizeVariants[size], shapeVariants[shape], backgroundVariants[background])}>
      {src ? (
        <img src={src} alt={placeholder} />
      ) : (
        <RMText
          type="sans"
          size={size === 'small' ? 's' : 'm'}
          bold
          color={background === 'dark' ? 'on-primary' : 'inverse-on-primary'}
        >
          {placeholder}
        </RMText>
      )}
    </Avatar>
  );
}
