import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const PhoneInputWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-xs);
  width: 100%;

  --react-international-phone-selected-dropdown-item-background-color: var(--darken-neutral-press);

  &[data-disabled='true'] {
    opacity: 0.5;
  }

  &[data-dropdown-position='top'] {
    --react-international-phone-dropdown-top: -208px;
  }
`;

export const PhoneInputLabel = styled.label`
  font-size: 0.875rem;
  font-family: var(--font-sans);
  font-weight: 700;
  line-height: 140%;
  color: var(--on-surface-secondary);
  cursor: pointer;
  width: fit-content;
  margin-left: var(--spacing-2xs);
`;

export const PhoneInputError = styled.span`
  font-size: 0.875rem;
  font-family: var(--font-sans);
  color: var(--danger);
`;

export const nativeClassName = css`
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 1px calc(var(--spacing-md) + 1px);
  border: 1px solid var(--border-strong);
  border-radius: var(--radius-regular);
  background-color: var(--surface);
  font-family: var(--font-sans);
  font-size: 1rem;

  &:hover {
    border: 1px solid var(--border-stronger);
  }

  &:focus-within {
    padding: 1px var(--spacing-md);
    border: 1px solid var(--border-primary);
    border-width: 2px;
  }
`;

export const nativeInputClassName = css`
  flex: 1 !important  ;
  background-color: var(--surface) !important;
  border: none !important;
  font-size: 1rem !important;
  font-family: var(--font-sans) !important;
  color: var(--on-surface-secondary) !important;
`;

export const nativeSelectorDropdownClassName = css`
  background-color: var(--surface);
  border: 1px solid var(--border-hairline);
  box-shadow: var(--elevation-bottom-3);
  border-radius: var(--radius-round);
  z-index: 10;
`;

export const nativeSelectorDropdownListItemClassName = css`
  color: var(--on-surface-secondary);
  font-family: var(--font-sans);

  &:hover {
    background-color: var(--darken-neutral-hover);
  }
`;

export const nativeSelectorButtonClassName = css`
  background-color: var(--surface);
  border: none;
`;

export const nativeSelectorButtonContentWrapperClassName = css`
  background-color: var(--surface);
`;
