import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import get from 'lodash/get';

import { isInputValue } from '@/modules/form/utils/value';
import { getState, Observer, setState, shallowCompare, subscribe, subscribeSelector } from '@/modules/jimbos';
import { useStoreSelector } from '@/modules/jimbos/react';

import {
  Form,
  FormInputStates,
  FormState,
  InputErrorType,
  InputInteractionState,
  InputState,
  InputValidationState,
} from './@types';
import { LeafPaths, PartialPathValueType, Paths, PathValueType } from './@types-utils';
import { merge } from './utils/merge';
import { getParentPaths } from './utils/paths';
import { set } from './utils/set';

function useBaseInput<In extends object, Out extends object, P extends Paths<In>>(form: Form<In, Out>, path: P) {
  const parentPaths = useMemo(() => {
    return getParentPaths(path) as Array<Paths<In>>;
  }, [path]);

  const onInitialize = useCallback(
    (value?: PathValueType<In, P> | null) => {
      setState(form.store, (state) => {
        // Update the input state for the input's path and all of its parent inputs
        const inputs: Partial<FormInputStates<In>> = {};
        // Initialize the input state without overriding any state value if it is already defined
        const stateInput = state.inputs[path];
        inputs[path] = {
          isDirty: stateInput?.isDirty === undefined ? false : stateInput.isDirty,
          isTouched: stateInput?.isTouched === undefined ? false : stateInput.isTouched,
          value: value === undefined ? stateInput?.value : value,
          errors: null,
          isValid: null,
        };
        parentPaths.forEach((p) => {
          // Initialize the parent input state without overriding any state value if it is already defined
          const stateParentInput = state.inputs[p];
          inputs[p] = {
            ...stateParentInput,
            isDirty: stateParentInput?.isDirty === undefined ? false : stateParentInput.isDirty,
            isTouched: stateParentInput?.isTouched === undefined ? false : stateParentInput.isTouched,
          };
        });
        return merge(state, {
          inputs: inputs,
          inputsValue: set(state.inputsValue, path, value),
        });
      });
      if (value !== undefined) {
        form.validate().catch(() => null);
      }
    },
    [form, path, parentPaths],
  );

  // On change, the input path and its parents are marked as dirty. The input's value is also updated
  const onChange = useCallback(
    (value: PathValueType<In, P> | null) => {
      setState(form.store, (state) => {
        // Update the input state for the input's path and all of its parent inputs
        const inputs: Partial<FormInputStates<In>> = {};
        const stateInput = state.inputs[path];
        inputs[path] = { ...stateInput, isDirty: true, value };
        parentPaths.forEach((p) => {
          const stateParentInput = state.inputs[p];
          inputs[p] = { ...stateParentInput, isDirty: true };
        });
        return merge(state, {
          inputs,
          isDirty: true,
          inputsValue: set(state.inputsValue, path, value),
        });
      });
      if (form.validation.mode === 'onChange') {
        form.validate().catch(() => null);
      }
    },
    [form, path, parentPaths],
  );

  // On blur, the input path and its parents are marked as touched
  const onBlur = useCallback(() => {
    setState(form.store, (state) => {
      // Update the input state for the input's path and all of its parent inputs
      const inputs: Partial<FormInputStates<In>> = {};
      const stateInput = state.inputs[path];
      inputs[path] = { ...stateInput, isTouched: true };
      parentPaths.forEach((p) => {
        const stateParentInput = state.inputs[p];
        inputs[p] = { ...stateParentInput, isTouched: true };
      });
      return merge(state, {
        inputs,
        isTouched: true,
      });
    });
    if (form.validation.mode == 'onBlur') {
      form.validate().catch(() => null);
    }
  }, [form, path, parentPaths]);
  return {
    onInitialize,
    onBlur,
    onChange,
  };
}

export interface ControlledInputProps<T> {
  onChange: (value: T | null) => void;
  onBlur: () => void;
}
/**
 * This hooks provides the onChange and onBlur hooks to observe the value of an input element. Setting the value of the
 * controlled element happens outside of the hook.
 *
 * This hook is useful for components that may not use a standard HTML input element.
 * @param form
 * @param path
 */
export function useControlledInputProps<In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
): ControlledInputProps<PathValueType<In, P>> {
  const { onInitialize, onBlur, onChange } = useBaseInput(form, path);
  useEffect(() => {
    const stateInput = getState(form.store).inputs[path];
    const stateInputValue = stateInput?.value;
    if (stateInputValue != null) {
      onInitialize(stateInputValue as PathValueType<In, P>);
      return;
    }
    const defaultInputValue = get(form.defaultValues, path);
    if (defaultInputValue != null) {
      // Remove the casting to make tsc run out of memory 🤷‍
      onInitialize(defaultInputValue as PathValueType<In, P>);
      return;
    }
    onInitialize();
  }, [form, path, onInitialize]);
  return {
    onBlur,
    onChange,
  };
}

export type InputElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
export interface InputProps<E extends InputElements = InputElements> {
  onChange: (event: ChangeEvent<E>) => void;
  onBlur: () => void;
  ref: (element: E | null) => void;
}

/**
 * This hooks provides the onChange, onRef and onBlur hooks to control an input element. It manages the value of the
 * input element directly via the ref.
 * @param form
 * @param path
 */
export function useInputProps<
  In extends object,
  Out extends object = In,
  E extends InputElements = InputElements,
  P extends LeafPaths<In, string> = LeafPaths<In, string>,
>(form: Form<In, Out>, path: P): InputProps<E> {
  const elementRef = useRef<E | null>();
  // It is no possible to tell useBaseInput that the path points to a string value
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { onInitialize, onBlur, onChange } = useBaseInput<In, Out, P>(form, path);
  useEffect(() => {
    // Observe the state to synchronize the element's value
    return subscribe(form.store, (state) => {
      const inputElement = elementRef.current;
      const inputState = state.inputs[path];
      if (inputElement == null || inputState == null || inputElement.value == inputState.value) {
        return;
      }
      inputElement.value = inputState.value != null ? `${inputState.value}` : '';
    });
  }, [form, path]);

  const onRef = useCallback(
    (el: E | null) => {
      elementRef.current = el;
      if (el == null) {
        return;
      }
      const stateInput = getState(form.store).inputs[path];
      const stateInputValue = stateInput?.value;
      if (stateInputValue != null) {
        el.value = `${stateInputValue}`;
        // It is no possible to tell useBaseInput that the path points to a string value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onInitialize(stateInputValue);
        return;
      }
      const defaultInputValue = get(form.defaultValues, path);
      if (defaultInputValue != null) {
        el.value = `${defaultInputValue}`;
        // It is no possible to tell useBaseInput that the path points to a string value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onInitialize(defaultInputValue);
        return;
      }
      // It is no possible to tell useBaseInput that the path points to a string value
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onInitialize(el.value);
    },
    [form, path, onInitialize],
  );

  const onElementChange = useCallback(
    (event: ChangeEvent<E>) => {
      // It is no possible to tell useBaseInput that the path points to a string value
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange(event.target.value);
    },
    [onChange],
  );
  return {
    ref: onRef,
    onBlur,
    onChange: onElementChange,
  };
}

export function setInputError<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  path: Paths<In>,
  error: InputErrorType,
) {
  setState(form.store, (state) =>
    merge(state, {
      isValid: false,
      inputs: { [path]: { errors: [error] } } as FormInputStates<In>,
    }),
  );
}

export function setInputValue<In extends object, Out extends object = In, P extends Paths<In> | null = null>(
  form: Form<In, Out>,
  path: P,
  value: PartialPathValueType<In, P>,
  validate?: boolean,
) {
  // WARNING: This function would accept a supertype of PathValue<T, P> and potentially add properties that are
  // not part of T
  if (value == null) {
    return;
  } else if (isInputValue(value)) {
    if (path == null) {
      throw new Error('The root object is expected to be an array of object');
    }
    const leafPath = path as LeafPaths<In>;
    setState(form.store, (state) =>
      merge(state, {
        inputs: { [leafPath]: { value } } as FormInputStates<In>,
        inputsValue: set(state.inputsValue, path, value as PathValueType<In, NonNullable<P>>),
      }),
    );
    if (validate === true || (validate !== false && form.validation.mode === 'onChange')) {
      form.validate().catch(() => null);
    }
  } else if (Array.isArray(value)) {
    value.forEach((v, i) => {
      const nestedPath = path !== null ? `${path}.${i}` : String(i);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setInputValue(form, nestedPath as any, v);
    });
  } else {
    Object.entries(value).forEach(([key, value]) => {
      const nestedPath = path !== null ? `${path}.${key}` : key;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setInputValue(form, nestedPath as any, value as any);
    });
  }
}

export function setInputValues<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  value: PartialPathValueType<In, null>,
  validate?: boolean,
) {
  return setInputValue(form, null, value, validate);
}

// ======================= ACCESSORS ============================ //
// Define get, watch and use function to get data out of the form //
// ============================================================== //

// Input value
const _getInputState = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
): InputState<PathValueType<In, P>> => {
  const inputState = getState(form.store).inputs[path];
  return {
    isDirty: inputState?.isDirty ?? false,
    isTouched: inputState?.isTouched ?? false,
    isValid: inputState?.isValid ?? null,
    errors: inputState?.errors ?? null,
    value: (inputState?.value ?? get(form.defaultValues, path) ?? null) as unknown as PathValueType<In, P> | null,
  };
};
export const getInputState = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
) => _getInputState<In, Out, P>(form, path);
export const watchInputState = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
  observer: Observer<InputState<PathValueType<In, P>>>,
) => subscribeSelector(form.store, observer, () => _getInputState<In, Out, P>(form, path), true, shallowCompare);
export const useInputState = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
) => useStoreSelector(form.store, () => _getInputState<In, Out, P>(form, path), true, shallowCompare);

// Input value
const _getInputValueFromState = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  state: FormState<In, Out>,
  path: P,
): PathValueType<In, P> | null =>
  (state.inputs?.[path]?.value ?? get(form.defaultValues, path) ?? null) as PathValueType<In, P> | null;

const _getInputValue = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
): PathValueType<In, P> | null => _getInputValueFromState(form, getState(form.store), path);

export const getInputValue = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
): PathValueType<In, P> | null => _getInputValue<In, Out, P>(form, path);

export const getInputValueOrThrow = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  input: P,
): PathValueType<In, P> => {
  const value = getInputValue(form, input);
  if (value == null) {
    throw new Error(`Missing ${input} from form value`);
  }
  return value;
};

export const watchInputValue = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
  observer: Observer<PathValueType<In, P> | null>,
) => subscribeSelector(form.store, observer, (state) => _getInputValueFromState<In, Out, P>(form, state, path), true);

export const useInputValue = <In extends object, Out extends object = In, P extends Paths<In> = Paths<In>>(
  form: Form<In, Out>,
  path: P,
): PathValueType<In, P> | null => useStoreSelector(form.store, () => _getInputValue<In, Out, P>(form, path), true);

// Input validation result
interface InputValidation extends InputValidationState {
  displayError: boolean;
}
function shouldDisplayError<In extends object, Out extends object = In>(
  form: Form<In, Out>,
  state: FormState<In, Out>,
  path: Paths<In>,
): boolean {
  const inputState = state.inputs[path];
  switch (form.validation.display) {
    case 'onDirty':
      return inputState?.isDirty === true || inputState?.isTouched === true || state.isSubmitted;
    case 'onTouched':
      return inputState?.isTouched === true || state.isSubmitted;
    case 'onSubmitted':
      return state.isSubmitted;
    default:
      return false;
  }
}
export const _getInputValidation = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  state: FormState<In, Out>,
  path: Paths<In>,
): InputValidation => {
  const inputState = state.inputs[path];
  return {
    displayError: shouldDisplayError(form, state, path),
    isValid: inputState?.isValid ?? null,
    errors: inputState?.errors ?? null,
  };
};
export const getInputValidation = <In extends object, Out extends object = In>(form: Form<In, Out>, path: Paths<In>) =>
  _getInputValidation(form, getState(form.store), path);
export const watchInputValidation = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  path: Paths<In>,
  observer: Observer<InputValidation>,
) => subscribeSelector(form.store, observer, (state) => _getInputValidation(form, state, path), true, shallowCompare);
export const useInputValidation = <In extends object, Out extends object = In>(form: Form<In, Out>, path: Paths<In>) =>
  useStoreSelector(form.store, (state) => _getInputValidation(form, state, path), true, shallowCompare);

// Input interaction state
export const _getInputInteraction = <In extends object, Out extends object = In>(
  state: FormState<In, Out>,
  path: Paths<In>,
): InputInteractionState => {
  const inputState = state.inputs[path];
  return {
    isDirty: inputState?.isDirty ?? false,
    isTouched: inputState?.isTouched ?? false,
  };
};
export const getInputInteraction = <In extends object, Out extends object = In>(form: Form<In, Out>, path: Paths<In>) =>
  _getInputInteraction(getState(form.store), path);
export const watchInputInteraction = <In extends object, Out extends object = In>(
  form: Form<In, Out>,
  path: Paths<In>,
  observer: Observer<InputInteractionState>,
) => subscribeSelector(form.store, observer, (state) => _getInputInteraction(state, path), true, shallowCompare);
export const useInputInteraction = <In extends object, Out extends object = In>(form: Form<In, Out>, path: Paths<In>) =>
  useStoreSelector(form.store, (state) => _getInputInteraction(state, path), true, shallowCompare);
