import { z } from 'zod';

import { BaseEntity, BaseMutation } from './base-entity';

// TODO-BOOK : Review type
export type EventDate = number;

// TODO-BOOK : Review type
export type EventLocation = string;

// TODO-BOOK : Review type
export type PortableText = string;

export interface StoryMetadata {
  title: string;
  date?: EventDate | null;
  location?: EventLocation | null;
}

export enum StoryStatus {
  PROCESSING = 'processing',
  RECORDED = 'recorded',
  DELETED = 'deleted',
  DRAFT = 'draft',
}

export enum StoryDataType {
  HUMAN = 'human',
  AI = 'ai',
  TRANSCRIPT_TOO_SHORT = 'transcript-too-short',
}

export interface HumanStoryData {
  type: StoryDataType.HUMAN;
  value: PortableText;
  settings?: Record<string, string>;
}

export interface AIStoryData {
  type: StoryDataType.AI;
  value: PortableText;
  settings: Record<string, string>;
}

export interface TranscriptTooShortStoryData {
  type: StoryDataType.TRANSCRIPT_TOO_SHORT;
}

export type StoryTitle = HumanStoryData | AIStoryData | TranscriptTooShortStoryData;
export type StorySummary = HumanStoryData | AIStoryData | TranscriptTooShortStoryData;
export type StoryShortSummary = HumanStoryData | AIStoryData;

export interface Story extends BaseEntity {
  projectId: string;
  promptId: string;
  metadata: StoryMetadata;
  status: StoryStatus;
  subjectPersonIds: string[];
  imageAssetIds: string[];
  recordingsIds: string[];
  lastRecordedOn?: number;
  pdfAssetId?: string | null;
  acl: string[];

  title?: StoryTitle;
  summary?: StorySummary;
  shortSummary?: StoryShortSummary;
}

export enum StoryShareLinkType {
  BOOK = 'book',
  SOCIAL = 'social',
  RECORD = 'record',
  EMAIL = 'email',
  HIGHLIGHT = 'highlight',
}

export enum StoryMutationType {
  SET_TITLE = 'set-title',
  SET_DATE = 'set-date',
  SET_LOCATION = 'set-location',
  SET_SUMMARY = 'set-summary',
  SET_SHORT_SUMMARY = 'set-short-summary',
  ADD_PERSON = 'add-person',
  REMOVE_PERSON = 'remove-person',
  ADD_RECORDING = 'add-recording',
  ADD_IMAGE = 'add-image',
  REMOVE_IMAGE = 'remove-image',
  SET_PROCESSING = 'set-processing',
  SET_RECORDED = 'set-recorded',
  SET_PDF_ASSET_ID = 'set-pdf-asset-id',
}

export enum StoryPerspectiveType {
  FIRST_PERSON = 'first-person',
  THIRD_PERSON = 'third-person',
  TRANSCRIPT = 'transcript',
}

export enum StoryLengthType {
  VERY_SHORT = 'very-short',
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
  VERY_LONG = 'very-long',
}

export type SetStoryDateMutation = BaseMutation<StoryMutationType.SET_DATE, EventDate>;
export type SetStoryLocationMutation = BaseMutation<StoryMutationType.SET_LOCATION, EventLocation>;
export type AddStoryPersonMutation = BaseMutation<StoryMutationType.ADD_PERSON, string>;
export type RemoveStoryPersonMutation = BaseMutation<StoryMutationType.REMOVE_PERSON, string>;
export type AddStoryRecordingMutation = BaseMutation<StoryMutationType.ADD_RECORDING, string>;
export type AddStoryImageMutation = BaseMutation<StoryMutationType.ADD_IMAGE, string>;
export type RemoveStoryImageMutation = BaseMutation<StoryMutationType.REMOVE_IMAGE, string>;
export type SetStoryTitleMutation = BaseMutation<StoryMutationType.SET_TITLE, StoryTitle>;
export type SetStorySummaryMutation = BaseMutation<StoryMutationType.SET_SUMMARY, StorySummary>;
export type SetStoryShortSummaryMutation = BaseMutation<StoryMutationType.SET_SHORT_SUMMARY, StoryShortSummary>;
export type SetStoryProcessingMutation = BaseMutation<StoryMutationType.SET_PROCESSING, undefined>;
export type SetStoryRecordedMutation = BaseMutation<StoryMutationType.SET_RECORDED, undefined>;
export type SetStoryPdfAssetIdMutation = BaseMutation<StoryMutationType.SET_PDF_ASSET_ID, string | null>;

export type StoryMutation =
  | SetStoryTitleMutation
  | SetStorySummaryMutation
  | SetStoryDateMutation
  | SetStoryLocationMutation
  | AddStoryPersonMutation
  | RemoveStoryPersonMutation
  | AddStoryImageMutation
  | RemoveStoryImageMutation
  | AddStoryRecordingMutation
  | SetStoryShortSummaryMutation
  | SetStoryProcessingMutation
  | SetStoryRecordedMutation
  | SetStoryPdfAssetIdMutation;

export const StoryMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(StoryMutationType.SET_DATE),
    value: z.number(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(StoryMutationType.SET_LOCATION),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(StoryMutationType.SET_TITLE),
    value: z.object({
      type: z.union([z.literal(StoryDataType.HUMAN), z.literal(StoryDataType.AI)]),
      value: z.string(),
      settings: z.record(z.string(), z.string()),
    }),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(StoryMutationType.SET_SUMMARY),
    value: z.object({
      type: z.union([z.literal(StoryDataType.HUMAN), z.literal(StoryDataType.AI)]),
      value: z.string(),
      settings: z.record(z.string(), z.string()),
    }),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(StoryMutationType.ADD_IMAGE),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(StoryMutationType.REMOVE_IMAGE),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
]);

export interface StorySummaryChunk {
  data: string;
}

export interface StoryGenerateSummaryPayload {
  perspective: StoryPerspectiveType;
  length: StoryLengthType;
}

export const SUMMARY_STREAM_DATA_SEPARATOR = '~~~~~';
