type AsyncFunc<In, Out> = (args: In) => Promise<Out>;

export function debounceAsync<Out>(callback: AsyncFunc<number, Out>, delay = 0): AsyncFunc<void, Out> {
  let currentTimeout: NodeJS.Timeout | null = null;
  let currentOperationsCallback: Array<(result: Out | null, err?: unknown) => void> = [];

  return async () =>
    new Promise<Out>((resolve, reject) => {
      currentOperationsCallback.push((result, err) => {
        if (err || result == null) reject(err);
        else resolve(result);
      });
      // Remove and replace the current timeout
      if (currentTimeout !== null) {
        clearTimeout(currentTimeout);
      }
      currentTimeout = setTimeout(() => {
        const _currentOperationsCallback = currentOperationsCallback;
        // Clean-up immediately in case the debounce function is called again while the promise resolves
        currentOperationsCallback = [];
        currentTimeout = null;
        const resolveOperations = (result: Out | null, err?: unknown) => {
          _currentOperationsCallback.forEach((cb) => cb(result, err));
        };
        callback(_currentOperationsCallback.length)
          .then((result) => resolveOperations(result))
          .catch((err) => resolveOperations(null, err));
      }, delay);
    });
}
