import { EntityResponse } from '@remento/types/entity';
import { jwtDecode } from 'jwt-decode';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';

import { AclService, InviteTokenPayload, ValidateTokenResponse } from './acl.types';

export class DefaultAclService implements AclService {
  constructor(private authorizationService: AuthorizationService) {}

  async getUserGroupMembers(scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>('/acl/members', {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getGroup(aclGroupId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/acl/groups/${aclGroupId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getGroupMembers(aclGroupId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/acl/groups/${aclGroupId}/members`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getGroupMember(aclMemberId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/acl/members/${aclMemberId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async deleteGroupMember(aclMemberId: string): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.delete<EntityResponse>(`/acl/members/${aclMemberId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async getGroupInvitees(aclGroupId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/acl/groups/${aclGroupId}/invitees`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getGroupInvitee(aclGroupInviteeId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/acl/invitees/${aclGroupInviteeId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async deleteGroupInvitee(aclGroupInviteeId: string): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.delete<EntityResponse>(`/acl/invitees/${aclGroupInviteeId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async joinGroup(token: string): Promise<ValidateTokenResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<ValidateTokenResponse>(
      `/acl/join`,
      { token },
      {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
      },
    );
    return data;
  }

  async validateToken(token: string): Promise<ValidateTokenResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<ValidateTokenResponse>(
      `/acl/join/validate-token`,
      { token },
      {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
      },
    );
    return data;
  }

  async sendEmailInvite(aclGroupId: string, emails: string[]): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<EntityResponse>(
      `/acl/groups/${aclGroupId}/invite-email`,
      { emails },
      {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
      },
    );
    return data;
  }

  async generateInviteLink(aclGroupId: string): Promise<string> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<{ link: string }>(`/acl/groups/${aclGroupId}/invite-link`, null, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data.link;
  }

  decodeInviteToken(token: string): InviteTokenPayload | null {
    try {
      return jwtDecode(token);
    } catch {
      return null;
    }
  }
}
