import { useMemo } from 'react';
import { BaseAssetAlternativeType, ImageAssetAlternativeType } from '@remento/types/alternative';
import { EntityType } from '@remento/types/entity';
import { Person } from '@remento/types/person';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries } from '@tanstack/react-query';

import { useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';
import { useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';

import { getPersonInitials } from './person.utils';

export function usePersonQuery(personId: string | null | undefined) {
  const { personService } = useServices();
  return useEntity(EntityType.PERSON, personId, (id, scope) => personService.getPerson(id, scope));
}

export function usePersonInitials(person: Person | null | undefined): string {
  return useMemo(() => getPersonInitials(person), [person]);
}

export function usePersonAvatarUrl(
  person: Person | null | undefined,
  type: BaseAssetAlternativeType | ImageAssetAlternativeType = ImageAssetAlternativeType.SMALL,
): string | null {
  const alternativesQuery = useAssetAlternativesQuery(person?.avatarAssetId);
  const smallAlternative = useAlternativeType(alternativesQuery.data, type);
  return smallAlternative?.url ?? null;
}

export function usePeople(ids: string[] | null) {
  const { personService, entityCacheManagerService } = useServices();
  return useQueries({
    combine: (queries) => queries.map((q) => q.data).filter(notNull),
    queries: useMemo(
      () =>
        ids?.map((id) => {
          return entityCacheManagerService.buildEntityQuery(EntityType.PERSON, id, () => {
            return personService.getPerson(id);
          });
        }) ?? [],
      [entityCacheManagerService, ids, personService],
    ),
  });
}
