import { EntityResponse } from '@remento/types/entity';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';

import { RecordingService } from './recording.types';

export class DefaultRecordingService implements RecordingService {
  constructor(private authorizationService: AuthorizationService) {}

  async getRecording(recordingId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/recordings/${recordingId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }
}
