// Use this instead of the one from react-router-dom.
// Read more:
// https://github.com/remix-run/react-router/issues/9304
// https://github.com/remix-run/react-router/issues/9991

import { useCallback, useMemo, useRef } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

// This needs to be outside the hook.
// Every hook instance need to use the same instance of the shared search params.
// This way, we will avoid overwriting params.
let globalSearchParams = new URLSearchParams();

export function useSearchParams() {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo(() => {
    globalSearchParams = new URLSearchParams(location.search);
    return globalSearchParams;
  }, [location.search]);

  const navigateTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const setSearchParam = useCallback(
    (name: string, value: string | null, options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(globalSearchParams);
      const currentValue = newSearchParams.get(name);
      if (value === currentValue) {
        return;
      }

      if (value === null) {
        newSearchParams.delete(name);
      } else {
        newSearchParams.set(name, value);
      }

      globalSearchParams = newSearchParams;

      // Add a timeout to run multiple sets at the same tick
      if (navigateTimeoutIdRef.current != null) {
        clearTimeout(navigateTimeoutIdRef.current);
        navigateTimeoutIdRef.current = null;
      }

      navigateTimeoutIdRef.current = setTimeout(() => {
        navigateTimeoutIdRef.current = null;
        navigate('?' + globalSearchParams, options);
      }, 0);
    },
    [navigate],
  );

  const deleteSearchParam = useCallback(
    (name: string, options?: NavigateOptions) => {
      setSearchParam(name, null, options);
    },
    [setSearchParam],
  );

  return { searchParams, setSearchParam, deleteSearchParam };
}
