import { LocalStoreRepository } from '../local-store';

import { OnboardingDialogRepository } from './onboarding-dialog.types';

const DIALOG_SHOWN_KEY = 'dialog-shown';

export class LocalOnboardingDialogRepository implements OnboardingDialogRepository {
  constructor(private sessionStore: LocalStoreRepository) {}

  setOnboardingDialogShown(shown: boolean): void {
    this.sessionStore.setItem(DIALOG_SHOWN_KEY, shown);
  }

  isOnboardingDialogPermitted(): boolean {
    return this.sessionStore.getItem(DIALOG_SHOWN_KEY) ?? false;
  }
}
