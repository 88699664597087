import { Services } from '@/Services';

import { RoutePath } from '../types/routing.types';

export function isInApp() {
  const navigator = window.navigator;
  const userAgent = navigator.userAgent;
  const normalizedUserAgent = userAgent.toLowerCase();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const standalone = navigator.standalone;

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const isAndroid = /android/.test(normalizedUserAgent);
  const isSafari = /safari/.test(normalizedUserAgent);
  return (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);
}

export async function isNewSigninAttempt(
  pathname: RoutePath,
  params: URLSearchParams,
  services: Services,
): Promise<boolean> {
  const { userService } = services;
  const user = await userService.getUser();
  return pathname === RoutePath.Signin && params.get('oobCode') != null && user != null;
}
