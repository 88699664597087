import isEqual from 'react-fast-compare';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { UserIdentifier } from '../api/user/user.types';
import { LocalStoreRepository } from '../local/local-store';

import { AnalyticsRepository, AnalyticsUserProps, Page } from './analytics.types';

const LAST_IDENTIFIED_PAYLOAD_KEY = '@remento/segment-repository/last-identified';

interface LastIdentifiedPayload {
  ruid: string;
  traits: AnalyticsUserProps;
}

export class SegmentAnalyticsRepository implements AnalyticsRepository {
  public readonly segment: AnalyticsBrowser;

  constructor(private localStore: LocalStoreRepository, readonly writeKey: string) {
    this.segment = AnalyticsBrowser.load(
      { writeKey },
      {
        integrations: {
          'Segment.io': {
            apiHost: `segment.remento.co/v1`,
            deliveryStrategy: {
              strategy: 'batching',
              config: {
                size: 10,
                timeout: 5000,
              },
            },
          },
        },
      },
    );
  }

  initialize(_userIdentifier: UserIdentifier): void {
    // no-op?
  }

  private shouldIdentify(ruid: string, traits: AnalyticsUserProps, overwrite: boolean): boolean {
    const lastIdentifiedPayload = this.localStore.getItem<LastIdentifiedPayload>(LAST_IDENTIFIED_PAYLOAD_KEY);
    if (lastIdentifiedPayload === null) {
      return true;
    }
    if (lastIdentifiedPayload.ruid !== ruid) {
      return overwrite;
    }
    return isEqual(lastIdentifiedPayload.traits, traits) === false;
  }

  async identify(ruid: string, traits: AnalyticsUserProps, overwrite: boolean): Promise<void> {
    if (!this.shouldIdentify(ruid, traits, overwrite)) {
      console.log(`User ${ruid} is already identified`);
      return;
    }

    await this.segment.identify(ruid, traits);

    this.localStore.setItem<LastIdentifiedPayload>(LAST_IDENTIFIED_PAYLOAD_KEY, {
      ruid,
      traits,
    });
  }

  reset(): void {
    this.segment.reset();
    this.localStore.removeItem(LAST_IDENTIFIED_PAYLOAD_KEY);
  }

  track(event: string, payload?: Record<string, unknown>): void {
    this.segment.track(event, payload);
  }

  page(page: Page, payload?: Record<string, unknown>): void {
    this.segment.page(undefined, page, payload);
  }
}
