import { createContext, PropsWithChildren, useContext } from 'react';

import { OnboardingForm } from './onboarding.form';

const OnboardingFormContext = createContext<OnboardingForm | null>(null);

export type OnboardingFormProviderProps = PropsWithChildren<{
  value: OnboardingForm;
}>;

export function OnboardingFormProvider({ value, children }: OnboardingFormProviderProps) {
  return <OnboardingFormContext.Provider value={value}>{children}</OnboardingFormContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useOnboardingForm(): OnboardingForm {
  const context = useContext(OnboardingFormContext);
  if (!context) {
    throw new Error('useOnboardingForm must be used inside a OnboardingFormProvider');
  }
  return context;
}
