import { AddressCandidateResult, GeolocationService, SuggestionResult } from './geolocation.types';

export class GeolocationServiceImpl implements GeolocationService {
  private readonly baseUrl = 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer';

  constructor(private arcgisToken: string) {}

  async suggestLocations(text: string): Promise<SuggestionResult[]> {
    // https://developers.arcgis.com/rest/services-reference/enterprise/suggest.htm
    const url = new URL(this.baseUrl + '/suggest');
    url.searchParams.set('token', this.arcgisToken);
    url.searchParams.set('f', 'json');
    url.searchParams.set('countryCode', 'USA');
    url.searchParams.set('categories', 'address');
    url.searchParams.set('text', text);

    const res = await fetch(url);
    if (res.ok) {
      const jsonResponse = await res.json();
      return jsonResponse.suggestions;
    }
    throw new Error(await res.text());
  }

  async getAddressCandidate(fullAddress: string): Promise<AddressCandidateResult | null> {
    // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-find-address-candidates.htm
    const url = new URL(this.baseUrl + '/findAddressCandidates');
    url.searchParams.set('token', this.arcgisToken);
    url.searchParams.set('f', 'json');
    url.searchParams.set('SingleLine', fullAddress);
    // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-service-output.htm#ESRI_SECTION1_42D7D3D0231241E9B656C01438209440
    url.searchParams.set('outFields', 'Region,RegionAbbr,City,StAddr,Postal');

    const res = await fetch(url);
    if (res.ok) {
      const jsonResponse = await res.json();
      if (jsonResponse.candidates[0]) {
        const candidate = jsonResponse.candidates[0];
        return {
          address: candidate.address,
          location: candidate.location,
          attributes: {
            state: candidate.attributes.Region,
            stateAbbreviation: candidate.attributes.RegionAbbr,
            city: candidate.attributes.City,
            streetAddress: candidate.attributes.StAddr,
            zip: candidate.attributes.Postal,
          },
        };
      }
      return null;
    }
    throw new Error(await res.text());
  }
}
