import { Person, PersonName } from '@remento/types/person';

export function getNameInitials(name: PersonName | null | undefined): string {
  if (!name) {
    return 'N/A';
  }
  return name.first[0] + name.last[0];
}

export function getPersonInitials(person: Person | null | undefined): string {
  return getNameInitials(person?.name);
}
