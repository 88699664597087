import { Container, Dot } from './RMButtonLoader.styles';

export function RMButtonLoader({}) {
  return (
    <Container>
      <Dot />
      <Dot />
      <Dot />
    </Container>
  );
}
