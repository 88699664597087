import { SanityClient } from '@sanity/client';

import { CmsService } from '../cms/cms.service';

import { PromptTemplate, PromptTemplateService } from './prompt-template.types';

export class PromptTemplateServiceImpl extends CmsService<PromptTemplate> implements PromptTemplateService {
  constructor(client: SanityClient) {
    super(client, {
      entity: 'prompt-template',
      additionalFields: {
        id: '_id',
        tags: `tags[]-> {
          ...,
          "id": _id,
          "imageUrl": image.asset->url
        }`,
        questions: `questions[] {
          ...,
          "id": _key
        }`,
      },
    });
  }

  getTemplates(): Promise<PromptTemplate[]> {
    return super.fetchAll();
  }

  getTemplatesByTag(tag: string): Promise<PromptTemplate[]> {
    return super.fetchAll(['$tag in tags[]->value'], { tag });
  }

  getPurchaserOnboardingTemplatesByTags(tags: string[]): Promise<PromptTemplate[]> {
    return super.fetchAll(
      ['purchaserOnboarding == true', `count((tags[]->value)[@ in [${tags.map((tag) => `"${tag}"`).join(',')}]]) > 0`],
      {},
    );
  }

  getPurchaserOnboardingTemplates(): Promise<PromptTemplate[]> {
    return super.fetchAll(['purchaserOnboarding == true']);
  }
}
