/* eslint-disable react-refresh/only-export-components */
import { createContext, PropsWithChildren, useContext } from 'react';

import { ReferralStore } from './referral.state';

const ReferralStoreContext = createContext<ReferralStore | null>(null);

export type ReferralStoreProviderProps = PropsWithChildren<{
  value: ReferralStore;
}>;

export function ReferralStoreProvider({ value, children }: ReferralStoreProviderProps) {
  return <ReferralStoreContext.Provider value={value}>{children}</ReferralStoreContext.Provider>;
}

export function useReferralStore(): ReferralStore {
  const context = useContext(ReferralStoreContext);
  if (!context) {
    throw new Error('useReferralStore must be used inside a ReferralStoreProvider');
  }

  return context;
}
