import { AnalyticsService, Events } from '../analytics.types';

import { ReferralAnalyticsService } from './referral-analytics.types';

export class DefaultReferralAnalyticsService implements ReferralAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onReferralShared(shareType: 'share-sheet' | 'email' | 'clipboard'): void {
    this.analyticsService.track(Events.ReferralShare, { shareType });
  }
}
