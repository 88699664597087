import { z } from 'zod';

import { AuditMetadata, BaseEntity, BaseMutation } from './base-entity';

// **** USER **** //

export const PRE_RENEWAL_PHASE_DURATION = 30 * 24 * 60 * 60 * 1000;

export enum UserStatus {
  REGISTERED = 'registered',
  INVITED = 'invited',
  PLACEHOLDER = 'placeholder',
  DELETED = 'deleted',
}

export interface UserPhone {
  countryCode: string;
  number: string;
}

export interface UserCommunicationChannels {
  email: string;
  phone: UserPhone | null;
}

export enum UserTag {
  HOST = 'host',
  STORYTELLER = 'storyteller',
  COLLABORATOR = 'collaborator',

  BABYBOOK_HOST = 'babybook-host',
  BABYBOOK_STORYTELLER = 'babybook-storyteller',
  BABYBOOK_COLLABORATOR = 'babybook-collaborator',

  BIOGRAPHY_HOST = 'biography-host',
  BIOGRAPHY_STORYTELLER = 'biography-storyteller',
  BIOGRAPHY_COLLABORATOR = 'biography-collaborator',

  AUTOBIOGRAPHY_HOST = 'autobiography-host',
  AUTOBIOGRAPHY_STORYTELLER = 'autobiography-storyteller',
  AUTOBIOGRAPHY_COLLABORATOR = 'autobiography-collaborator',
}

export const PROJECT_TYPE_TO_USER_HOST_TAGS = {
  BABYBOOK: [UserTag.BABYBOOK_HOST, UserTag.STORYTELLER, UserTag.BABYBOOK_STORYTELLER],
  BIOGRAPHY: [UserTag.BIOGRAPHY_HOST],
  AUTOBIOGRAPHY: [UserTag.AUTOBIOGRAPHY_HOST, UserTag.STORYTELLER, UserTag.AUTOBIOGRAPHY_STORYTELLER],
};

export const PROJECT_TYPE_TO_USER_STORYTELLER_TAG = {
  BABYBOOK: UserTag.BABYBOOK_STORYTELLER,
  BIOGRAPHY: UserTag.BIOGRAPHY_STORYTELLER,
  AUTOBIOGRAPHY: UserTag.AUTOBIOGRAPHY_STORYTELLER,
};

export const PROJECT_TYPE_TO_USER_COLLABORATOR_TAG = {
  BABYBOOK: UserTag.BABYBOOK_COLLABORATOR,
  BIOGRAPHY: UserTag.BIOGRAPHY_COLLABORATOR,
  AUTOBIOGRAPHY: UserTag.AUTOBIOGRAPHY_COLLABORATOR,
};

export enum NotificationStatus {
  PENDING = 'pending',
  SENT = 'sent',
}

export interface UserPaymentMethod {
  stripeId: string;
  type: string;
  last4digits?: string;
}

export interface UserAvailableSubscription {
  id: string;
  stripeSubscriptionId: string;
  stripePaymentMethodId: string;
  stripeSubscriptionStartsOn: number;
  seriesId: string;
  storyteller: {
    firstName?: string;
    lastName?: string;
  };
}

export enum UserOnboardingActionType {
  PHONE_COLLECTED = 'phoneCollected',
  TERMS_ACCEPTED = 'termsAccepted',
  POLL_CREATED = 'pollCreated',
  WELCOME_SMS_SENT = 'welcomeSmsSent',
  PURCHASER = 'purchaser',
  STORYTELLER = 'storyteller',
  COLLABORATOR = 'collaborator',
  STORY_PERSPECTIVE_SETTINGS_VIEWED = 'storyPerspectiveSettingsViewed',
  PROJECT_STORY_RECORDED_REFERRAL_SENT_1 = 'projectStoryRecordedReferralSent1',
  PROJECT_STORY_RECORDED_REFERRAL_SENT_2 = 'projectStoryRecordedReferralSent2',
  HEIRLOOM_CLOUD_PROMO_EVENT_SENT = 'heirloomCloudPromoEventSent',
  SUBSCRIPTION_RENEW_OFFER_VIEWED = 'subscriptionRenewOfferViewed',
  SUBSCRIPTION_WILL_EXPIRE_BANNER_CLOSED = 'subscriptionWillExpireBannerClosed',
}

export interface PendingUserOnboardingAction {
  done: false;
}

export interface FinishedUserOnboardingAction {
  done: true;
  timestamp: number;
}

export type UserOnboardingAction = PendingUserOnboardingAction | FinishedUserOnboardingAction;
export type UserOnboardingHistory = Partial<Record<UserOnboardingActionType, UserOnboardingAction>>;

export enum UserSubscriptionStatus {
  PENDING = 'pending',
  ACTIVE = 'started',
  INACTIVE = 'inactive',
  CANCELED = 'canceled',
}

export enum SubscriptionType {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum SubscriptionRenewalType {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  CANCEL = 'cancel',
}

export interface UserSubscription {
  stripeSubscriptionId: string;
  stripePaymentMethodId?: string;
  startsOn: number;
  endsOn: number;
  status: UserSubscriptionStatus;
  type: SubscriptionType;
  renewalType?: SubscriptionRenewalType | null;
}

export interface AvailableStoryteller {
  personId: string;
  userId: string;
  stripeSubscriptionId?: string;
  subscriptionOwnerPersonId: string;
  subscriptionOwnerUserId: string;
}

export interface AvailableStoryteller {
  personId: string;
  userId: string;
  stripeSubscriptionId?: string;
  subscriptionOwnerPersonId: string;
  subscriptionOwnerUserId: string;
}

export enum UserCouponType {
  PROJECT = 'project',
}

export interface UserCoupon {
  type: UserCouponType;
  value: string;
}

export interface User extends BaseEntity<UserStatus> {
  ruid: string;
  authId: string;
  personId: string;
  tags: UserTag[];
  contactIds: string[];
  communicationChannels: UserCommunicationChannels;
  onboardingHistory: UserOnboardingHistory;
  acl: string[];
  // This property should NOT be used for filtering purposes
  stripeCustomerId: string | null;
  paymentMethods: UserPaymentMethod[];
  availableSubscriptions: UserAvailableSubscription[];
  subscription: UserSubscription | null;
  storytellers: AvailableStoryteller[];
  coupons?: UserCoupon[];
}

export enum UserMutationType {
  REGISTER_USER = 'register-user',
  ADD_REF_ID = 'add-ref-id',
  SET_RUID = 'add-contact-id',
  SET_EMAIL = 'set-email',
  SET_PHONE = 'set-phone',
  ADD_TAGS = 'add-tag',
  REMOVE_TAG = 'remove-tag',
  SET_STRIPE_CUSTOMER_ID = 'set-stripe-customer-id',
  ADD_PAYMENT_METHOD = 'add-payment-method',
  SET_AVAILABLE_SUBSCRIPTIONS = 'set-available-subscription',
  ADD_AVAILABLE_SUBSCRIPTION = 'add-available-subscription',
  DELETE_AVAILABLE_SUBSCRIPTION = 'delete-available-subscription',
  REMOVE_ACL = 'remove-acl',
  SET_ONBOARDING_HISTORY = 'set-onboarding-history',
  SET_SUBSCRIPTION = 'set-subscription',
  SET_SUBSCRIPTION_START_DATE = 'set-subscription-start-date',
  SET_SUBSCRIPTION_END_DATE = 'set-subscription-start-date',
  SET_SUBSCRIPTION_STATUS = 'set-subscription-status',
  ADD_AVAILABLE_STORYTELLER = 'add-available-storyteller',
  DELETE_AVAILABLE_STORYTELLER = 'delete-available-storyteller',
  SET_SUBSCRIPTION_RENEWAL_TYPE = 'set-subscription-renewal-type',
  SET_SUBSCRIPTION_TYPE = 'set-subscription-type',
  ADD_COUPON = 'add-coupon',
  DELETE_COUPON = 'delete-coupon',
}

export type RegisterUserMutation = BaseMutation<UserMutationType.REGISTER_USER, null>;
export type AddRefIdUserMutation = BaseMutation<UserMutationType.ADD_REF_ID, string>;
export type SetUserEmailMutation = BaseMutation<UserMutationType.SET_EMAIL, string>;
export type SetUserPhoneMutation = BaseMutation<UserMutationType.SET_PHONE, UserPhone | null>;
export type SetRuidMutation = BaseMutation<UserMutationType.SET_RUID, string>;
export type AddUserTagsMutation = BaseMutation<UserMutationType.ADD_TAGS, UserTag[]>;
export type RemoveUserTagMutation = BaseMutation<UserMutationType.REMOVE_TAG, UserTag>;
export type SetUserStripeCustomerIdMutation = BaseMutation<UserMutationType.SET_STRIPE_CUSTOMER_ID, string>;
export type AddUserPaymentMethodMutation = BaseMutation<UserMutationType.ADD_PAYMENT_METHOD, UserPaymentMethod>;
export type AddUserAvailableSubscription = BaseMutation<
  UserMutationType.ADD_AVAILABLE_SUBSCRIPTION,
  UserAvailableSubscription
>;
export type DeleteUserAvailableSubscription = BaseMutation<
  UserMutationType.DELETE_AVAILABLE_SUBSCRIPTION,
  UserAvailableSubscription
>;
export type SetUserAvailableSubscription = BaseMutation<
  UserMutationType.SET_AVAILABLE_SUBSCRIPTIONS,
  Array<UserAvailableSubscription>
>;
export type RemoveAclUserMutation = BaseMutation<UserMutationType.REMOVE_ACL, string>;
export type SetUserOnboardingHistoryMutation = BaseMutation<
  UserMutationType.SET_ONBOARDING_HISTORY,
  | { action: UserOnboardingActionType; done: true; timestamp: number }
  | { action: UserOnboardingActionType; done: false }
>;
export type SetSubscriptionMutation = BaseMutation<UserMutationType.SET_SUBSCRIPTION, UserSubscription>;
export type SetSubscriptionStartDateMutation = BaseMutation<UserMutationType.SET_SUBSCRIPTION_START_DATE, number>;
export type SetSubscriptionEndDateMutation = BaseMutation<UserMutationType.SET_SUBSCRIPTION_END_DATE, number>;
export type SetUserSubscriptionStatusMutation = BaseMutation<
  UserMutationType.SET_SUBSCRIPTION_STATUS,
  UserSubscriptionStatus
>;
export type AddUserAvailableStorytellerMutation = BaseMutation<
  UserMutationType.ADD_AVAILABLE_STORYTELLER,
  AvailableStoryteller
>;
export type DeleteUserAvailableStorytellerMutation = BaseMutation<
  UserMutationType.DELETE_AVAILABLE_STORYTELLER,
  AvailableStoryteller
>;
export type SetUserSubscriptionRenewalTypeMutation = BaseMutation<
  UserMutationType.SET_SUBSCRIPTION_RENEWAL_TYPE,
  SubscriptionRenewalType | null | undefined
>;
export type SetUserSubscriptionTypeMutation = BaseMutation<UserMutationType.SET_SUBSCRIPTION_TYPE, SubscriptionType>;
export type AddUserCouponMutation = BaseMutation<UserMutationType.ADD_COUPON, UserCoupon[]>;
export type DeleteUserCouponMutation = BaseMutation<UserMutationType.DELETE_COUPON, UserCoupon>;

export type UserMutation =
  | RegisterUserMutation
  | AddRefIdUserMutation
  | SetRuidMutation
  | SetUserEmailMutation
  | SetUserPhoneMutation
  | AddUserTagsMutation
  | RemoveUserTagMutation
  | SetUserStripeCustomerIdMutation
  | AddUserPaymentMethodMutation
  | SetUserAvailableSubscription
  | AddUserAvailableSubscription
  | DeleteUserAvailableSubscription
  | RemoveAclUserMutation
  | SetUserOnboardingHistoryMutation
  | SetSubscriptionMutation
  | SetSubscriptionStartDateMutation
  | SetSubscriptionEndDateMutation
  | SetUserSubscriptionStatusMutation
  | AddUserAvailableStorytellerMutation
  | DeleteUserAvailableStorytellerMutation
  | SetUserSubscriptionRenewalTypeMutation
  | SetUserSubscriptionTypeMutation
  | AddUserCouponMutation
  | DeleteUserCouponMutation;

// **** USER SESSION **** //

export interface UserSession {
  id: string;
  audit: AuditMetadata;
  metadata: Record<string, unknown>;
}

export const UserMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(UserMutationType.SET_EMAIL),
    value: z.string().email(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(UserMutationType.SET_PHONE),
    value: z
      .object({
        number: z.string(),
        countryCode: z.string(),
      })
      .nullable(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(UserMutationType.SET_ONBOARDING_HISTORY),
    value: z.discriminatedUnion('done', [
      z.object({
        done: z.literal(true),
        action: z.nativeEnum(UserOnboardingActionType),
        timestamp: z.number().int().min(0),
      }),
      z.object({
        done: z.literal(false),
        action: z.nativeEnum(UserOnboardingActionType),
      }),
    ]),
    vclock: z.number(),
    version: z.number(),
  }),
]);

// **** USER PURCHASE **** //

export enum UserPurchaseStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
  REFUNDED = 'refunded',
}

export enum UserPurchaseProductType {
  DIGITAL = 'digital',
  STORYTELLER = 'project',
  BOOK = 'book',
  EBOOK = 'ebook',
  BUNDLE = 'bundle',
  UNKNOWN = 'unknown',
}

export enum UserPurchaseProductStatus {
  PAID = 'paid',
  REFUNDED = 'refunded',
}

export interface UserPurchaseProduct {
  type: UserPurchaseProductType;
  count: number;
  price?: number;
  status: UserPurchaseProductStatus | undefined;
}

export enum UserPurchaseStripeMetadataType {
  PAYMENT = 'payment',
  SUBSCRIPTION = 'subscription',
}

export interface UserPurchaseStripeBaseMetadata {
  checkoutId: string;
}
export interface UserPurchaseStripePaymentMetadata extends UserPurchaseStripeBaseMetadata {
  type: UserPurchaseStripeMetadataType.PAYMENT;
  paymentId: string | null;
}

export interface UserPurchaseStripeSubscriptionMetadata extends UserPurchaseStripeBaseMetadata {
  type: UserPurchaseStripeMetadataType.SUBSCRIPTION;
  subscriptionId: string | null;
  paymentId: string | null;
}

export type UserPurchaseStripeMetadata = UserPurchaseStripePaymentMetadata | UserPurchaseStripeSubscriptionMetadata;

export interface UserPurchase extends BaseEntity<UserPurchaseStatus> {
  stripe: UserPurchaseStripeMetadata | null;
  price: number | null;
  products: UserPurchaseProduct[];
}

export enum UserPurchaseMutationType {
  SET_COMPLETED = 'set-completed',
  SET_FAILED = 'set-failed',
  SET_REFUNDED = 'set-refunded',
  SET_PRODUCTS = 'set-products',
}

export type SetUserPurchaseRefundedMutation = BaseMutation<UserPurchaseMutationType.SET_REFUNDED, undefined>;
export type SetUserPurchaseCompletedMutation = BaseMutation<
  UserPurchaseMutationType.SET_COMPLETED,
  { price: number; paymentId: string | null; subscriptionId: string | null; products: UserPurchaseProduct[] }
>;
export type SetUserPurchaseFailedMutation = BaseMutation<
  UserPurchaseMutationType.SET_FAILED,
  { price: number; paymentId: string | null; subscriptionId: string | null; products: UserPurchaseProduct[] }
>;
export type SetPurchaseProductsMutation = BaseMutation<UserPurchaseMutationType.SET_PRODUCTS, UserPurchaseProduct[]>;

export type UserPurchaseMutation =
  | SetUserPurchaseRefundedMutation
  | SetUserPurchaseCompletedMutation
  | SetUserPurchaseFailedMutation
  | SetPurchaseProductsMutation;
