import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Content = styled(motion.div)`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: var(--spacing-lg);
  background-color: var(--surface);
  border-radius: var(--radius-round);
  box-shadow: var(--elevation-2);
  overflow-y: auto;
  pointer-events: auto;

  &[data-variant='regular'] {
    max-width: 32rem;
  }

  &[data-variant='large'] {
    max-width: 44.5rem;
  }

  &[data-variant='extra-large'] {
    max-width: 64rem;
  }

  &[data-variant='full-screen'] {
    max-width: 44.5rem;
    height: calc(100vh - 4rem);
  }

  &[data-variant='regular-mobile'],
  &[data-variant='large-mobile'],
  &[data-variant='extra-large-mobile'] {
    max-height: 100dvh;
    max-width: 100vw;
    overflow-x: hidden;
    align-self: flex-end;
    margin: 0;
    max-width: unset;
    border-radius: var(--radius-round) var(--radius-round) 0 0;
  }

  &[data-variant='full-screen-mobile'] {
    max-height: 100dvh;
    max-width: 100vw;
    overflow-x: hidden;
    height: 100dvh;
    border-radius: 0;
    margin: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
