import { SanityClient } from '@sanity/client';

import { CmsConfig } from './cms.types';

export abstract class CmsService<T> {
  constructor(private client: SanityClient, private config: CmsConfig) {}

  protected fetchAll(
    filters: string[] = [],
    params: Record<string, string> = {},
    configOverride?: Partial<CmsConfig>,
  ): Promise<T[]> {
    const config = {
      ...this.config,
      ...configOverride,
    };

    let query = `*[_type == "${config.entity}"`;
    if (filters.length) {
      query += ` && ${filters.join(' && ')}`;
    }
    query += ']';

    if (config.additionalFields) {
      const additionalFields = Object.entries(config.additionalFields)
        .map(([key, value]) => `"${key}": ${value}`)
        .join(',');

      query += ` {
        ...,
        ${additionalFields}
      }`;
    }

    if (config.sort) {
      query += ` | ${config.sort.map((sort) => `order(${sort})`).join(' |')}`;
    }

    return this.client.fetch<T[]>(query, params);
  }
}
