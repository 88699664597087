export const Spacings = {
  none: '0rem',
  '3xs': '0.125rem',
  '2xs': '0.25rem',
  xs: '0.5rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '1.5rem',
  '2xl': '2rem',
  '3xl': '2.5rem',
  '4xl': '4rem',
  '5xl': '5rem',
  '6xl': '6rem',
} as const;
