import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { PromptDraftsStoreProvider } from '@/modules/project/states/prompt-drafts.state.context';
import { RementoPage } from '@/modules/routing';

import { OnboardingContextProvider } from '../onboarding.context';
import { createOnboardingForm } from '../onboarding.form';
import { OnboardingFormProvider } from '../onboarding.form.context';

export function OnboardingContainer() {
  const form = useMemo(() => {
    return createOnboardingForm({
      gift: {
        message: 'I’ve gifted you Remento, an easy way to share memories from your past with me and our family.',
      },
    });
  }, []);

  return (
    <RementoPage type="default">
      <PromptDraftsStoreProvider>
        <OnboardingFormProvider value={form}>
          <OnboardingContextProvider>
            <Outlet />
          </OnboardingContextProvider>
        </OnboardingFormProvider>
      </PromptDraftsStoreProvider>
    </RementoPage>
  );
}
