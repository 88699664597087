import { styled } from '@linaria/react';

import { LottieAnimation } from '../LottieAnimation';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Center = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 6rem;
`;

export const LottieLoader = styled(LottieAnimation)`
  position: relative;
  width: 6rem;
  height: 6rem;
  flex: 0 0 6rem;
`;
