import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAsyncEffect } from '@/hooks';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { OnboardingDialog } from '@/services/local/onboarding-dialog';

import { useNavbarProjectId, useNavbarStore } from '../navbar/states';
import { getRouteFromPathname } from '../routing/routes';

export function useNextOnboardingDialog(): OnboardingDialog | null {
  // Services
  const { onboardingDialogService } = useServices();
  const { pathname } = useLocation();

  // State
  const [nextOnboardingDIalog, setNextOnboardingDialog] = useState<OnboardingDialog | null>(null);
  const user = useUser();
  const navbarStore = useNavbarStore();
  const projectId = useNavbarProjectId(navbarStore);
  const route = useMemo(() => getRouteFromPathname(pathname), [pathname]);

  useAsyncEffect(
    async (checkpoint) => {
      if (user == null || projectId == null || route == null) {
        setNextOnboardingDialog(null);
        return;
      }

      const onboardingDialog = await onboardingDialogService.getNextOnboardingDialog(route, projectId);
      checkpoint();
      setNextOnboardingDialog(onboardingDialog);
    },
    [onboardingDialogService, projectId, route, user],
  );

  return nextOnboardingDIalog;
}
