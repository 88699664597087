import { notNull } from '@remento/utils/array/notNull';

import { logger } from '@/logger';

import { LocalStoreRepository } from './local-store.types';

export class LocalPersistentStoreRepository implements LocalStoreRepository {
  constructor(
    private permanentKeys: Set<string> = new Set<string>(),
    private permanentKeysPrefixes: Array<string> = [],
  ) {}

  getItem<T = string>(key: string): T | null {
    const value = localStorage.getItem(key);

    try {
      return value !== null ? JSON.parse(value) : null;
    } catch {
      // Migrate values that are not stringified yet
      this.setItem(key, value);
      return value as T;
    }
  }

  setItem<T = string>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear(prefix?: string): void {
    const allKeys = Object.keys(localStorage);
    const keysToDelete = allKeys
      .filter((key) => this.permanentKeys.has(key) == false)
      .filter((key) => this.permanentKeysPrefixes.some((prefix) => key.startsWith(prefix)) == false)
      .filter((key) => !prefix || key.startsWith(prefix));
    keysToDelete.forEach((key) => {
      localStorage.removeItem(key);
    });

    logger.debug('LOCAL_PERSISTENT_STORE.CLEAR', { keys: keysToDelete });
  }

  keys(prefix?: string): string[] {
    let keys = Object.keys(localStorage);
    if (prefix) {
      keys = keys.filter((key) => key.startsWith(prefix));
    }
    return keys;
  }

  entries<T = string>(prefix?: string): [string, T][] {
    const keys = this.keys(prefix);
    return keys
      .map((key) => {
        try {
          return [key, this.getItem<T>(key) as T];
        } catch {
          return [key, localStorage.getItem(key)];
        }
      })
      .filter(notNull) as [string, T][];
  }
}
