import { styled } from '@linaria/react';

export const Page = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  body[data-mobile='true'] & {
    flex: 1;
  }

  body[data-mobile='false'] & {
    padding-bottom: var(--spacing-2xl);
  }
`;

export const Image = styled.img``;

export const Footer = styled.div`
  body[data-mobile='true'] & {
    width: 100%;
    padding: var(--spacing-xl);
  }
`;
