import { Colors } from '../base/colors';
import { Elevations } from '../base/elevations';
import { Fonts } from '../base/fonts';
import { Radius } from '../base/radius';
import { Spacings } from '../base/spacings';
import { generateCssVars } from '../css-var-generator';

export const baseStyles = [
  generateCssVars(Colors, 'color'),
  generateCssVars(Elevations, 'elevation'),
  generateCssVars(Fonts, 'font'),
  generateCssVars(Radius, 'radius'),
  generateCssVars(Spacings, 'spacing'),
].join('\n');
