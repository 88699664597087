const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const UUID_LENGTH = 20;

export function secureUuid(): string {
  const min = 0;
  const max = ALPHABET.length - 1;

  const randomInts = new Uint8Array(UUID_LENGTH);
  crypto.getRandomValues(randomInts);

  return Array.from(randomInts)
    .map((value) => {
      const charIndex = Math.floor((value / 256) * (max - min + 1)) + min;
      return ALPHABET[charIndex];
    })
    .join('');
}
