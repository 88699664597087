import { LocalStoreRepository } from '@/services/local/local-store';

import { AnonymousVote, PollAnonymousVoteRepository } from './poll.types';

export class LocalPollAnonymousVoteRepository implements PollAnonymousVoteRepository {
  constructor(private localStore: LocalStoreRepository) {}

  getVotes(): AnonymousVote[] {
    return this.localStore.getItem('votes') ?? [];
  }

  getAnonymousVote(pollId: string | null): AnonymousVote | null {
    const votes = this.getVotes();
    return votes.find((vote) => vote.pollId == pollId) ?? null;
  }

  saveVote(pollId: string, voteId: string): void {
    const votes = this.getVotes();
    votes.push({ pollId, voteId });
    this.localStore.setItem('votes', votes);
  }

  hasVoted(pollId: string): boolean {
    const votes = this.getVotes();
    return votes.some((vote) => vote.pollId == pollId);
  }

  clear(): void {
    this.localStore.clear();
  }
}
