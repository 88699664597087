import { PropsWithChildren, useCallback, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import SpinnerLottieAnimation from '@/assets/spinner.lottie.json';

import { Cancel, LottieWrapper, ToastContent } from './RMLoadingToast.styles';

type RMLoadingToast = PropsWithChildren<{
  onLoadingCancel?: () => void;
}>;

export function RMLoadingToast({ onLoadingCancel, children }: RMLoadingToast) {
  const animationRef = useRef<AnimationItem | null>(null);
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      if (animationRef.current) {
        animationRef.current.destroy();
        animationRef.current = null;
      }
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: SpinnerLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  return (
    <ToastContent>
      <LottieWrapper ref={lottieRef} />
      {children}
      {onLoadingCancel && (
        <Cancel onClick={onLoadingCancel} underline type="sans" size="xs" bold color="on-surface-primary">
          Cancel
        </Cancel>
      )}
    </ToastContent>
  );
}
