import { DeleteMutation } from '@remento/types/base-entity';
import { EntityResponse, EntityType } from '@remento/types/entity';
import { UnauthorizedError } from '@remento/types/error';
import {
  AddPromptImageMutation,
  MovePromptMutation,
  Project,
  ProjectMutationType,
  Prompt,
  PromptMutationType,
  Question,
  QuestionMutationType,
  RemovePromptImageMutation,
  SendPromptMutation,
  SetDefaultProjectStoryPerspectiveMutation,
  SetProjectActiveMutation,
  SetProjectCoverAssetMutation,
  SetProjectGiftMessageMutation,
  SetProjectNameMutation,
  SetProjectPausedMutation,
  SetProjectStartOnMutation,
  SetQuestionTextMutation,
} from '@remento/types/project';
import { StoryPerspectiveType } from '@remento/types/story';

import { auth } from '@/firebase';
import { logger } from '@/logger';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';
import { EntityMutation } from '../cache';

import {
  CreateProjectPayload,
  CreatePromptPayload,
  CreditsOrderCheckoutRequest,
  CreditsOrderCheckoutResponse,
  ProjectService,
  RequestAccessPayload,
} from './project.types';

export class DefaultProjectService implements ProjectService {
  constructor(private authorizationService: AuthorizationService) {}

  async createProject(payload: CreateProjectPayload, storytellerPhoto?: File): Promise<void> {
    const formData = new FormData();
    formData.set('data', JSON.stringify(payload));
    if (storytellerPhoto) {
      formData.set('photo', storytellerPhoto);
    }

    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post('/projects', formData, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async requestAccess(projectId: string, payload: RequestAccessPayload): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post(`/projects/${projectId}/request-access`, payload, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async getPersonProject(personId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/people/${personId}/projects`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getProjects(scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();

    try {
      const { data } = await api.get<EntityResponse>(`/projects`, {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
        signal: scope?.signal,
      });
      return data;
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        // TODO: Remove this once the forbidden issue has been resolved
        // This should NEVER happen, but for some weird reason it may be happening sometimes.
        // In theory, we always check if there's a user before calling this method.
        // Keep this log until REM-4741 is fixed.
        const token = await auth.currentUser?.getIdToken();
        const tokenResult = await auth.currentUser?.getIdTokenResult();
        console.trace('TRACE.GET_PROJECTS_CALLED_WITHOUT_USER');
        logger.warn('GET_PROJECTS_CALLED_WITHOUT_USER', {
          firebaseUser: auth.currentUser != null,
          firebaseToken: token != null && token.length > 0,
          firebaseTokenResult: tokenResult != null,
          firebaseTokenAuthTime: tokenResult?.authTime,
          firebaseTokenExpirationTime: tokenResult?.expirationTime,
          firebaseTokenIssuedAtTime: tokenResult?.issuedAtTime,
          authorizationHeader: credentialsForRequest.headers.Authorization != null,
          authorizationHeaderStart: String(credentialsForRequest.headers.Authorization).substring(0, 15),
        });
      }

      throw error;
    }
  }

  async getProject(projectId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/projects/${projectId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getProjectsFromRecipientPersonId(personId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/people/${personId}/projects`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getProjectFromStoryId(storyId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/stories/${storyId}/project`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getProjectPrompts(projectId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/projects/${projectId}/prompts`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getProjectPromptsCatchupData(projectId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/projects/${projectId}/prompts/catchup`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getPrompt(promptId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/prompts/${promptId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getPromptQuestions(promptId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/prompts/${promptId}/questions`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getQuestion(questionId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/questions/${questionId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getPromptRecordLink(promptId: string, scope?: RequestScope): Promise<string> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<{ link: string }>(`/prompts/${promptId}/record-link`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data.link;
  }

  createDeletePromptMutation(prompt: Prompt): DeleteMutation {
    return {
      type: 'delete',
      value: undefined,
      vclock: prompt.vclock,
      version: prompt.version,
    };
  }

  createMovePromptMutation(prompt: Prompt, newIndex: number): EntityMutation<MovePromptMutation>[] {
    return [
      {
        type: EntityType.PROMPT,
        id: prompt.id,
        mutations: [
          {
            type: PromptMutationType.MOVE,
            value: newIndex,
            vclock: prompt.vclock,
            version: prompt.version,
          },
        ],
      },
    ];
  }

  createAddPromptImageMutation(prompt: Prompt, imageId: string): EntityMutation<AddPromptImageMutation>[] {
    return [
      {
        type: EntityType.PROMPT,
        id: prompt.id,
        mutations: [
          {
            type: PromptMutationType.ADD_IMAGE,
            value: imageId,
            vclock: prompt.vclock,
            version: prompt.version,
          },
        ],
      },
    ];
  }

  createRemovePromptImageMutation(prompt: Prompt, imageId: string): EntityMutation<RemovePromptImageMutation>[] {
    return [
      {
        type: EntityType.PROMPT,
        id: prompt.id,
        mutations: [
          {
            type: PromptMutationType.REMOVE_IMAGE,
            value: imageId,
            vclock: prompt.vclock,
            version: prompt.version,
          },
        ],
      },
    ];
  }

  createSetQuestionTextMutation(question: Question, text: string | null): EntityMutation<SetQuestionTextMutation>[] {
    return [
      {
        type: EntityType.QUESTION,
        id: question.id,
        mutations: [
          {
            type: QuestionMutationType.SET_TEXT,
            value: text,
            vclock: question.vclock,
            version: question.version,
          },
        ],
      },
    ];
  }

  createSetStartOnMutation(project: Project, startDate: number): EntityMutation<SetProjectStartOnMutation>[] {
    return [
      {
        type: EntityType.PROJECT,
        id: project.id,
        mutations: [
          {
            type: ProjectMutationType.SET_START_ON,
            value: startDate,
            vclock: project.vclock,
            version: project.version,
          },
        ],
      },
    ];
  }

  createSetProjectMessageMutation(
    project: Project,
    gift: { message: string; from: string },
  ): EntityMutation<SetProjectGiftMessageMutation>[] {
    return [
      {
        type: EntityType.PROJECT,
        id: project.id,
        mutations: [
          {
            type: ProjectMutationType.SET_GIFT_MESSAGE,
            value: gift,
            vclock: project.vclock,
            version: project.version,
          },
        ],
      },
    ];
  }

  createSetProjectActiveMutation(project: Project): EntityMutation<SetProjectActiveMutation>[] {
    return [
      {
        type: EntityType.PROJECT,
        id: project.id,
        mutations: [
          {
            type: ProjectMutationType.SET_ACTIVE,
            value: null,
            vclock: project.vclock,
            version: project.version,
          },
        ],
      },
    ];
  }

  createSetProjectPausedMutation(project: Project): EntityMutation<SetProjectPausedMutation>[] {
    return [
      {
        type: EntityType.PROJECT,
        id: project.id,
        mutations: [
          {
            type: ProjectMutationType.SET_PAUSED,
            value: null,
            vclock: project.vclock,
            version: project.version,
          },
        ],
      },
    ];
  }

  createSetCoverImageMutation(
    project: Project,
    newAvatarAssetId: string,
  ): EntityMutation<SetProjectCoverAssetMutation>[] {
    return [
      {
        type: EntityType.PROJECT,
        id: project.id,
        mutations: [
          {
            type: ProjectMutationType.SET_COVER_ASSET,
            value: newAvatarAssetId,
            vclock: project.vclock,
            version: project.version,
          },
        ],
      },
    ];
  }

  createSetProjectNameMutation(project: Project, newName: string): EntityMutation<SetProjectNameMutation>[] {
    return [
      {
        type: EntityType.PROJECT,
        id: project.id,
        mutations: [
          {
            type: ProjectMutationType.SET_NAME,
            value: newName,
            vclock: project.vclock,
            version: project.version,
          },
        ],
      },
    ];
  }

  createSetSentMutation(prompt: Prompt): EntityMutation<SendPromptMutation>[] {
    return [
      {
        type: EntityType.PROMPT,
        id: prompt.id,
        mutations: [
          {
            type: PromptMutationType.SEND,
            value: Date.now(),
            vclock: prompt.vclock,
            version: prompt.version,
          },
        ],
      },
    ];
  }

  createSetDefaultStoryPerspectiveMutation(
    project: Project,
    perspective: StoryPerspectiveType,
  ): EntityMutation<SetDefaultProjectStoryPerspectiveMutation>[] {
    return [
      {
        type: EntityType.PROJECT,
        id: project.id,
        mutations: [
          {
            type: ProjectMutationType.SET_DEFAULT_STORY_PERSPECTIVE,
            value: perspective,
            vclock: project.vclock,
            version: project.version,
          },
        ],
      },
    ];
  }

  async createPrompts(projectId: string, payload: CreatePromptPayload[]): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<EntityResponse>(`/projects/${projectId}/prompts`, payload, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async deletePrompt(promptId: string, mutation: DeleteMutation): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<EntityResponse>(`/prompts/${promptId}/delete`, mutation, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async checkoutCredits(request: CreditsOrderCheckoutRequest): Promise<CreditsOrderCheckoutResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<CreditsOrderCheckoutResponse>(`/checkout/credits`, request, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }
}
