import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { getAccountRenewPromoPath } from '@/modules/routing';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { CustomOnboardingDialogRenderProps } from '@/services/local/onboarding-dialog';
import { captureException } from '@/utils/captureException';

export function SubscriptionRenewOfferOnboardingDialogContainer({
  onSetOnboardingDone,
}: CustomOnboardingDialogRenderProps) {
  const navigate = useNavigate();
  const user = useUser();

  const handleContinue = useCallback(async () => {
    try {
      await onSetOnboardingDone();
      navigate(getAccountRenewPromoPath());
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [navigate, onSetOnboardingDone]);

  const handleClose = useCallback(async () => {
    try {
      await onSetOnboardingDone();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [onSetOnboardingDone]);

  return (
    <RMDialog.Root open onClose={handleClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title="Special offer: Save $25 on renewal!"
          rightAdornment={<RMCloseButton onClick={handleClose} />}
        />

        <RMDialog.Body>
          <RMText type="sans" size="xs" color="on-surface-primary">
            Your Remento subscription is set to renew on {dayjs(user?.subscription?.endsOn).format('MM/DD/YYYY')}, and
            we’ve got a limited-time offer on your renewal of Remento for an extra year.
          </RMText>
        </RMDialog.Body>

        <RMDialog.Footer>
          <RMButton background="outlined" autoLoading onClick={handleContinue}>
            Learn more
          </RMButton>
          <RMButton background="primary" autoLoading onClick={handleContinue}>
            Claim offer
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
