import { AssetAlternativeView } from '@remento/types/alternative';
import { Asset } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { QueryClient } from '@tanstack/react-query';

import { AssetCacheService, AssetService } from '@/services/api/asset';

import { EntityCacheManagerService } from '../cache/entity-cache-manager.types';

export class DefaultAssetCacheService implements AssetCacheService {
  constructor(
    private remoteService: AssetService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  getAsset(assetId: string): Promise<Asset | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.ASSET, assetId, () =>
        this.remoteService.getAsset(assetId),
      ),
    );
  }

  getAssetAlternatives(assetId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.ASSET_ALTERNATIVE, { assetId }, () =>
        this.remoteService.getAssetAlternatives(assetId),
      ),
    );
  }

  getAlternative(alternativeId: string): Promise<AssetAlternativeView | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.ASSET_ALTERNATIVE, alternativeId, () =>
        this.remoteService.getAlternative(alternativeId),
      ),
    );
  }
}
