import { PropsWithChildren } from 'react';

import { RMText } from '../RMText/RMText';

export type RMErrorLinkProps = PropsWithChildren<{
  href: string;
}>;

export function RMErrorLink({ href, children }: RMErrorLinkProps) {
  return (
    <a href={href}>
      <RMText size="s" type="sans" color="primary" underline>
        {children}
      </RMText>
    </a>
  );
}
