import { AuditOperationMetadata, BaseEntityStatus, BaseMutation } from '@remento/types/base-entity';
import {
  CachedCollection,
  EntityResponse,
  EntityResponseMap,
  EntityType,
  EntityTypeMapping,
} from '@remento/types/entity';
import { notNull } from '@remento/utils/array/notNull';
import { matchQuery, QueryClient, QueryKey } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';

import { logger } from '@/logger';
import { AuthService } from '@/services/api/auth/auth.types';

import { api } from '../api';
import { AuthorizationService } from '../authorization';

import {
  CachedValue,
  CacheKey,
  CollectionId,
  CompareFn,
  EntityCacheManagerService,
  EntityMutation,
  ObserveEntityConfig,
  OptimisticMutationManager,
  OptimisticMutationResult,
  Query,
  QueryFn,
} from './entity-cache-manager.types';

const COLLECTION_PREFIX = 'collection';
const ENTITY_PREFIX = 'entity';

export class EntityCacheManagerServiceImpl implements EntityCacheManagerService {
  private optimisticMutationManagers: Partial<Record<EntityType, OptimisticMutationManager>> = {};
  private userId: string | null = null;

  constructor(
    private queryClient: QueryClient,
    private authService: AuthService,
    private authorizationService: AuthorizationService,
  ) {}

  async initialize(optimisticMutationManagers: Partial<Record<EntityType, OptimisticMutationManager>>) {
    this.optimisticMutationManagers = optimisticMutationManagers;
    this.userId = this.authService.getUserIdentifier().id;
    this.authService.onTokenChanged((token) => {
      if (token != null) {
        const parsedJwt = jwtDecode<{ user_id: string }>(token);
        const tokenUserId = parsedJwt.user_id;

        // onTokenChanged can run if another tab updates the token
        // We only want to refresh the queries if there's a meaningful change to the token (the user changed)
        if (this.userId === tokenUserId) {
          return;
        }

        this.userId = tokenUserId;
      }

      this.queryClient.invalidateQueries({ queryKey: [COLLECTION_PREFIX] });
    });
  }

  private buildEntityKey(type: EntityType, id: unknown): CacheKey {
    return [ENTITY_PREFIX, type, id];
  }

  private buildCollectionKey(type: EntityType, id?: CollectionId): CacheKey {
    return [COLLECTION_PREFIX, type, id].filter(notNull);
  }

  private isAuditAfterDate(auditOperationMetadata: AuditOperationMetadata | undefined, b: number): boolean {
    return auditOperationMetadata != null && auditOperationMetadata.date > b;
  }

  private isEntityDeleted<T extends keyof EntityTypeMapping>(entity: EntityTypeMapping[T]): boolean {
    return entity.status === BaseEntityStatus.DELETED;
  }

  private cacheEntity<T extends keyof EntityTypeMapping>(type: T, entity: EntityTypeMapping[T]): void {
    const ids = 'refIds' in entity ? entity.refIds : [entity.id];

    for (const id of ids) {
      const key = this.buildEntityKey(type, id);
      this.queryClient.setQueryData(key, this.isEntityDeleted(entity) ? null : entity);
    }
  }

  private async cacheEntities(entityResponse: EntityResponseMap): Promise<void> {
    const cache = this.queryClient.getQueryCache();
    const allCollectionQueries = cache.findAll({ queryKey: [COLLECTION_PREFIX] });
    const typeEarliestRunTimeMapping = new Map<keyof EntityTypeMapping, number>();

    // Find the earliest time that each collection type ran
    for (const collectionQuery of allCollectionQueries) {
      const collectionType = collectionQuery.queryKey[1] as keyof EntityTypeMapping;
      const earliestRun = typeEarliestRunTimeMapping.get(collectionType);

      if (
        collectionQuery.state.dataUpdatedAt > 0 &&
        (earliestRun == null || earliestRun > collectionQuery.state.dataUpdatedAt)
      ) {
        typeEarliestRunTimeMapping.set(collectionType, collectionQuery.state.dataUpdatedAt);
      }
    }

    const typesToInvalidate = new Set<EntityType>();
    const typesToUpdate = new Map<EntityType, Set<string>>();

    for (const [typeString, entities] of Object.entries(entityResponse)) {
      const type = typeString as EntityType;
      const earliestCollectionRun = typeEarliestRunTimeMapping.get(type);

      for (const entity of entities) {
        // Check if the entity has been created/updated/deleted AFTER the earliest run
        // of a collection that has the same type
        if (
          earliestCollectionRun != null &&
          (this.isAuditAfterDate(entity.audit.create, earliestCollectionRun) ||
            this.isAuditAfterDate(entity.audit.update, earliestCollectionRun) ||
            this.isAuditAfterDate(entity.audit.delete, earliestCollectionRun))
        ) {
          // Only invalidate the queries at the end. This will avoid trying to invalidate the same query twice
          // and will also improve the performance.
          if (this.isEntityDeleted(entity)) {
            // If the entity has been deleted, we don't need to invalidate the collection.
            const idsToUpdate = typesToUpdate.get(type) ?? new Set();
            if (idsToUpdate.size === 0) {
              typesToUpdate.set(type, idsToUpdate);
            }
            idsToUpdate.add(entity.id);
          } else {
            typesToInvalidate.add(type);
          }
        }

        this.cacheEntity(type, entity);
      }
    }

    // The queries remain active for `cacheTime` (5 minutes by default) after the component
    // unmounts. We don't need to refetch these queries, only remove them from the cache.
    // This will avoid prefetching unused data.
    const queryHashesToPurge = new Set<string>();

    // Invalidate the collection queries that are not fetching
    const keysToInvalidate = Array.from(typesToInvalidate).map((type) => this.buildCollectionKey(type));
    if (keysToInvalidate.length > 0) {
      await this.queryClient.invalidateQueries({
        type: 'all',
        predicate: (query) => {
          if (query.state.fetchStatus === 'fetching') {
            return false;
          }

          const shouldInvalidate = keysToInvalidate.some((queryKey) => matchQuery({ queryKey }, query));
          if (!shouldInvalidate) {
            return false;
          }

          if (query.isActive() == false) {
            queryHashesToPurge.add(query.queryHash);
            return false;
          }

          return true;
        },
      });
    }

    // Purge inactive queries that are outdated
    if (queryHashesToPurge.size > 0) {
      this.queryClient.removeQueries({
        predicate: (query) => queryHashesToPurge.has(query.queryHash),
      });
    }

    // Remove deleted entities from the collections
    if (typesToUpdate.size > 0) {
      const keysToUpdate = Array.from(typesToUpdate.keys())
        // If the collection will be invalid anyway, we don't need to update the cache.
        .filter((type) => typesToInvalidate.has(type) === false)
        .map((type) => this.buildCollectionKey(type));
      const queryEntries = this.queryClient.getQueriesData<string[]>({
        predicate: (query) => {
          return keysToUpdate.some((queryKey) => matchQuery({ queryKey }, query));
        },
      });

      for (const [key, itemIds] of queryEntries) {
        if (!itemIds) {
          continue;
        }

        const type = key[1] as EntityType;
        const idsToRemove = typesToUpdate.get(type) ?? new Set();
        this.queryClient.setQueryData(
          key,
          itemIds.filter((id) => idsToRemove.has(id) === false),
        );
      }
    }
  }

  private cacheCollections(collections: Array<CachedCollection>): void {
    for (const collection of collections) {
      const key = this.buildCollectionKey(collection.type, collection.id);
      this.queryClient.setQueryData(key, collection.itemIds);
    }
  }

  async cacheResponse(entityResponse: EntityResponse): Promise<void> {
    // TODO - We can remove this after refactoring all endpoints to the new interface
    if ('entities' in entityResponse) {
      await this.cacheEntities(entityResponse.entities);
      if (entityResponse.collections) {
        this.cacheCollections(entityResponse.collections);
      }
      return;
    }

    await this.cacheEntities(entityResponse);
  }

  buildEntityQuery<T extends keyof EntityTypeMapping>(
    type: T,
    id: string,
    fn: QueryFn<string>,
    compareFn: CompareFn<T> = (id, entity) => id === entity.id,
  ): Query<EntityTypeMapping[T] | null> {
    return {
      queryKey: this.buildEntityKey(type, id),
      queryFn: async (scope) => {
        const entityResponse = await fn(id, scope);
        // TODO - We can remove this after refactoring all endpoints to the new interface
        const entities = 'entities' in entityResponse ? entityResponse.entities : entityResponse;

        await this.cacheResponse(entityResponse);

        return entities[type]?.find((entity) => compareFn(id, entity) && !this.isEntityDeleted(entity)) ?? null;
      },
    };
  }

  buildCollectionQuery<T extends keyof EntityTypeMapping>(
    type: T,
    params: CollectionId,
    fn: QueryFn<CollectionId>,
  ): Query<string[]> {
    return {
      queryKey: this.buildCollectionKey(type, params),
      queryFn: async (scope) => {
        const entityResponse = await fn(params, scope);
        // TODO - We can remove this after refactoring all endpoints to the new interface
        const entities = 'entities' in entityResponse ? entityResponse.entities : entityResponse;

        const specificCollectionData = entities[type];
        if (specificCollectionData == null) {
          throw new Error(`No data returned for collection: ${type}`);
        }

        await this.cacheResponse(entityResponse);

        return specificCollectionData.filter((entity) => !this.isEntityDeleted(entity)).map((entity) => entity.id);
      },
      cacheTime: 1000 * 60 * 5, // 5 minutes
    };
  }

  getEntity<T extends keyof EntityTypeMapping>(type: T, id: string): CachedValue<EntityTypeMapping[T]> | null {
    const queryKey = this.buildEntityKey(type, id);
    const value = this.queryClient.getQueryCache().find<EntityTypeMapping[T]>({ queryKey })?.state.data ?? null;
    if (value === null) {
      return null;
    }
    return { value };
  }

  getCollection(type: EntityType, params: CollectionId): CachedValue<string[]> | null {
    const queryKey = this.buildCollectionKey(type, params);
    const value = this.queryClient.getQueryCache().find<string[]>({ queryKey })?.state.data ?? null;
    if (value === null) {
      return null;
    }
    return { value };
  }

  setCachedValue<T = unknown>(key: QueryKey, value: T): void {
    this.queryClient.setQueryData(key, value);
  }

  setEntity<T extends keyof EntityTypeMapping>(type: T, id: string, value: EntityTypeMapping[T]): void {
    this.setCachedValue(this.buildEntityKey(type, id), value);
  }

  setCollection(type: EntityType, params: CollectionId, ids: string[]): void {
    this.setCachedValue(this.buildCollectionKey(type, params), ids);
  }

  async invalidateEntity(type: EntityType, id: string): Promise<void> {
    await this.queryClient.invalidateQueries({
      queryKey: this.buildEntityKey(type, id),
    });
  }

  async invalidateCollection(type: EntityType, params: CollectionId): Promise<void> {
    await this.queryClient.invalidateQueries({
      queryKey: this.buildCollectionKey(type, params),
    });
  }

  // ************ MUTATIONS ************ \\

  private runOptimisticMutations(
    mutations: EntityMutation<BaseMutation<string, unknown>>[],
  ): OptimisticMutationResult[] {
    const rollbackResults: OptimisticMutationResult[] = [];

    for (const entityMutation of mutations) {
      const mutationManager = this.optimisticMutationManagers[entityMutation.type];
      if (!mutationManager) {
        continue;
      }

      const cachedData = this.getEntity(entityMutation.type, entityMutation.id)?.value ?? null;
      if (!cachedData) {
        logger.warn('MUTATION.MISSING_CACHED_DATA', { entityMutation });
        continue;
      }

      for (const mutation of entityMutation.mutations) {
        const mutationResults = mutationManager.mutate(mutation, cachedData as never);

        for (const mutationResult of mutationResults) {
          switch (mutationResult.mutationType) {
            case 'entity': {
              // Perform entity mutation
              const previousData = this.getEntity(mutationResult.entity.type, mutationResult.entity.id)?.value ?? null;
              rollbackResults.push({
                mutationType: 'entity',
                entity: mutationResult.entity,
                data: previousData,
              });
              const key = this.buildEntityKey(mutationResult.entity.type, mutationResult.entity.id);
              this.queryClient.setQueryData(key, mutationResult.data);
              break;
            }
            case 'collection': {
              // Perform entity collection
              const previousData = this.getCollection(mutationResult.type, mutationResult.params)?.value ?? null;
              rollbackResults.push({
                mutationType: 'collection',
                type: mutationResult.type,
                params: mutationResult.params,
                ids: previousData,
              });
              const key = this.buildCollectionKey(mutationResult.type, mutationResult.params);
              this.queryClient.setQueryData(key, mutationResult.ids);
              break;
            }
            default: {
              throw new Error(`Mutation type not implemented ${JSON.stringify(mutationResult, null, 2)}`);
            }
          }
        }
      }
    }

    return rollbackResults;
  }

  private rollbackOptimisticMutations(rollbackResults: OptimisticMutationResult[]): void {
    for (const mutationResult of rollbackResults) {
      switch (mutationResult.mutationType) {
        case 'entity': {
          // Rollback entity mutation
          const key = this.buildEntityKey(mutationResult.entity.type, mutationResult.entity.id);
          this.queryClient.setQueryData(key, mutationResult.data);
          break;
        }
        case 'collection': {
          // Rollback entity collection
          const key = this.buildCollectionKey(mutationResult.type, mutationResult.params);
          this.queryClient.setQueryData(key, mutationResult.ids);
          break;
        }
        default: {
          throw new Error(`Mutation type not implemented ${JSON.stringify(mutationResult, null, 2)}`);
        }
      }
    }
  }

  async mutate(mutations: EntityMutation<BaseMutation<string, unknown>>[]): Promise<void> {
    const optimisticResults = this.runOptimisticMutations(mutations);

    const mutationsByEntity = new Map<EntityType, Map<string, BaseMutation<string, unknown>[]>>();
    for (const mutation of mutations) {
      let entityTypeMutations = mutationsByEntity.get(mutation.type);
      if (entityTypeMutations == null) {
        entityTypeMutations = new Map();
        mutationsByEntity.set(mutation.type, entityTypeMutations);
      }

      let entityMutations = entityTypeMutations.get(mutation.id);
      if (entityMutations == null) {
        entityMutations = [];
        entityTypeMutations.set(mutation.id, entityMutations);
      }

      entityMutations.push(...mutation.mutations);
    }

    const groupedMutations: EntityMutation<BaseMutation<string, unknown>>[] = [];
    for (const [entityType, entityTypeMutations] of mutationsByEntity.entries()) {
      for (const [entityId, entityMutations] of entityTypeMutations.entries()) {
        groupedMutations.push({
          id: entityId,
          type: entityType,
          mutations: entityMutations,
        });
      }
    }

    try {
      const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
      const { data } = await api.post<EntityResponse>('/mutate', groupedMutations, {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
      });
      await this.cacheResponse(data);
    } catch (error) {
      this.rollbackOptimisticMutations(optimisticResults);
      throw error;
    }
  }

  observeEntity<T extends keyof EntityTypeMapping>(config: ObserveEntityConfig<T>): void {
    const { entityType, entityId, fetchCallback, stopCallback, interval = 15000, signal } = config;

    const entity = this.getEntity(entityType, entityId)?.value;
    if (entity != null && stopCallback(entity) == true) {
      return;
    }

    logger.debug('OBSERVE_ENTITY', { entityType, entityId, interval });

    const abortListener = () => {
      logger.debug('OBSERVE_ENTITY.ABORTED.', { entityType, entityId, interval });
      clearInterval(intervalId);
      signal.removeEventListener('abort', abortListener);
    };

    signal.addEventListener('abort', abortListener);

    const intervalId = setInterval(async () => {
      logger.debug('OBSERVE_ENTITY.POLLING.', { entityType, entityId, interval });
      const response = await fetchCallback({ entityType, entityId, signal });
      if (signal.aborted) {
        return;
      }
      await this.cacheResponse(response);
      if (signal.aborted) {
        return;
      }

      const entity = this.getEntity(entityType, entityId)?.value;
      if (entity != null && stopCallback(entity) == true) {
        logger.debug('OBSERVE_ENTITY.DONE.', { entityType, entityId, interval });
        clearInterval(intervalId);
        signal.removeEventListener('abort', abortListener);
        return;
      }
    }, interval);
  }
}
