import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ACL_VIEW_ROLES } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';

import { getProjectPromptsPath, getSigninPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles, usePrimaryAclGroup } from '@/services/api/acl';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePollQuery } from '@/services/api/poll';

import { PollExpiredPage } from '../components/PollExpiredPage/PollExpiredPage';

export interface PollExpiredPageContainerProps {
  pollId: string;
  finished: boolean;
}

export function PollExpiredPageContainer({ pollId, finished }: PollExpiredPageContainerProps) {
  const { redirectService } = useServices();

  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('inviteToken') ?? null;

  const navigate = useNavigate();
  const user = useUser();
  const pollQuery = usePollQuery(pollId);

  const projectAcl = usePrimaryAclGroup(pollQuery.data?.acl, EntityType.PROJECT);
  const userProjectRoles = useCurrentUserAclRoles(projectAcl ? [projectAcl.id] : null);
  const canViewProject = hasRole(ACL_VIEW_ROLES, userProjectRoles ?? []);

  const handleRedirect = useCallback(async () => {
    if (pollQuery.data == null || userProjectRoles == null) {
      return;
    }

    if (finished) {
      if (user === null) {
        await redirectService.registerRedirect('signed-in', `/invite?inviteToken=${inviteToken}`);
        navigate(getSigninPath({ backupLocalData: true }));
        return;
      }

      if (canViewProject) {
        navigate(getProjectPromptsPath(pollQuery.data.projectId));
      } else {
        navigate(`/invite?inviteToken=${inviteToken}`);
      }

      return;
    }

    navigate('/');
  }, [canViewProject, finished, inviteToken, navigate, pollQuery.data, redirectService, user, userProjectRoles]);

  return <PollExpiredPage finished={finished} onRedirect={handleRedirect} />;
}
