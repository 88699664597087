import { EntityResponse } from '@remento/types/entity';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';
import { UserService } from '../user/user.types';

import {
  CreatePollData,
  CreatePollVoteData,
  PollAnonymousVoteRepository,
  PollService,
  PollShareLink,
} from './poll.types';

export class DefaultPollService implements PollService {
  constructor(
    private anonymousVoteRepository: PollAnonymousVoteRepository,
    private authorizationService: AuthorizationService,
    private userService: UserService,
  ) {}

  async getProjectPolls(projectId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/projects/${projectId}/polls`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getPoll(pollId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/polls/${pollId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getPollShareLink(pollId: string, scope?: RequestScope): Promise<PollShareLink> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<PollShareLink>(`/polls/${pollId}/share-link`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async createPoll(data: CreatePollData): Promise<void> {
    const { projectId, ...body } = data;
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    await api.post(`/projects/${projectId}/polls`, body, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
  }

  async deletePoll(pollId: string): Promise<void> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    await api.delete(`/polls/${pollId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
  }

  async getPollVotes(pollId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/polls/${pollId}/poll-votes`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getPollVote(voteId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/poll-votes/${voteId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async createPollVote(data: CreatePollVoteData): Promise<void> {
    const { pollId, ...body } = data;
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    await api.post(`/polls/${pollId}/poll-votes`, body, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });

    // If there's no user signed in, save the vote to the anonymous repository.
    // Then we can assign the vote to a user if they sign in.
    const user = await this.userService.getUser();
    if (user == null) {
      this.anonymousVoteRepository.saveVote(data.pollId, data.id);
    }
  }

  async sendEmailInvite(pollId: string, email: string, scope?: RequestScope | undefined): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<EntityResponse>(
      `/polls/${pollId}/invite`,
      { email },
      {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
        signal: scope?.signal,
      },
    );
    return data;
  }
}
