import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    text-transform: uppercase;
  }
`;

// Size variants

export const smallSizeVariant = css`
  height: 44px;
  width: 44px;
`;

export const largeSizeVariant = css`
  height: 112px;
  width: 112px;
`;

// Background variants

export const blendBackgroundVariant = css`
  background-color: var(--inverse-on-surface-secondary);
`;

export const primaryBackgroundVariant = css`
  background-color: var(--inverse-primary);
`;

export const darkBackgroundVariant = css`
  background-color: var(--color-blend-70);
`;

// Shape variants

export const roundedShapeVariant = css`
  border-radius: var(--radius-regular);
`;

export const circleShapeVariant = css`
  border-radius: var(--radius-full);
`;
