import { useEffect, useMemo, useState } from 'react';

export function useMatchMedia(query: string): boolean {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [matches, setMatches] = useState(mediaQuery.matches);

  useEffect(() => {
    const listener = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQuery.addEventListener('change', listener);
    return () => mediaQuery.removeEventListener('change', listener);
  }, [mediaQuery]);

  return matches;
}
