import { useEffect, useMemo } from 'react';

import { toast } from '@/components/RMToast/RMToast';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { PaywallDialog } from '../components/PaywallDialog';
import { useIsBillingOwner, useReactivate } from '../paywall.hooks';
import { closePaywall, usePaywallState } from '../paywall.state';

export function PaywallContainer() {
  // Stores
  const { isPaywallVisible, projectId } = usePaywallState();

  // Queries
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const projectQuery = useProjectQuery(projectId);
  const handleReactivate = useReactivate(projectId);
  const isBillingOwner = useIsBillingOwner(projectId);

  // States
  const isStoryteller = useMemo<boolean>(
    () => personQuery.data?.refIds.includes(projectQuery.data?.notifications.recipientPersonIds[0] ?? '') ?? false,
    [personQuery.data?.refIds, projectQuery.data?.notifications.recipientPersonIds],
  );
  const isCollaboratorOnly = isBillingOwner == false && isStoryteller == false;

  // Show the toast to collaborators
  useEffect(() => {
    if (isPaywallVisible == false || isCollaboratorOnly == false) {
      return;
    }

    toast('This feature is available for projects with active subscription.', 'root-toast', 'default', {
      toastId: 'paywall-toast',
      onClose: () => {
        closePaywall();
      },
    });
  }, [isCollaboratorOnly, isPaywallVisible]);

  if (isPaywallVisible == false || isCollaboratorOnly) {
    return null;
  }

  return (
    <PaywallDialog
      isBillingOwner={isBillingOwner ?? false}
      onReactivate={() => {
        closePaywall();
        handleReactivate();
      }}
      onClose={closePaywall}
    />
  );
}
