import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const TooltipContent = styled(motion.div)`
  position: relative;
  padding: var(--spacing-2xs) var(--spacing-xs);
  border-radius: var(--radius-regular);
  background-color: var(--informational-container);
  max-width: 245px;
  box-shadow: var(--elevation-bottom-4);
  border: 1px solid var(--inverse-border-hairline);
`;
