import * as RadixSelect from '@radix-ui/react-select';

import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMText } from '@/components/RMText/RMText';

import { SelectItem, SelectItemInfo } from './ProjectSelectItem.styles';

type StorytellerSelectItemProps = {
  id: string;
  name: string;
  coverUrl?: string | null;
  initials: string;
  subjectName: string | null;
  selected: boolean;
};

export function ProjectSelectItem({
  id,
  name,
  coverUrl: avatarUrl,
  initials,
  subjectName,
  selected,
}: StorytellerSelectItemProps) {
  return (
    <RadixSelect.Item key={id} value={id} asChild>
      <SelectItem data-selected={selected}>
        <RMAvatar placeholder={initials} background="primary" shape="rounded" src={avatarUrl} />

        <SelectItemInfo>
          <RMText type="sans" size="xxs" bold color="inverse-on-surface-secondary">
            {subjectName}
          </RMText>
          <RMText type="sans" size="xs" bold color="on-primary">
            {name}
          </RMText>
        </SelectItemInfo>
      </SelectItem>
    </RadixSelect.Item>
  );
}
