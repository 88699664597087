import { styled } from '@linaria/react';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-xl);
  padding: var(--spacing-2xl) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
  background-color: var(--surface);
  position: sticky;
  bottom: 0;

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
    padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md) var(--spacing-md);
  }
`;
