import { Link } from 'react-router-dom';
import { styled } from '@linaria/react';

export const NavbarItemRoot = styled(Link)`
  display: flex;
  padding: 0.875rem var(--spacing-md);
  align-items: center;
  gap: var(--spacing-xs);
  border-radius: var(--radius-regular);
  background-color: transparent;
  transition: 100ms;
  gap: var(--spacing-md);

  body[data-tablet='true'] & {
    height: 3rem;
    padding: 0;
    justify-content: center;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  svg,
  span {
    color: var(--inverse-on-surface-secondary);
  }

  :hover:not(&[data-disabled='true']) {
    background-color: var(--lighten-primary-hover);

    svg,
    span {
      color: var(--inverse-on-surface-primary);
    }
  }

  &[data-active='true'] {
    background-color: var(--lighten-primary-press) !important;

    svg,
    span {
      color: var(--inverse-on-surface-primary) !important;
    }
  }

  &[data-disabled='true'] {
    opacity: 0.5;
    cursor: default;
  }
`;
