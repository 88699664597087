import { BaseEntity, BaseMutation } from './base-entity';

export enum BookColor {
  FOREST = 'forest',
  NAVY = 'navy',
  LIGHT_BLUE = 'light-blue',
  BLUSH = 'blush',
  NATURAL = 'natural',
}

export const BookColorMap: Record<BookColor, string> = {
  [BookColor.FOREST]: 'hsla(180, 22%, 20%, 1)',
  [BookColor.NAVY]: 'hsla(215, 75%, 14%, 1)',
  [BookColor.LIGHT_BLUE]: 'hsla(212, 30%, 79%, 1)',
  [BookColor.BLUSH]: 'hsla(345, 19%, 83%, 1)',
  [BookColor.NATURAL]: 'hsla(35, 22%, 80%, 1)',
} as const;

export const BookColorNameMap: Record<BookColor, string> = {
  [BookColor.FOREST]: 'Forest green',
  [BookColor.NAVY]: 'Navy blue',
  [BookColor.LIGHT_BLUE]: 'Light blue',
  [BookColor.BLUSH]: 'Blush',
  [BookColor.NATURAL]: 'Natural',
} as const;

export interface BookShippingAddress {
  recipientName: string;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export enum BookTemplateStatus {
  ACTIVE = 'active',
}

export interface BookTemplate extends BaseEntity<BookTemplateStatus> {
  /**
   * The ID of the project to which the book belongs
   */
  projectId: string;

  /**
   * The title of the book
   */
  title?: string;

  /**
   * The subtitle of the book
   */
  subtitle?: string;

  /**
   * The color of the book
   */
  color?: BookColor;

  /**
   * The ID of the asset used for the cover of the book
   */
  coverAssetId?: string | null;

  /**
   * The IDs of the stories in the book
   */
  storyIds: Array<string>;

  /**
   * The ID of the asset for the PDF of the book
   */
  pdfAssetId?: string | null;

  /**
   * Whether the QR Codes should be rendered in the printed book.
   */
  hideQrCode?: boolean;

  /**
   * A list of ACL groups that grant permissions on the book.
   */
  acl: string[];
}

// MUTATIONS

export enum BookTemplateMutationType {
  SET_BOOK_TITLE = 'set-book-title',
  SET_BOOK_SUBTITLE = 'set-book-subtitle',
  SET_BOOK_COLOR = 'set-book-color',
  SET_BOOK_COVER_PHOTO = 'set-book-cover-photo',
  SET_BOOK_STORIES = 'set-book-stories',
  SET_BOOK_PDF = 'set-book-pdf',
  SET_BOOK_HIDE_QR_CODE = 'set-book-hide-qr-code',
}

export type SetBookTemplateTitleMutation = BaseMutation<BookTemplateMutationType.SET_BOOK_TITLE, string>;
export type SetBookTemplateSubTitleMutation = BaseMutation<BookTemplateMutationType.SET_BOOK_SUBTITLE, string>;
export type SetBookTemplateColorMutation = BaseMutation<BookTemplateMutationType.SET_BOOK_COLOR, BookColor>;
export type SetBookTemplateCoverPhotoMutation = BaseMutation<
  BookTemplateMutationType.SET_BOOK_COVER_PHOTO,
  string | null
>;
export type SetBookTemplateStoriesMutation = BaseMutation<BookTemplateMutationType.SET_BOOK_STORIES, string[]>;
export type SetBookTemplatePdfMutation = BaseMutation<BookTemplateMutationType.SET_BOOK_PDF, string | null>;
export type SetBookTemplateHideQrCodeMutation = BaseMutation<BookTemplateMutationType.SET_BOOK_HIDE_QR_CODE, boolean>;

export type BookTemplateMutation =
  | SetBookTemplateTitleMutation
  | SetBookTemplateSubTitleMutation
  | SetBookTemplateColorMutation
  | SetBookTemplateCoverPhotoMutation
  | SetBookTemplateStoriesMutation
  | SetBookTemplatePdfMutation
  | SetBookTemplateHideQrCodeMutation;
