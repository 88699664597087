import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AppLayout } from '@/layouts/AppLayout';
import { setNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { RementoPage } from '@/modules/routing';

import { RMLoader } from '../RMLoader/RMLoader';

import { Center, Container } from './PageLoader.styles';

interface PageLoaderProps {
  // shows navbar and header
  layout?: 'navbar' | null;
}

export function PageLoader({ layout = null }: PageLoaderProps) {
  const params = useParams();
  const navbarStore = useNavbarStore();

  useEffect(() => {
    if (layout !== 'navbar') {
      return;
    }

    if (params.projectId) {
      setNavbarProjectId(navbarStore, params.projectId);
    }
  }, [layout, navbarStore, params.projectId]);

  if (layout === 'navbar') {
    return (
      <RementoPage type="default">
        <AppLayout>
          <Container>
            <Center>
              <RMLoader />
            </Center>
          </Container>
        </AppLayout>
      </RementoPage>
    );
  }

  return (
    <Container>
      <Center>
        <RMLoader />
      </Center>
    </Container>
  );
}
