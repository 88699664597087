import { useLayoutEffect, useState } from 'react';

import { FullscreenStore, useFullscreenStore } from '@/modules/media-player/states';

export function isFullScreen(store: FullscreenStore) {
  return store.getState().forcedFullScreen || document.fullscreenElement != null;
}

export function observeIsFullscreen(store: FullscreenStore, callback: (isFullscreen: boolean) => void): () => void {
  let lastFiredValue: boolean | null = null;
  const listener = () => {
    const isFullscreen = isFullScreen(store);
    if (isFullscreen !== lastFiredValue) {
      callback(isFullscreen);
      lastFiredValue = isFullscreen;
    }
  };

  const removeStoreSubscription = store.subscribe(listener);
  document.addEventListener('fullscreenchange', listener);

  return () => {
    removeStoreSubscription();
    document.removeEventListener('fullscreenchange', listener);
  };
}

export function useIsFullscreen() {
  const store = useFullscreenStore();
  const [isFullscreen, setIsFullscreen] = useState(() => isFullScreen(store));

  useLayoutEffect(() => {
    return observeIsFullscreen(store, setIsFullscreen);
  }, [store]);

  return isFullscreen;
}
