import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Dialog = styled(motion.div)`
  position: absolute;
  inset: 0;
  outline: none;
  padding: 0;
  overflow: hidden;
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
  align-items: center;
  background: none;
  pointer-events: none;

  body[data-mobile='true'] & {
    position: fixed;
  }

  &[data-variant='fullscreen--mobile'] {
    align-items: flex-start;
  }

  &[data-variant='bottom-sheet--mobile'] {
    align-items: flex-end;
  }
`;

export const Backdrop = styled(motion.div)`
  position: absolute;
  inset: 0;
  background-color: var(--color-black-0-70);
  z-index: 999;

  body[data-mobile='true'] & {
    position: fixed;
  }
`;
