import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

import { RMWordmark } from '../RMWordmark/RMWordmark';

export const Header = styled.div`
  padding: 1.75rem var(--spacing-3xl);
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
`;

export const Wordmark = styled(RMWordmark)`
  height: 1.5rem;
`;

export const Image = styled.img`
  margin-bottom: var(--spacing-lg);
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-bottom: var(--spacing-lg);
`;

export const ContentWrapper = styled.div`
  height: calc(100% - 12rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  body[data-mobile='true'] & {
    height: calc(100% - 6rem);
  }
`;

export const Content = styled.div`
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-lg);
`;
