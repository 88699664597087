import { faHome } from '@fortawesome/pro-solid-svg-icons';

import BookIcon from '@/assets/icons/book-icon.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMError } from '@/components/RMError';

export interface StoryNotFoundPageProps {
  onGoHome: () => void;
}

export function StoryNotFoundPage({ onGoHome }: StoryNotFoundPageProps) {
  return (
    <RMError.Root
      icon={BookIcon}
      iconSize="12.25rem"
      title="This story no longer exists"
      Action={
        <RMButton leftIcon={faHome} onClick={onGoHome}>
          Return home
        </RMButton>
      }
      Message={<RMError.Text>This story has been removed.</RMError.Text>}
    />
  );
}
