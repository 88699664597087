import { Story, StoryDataType } from '@remento/types/story';

export function getStoryTitle(story?: Story | null) {
  return story?.title == null || story.title.type == StoryDataType.TRANSCRIPT_TOO_SHORT ? null : story.title.value;
}

export function getStorySummary(story?: Story | null) {
  return story?.summary == null || story.summary.type == StoryDataType.TRANSCRIPT_TOO_SHORT
    ? null
    : story.summary.value;
}

export function getStoryDownloadName(story?: Story | null, isHighlight = false) {
  let storyTitle = getStoryTitle(story);
  if (storyTitle == null || storyTitle.trim().length == 0) {
    storyTitle = 'story';
  }
  if (isHighlight) {
    storyTitle += '-highlight-reel';
  }

  // Remove accentuation
  const noAccents = storyTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  // Remove special characters
  const cleaned = noAccents.replace(/[^\w\s-]/g, '');
  // Replace spaces with hyphens and convert to lowercase
  const downloadName = cleaned.replace(/\s+/g, '-').toLowerCase();

  return downloadName;
}
