import axios from 'axios';

import { RementoIdChannelType, RementoIdService } from './remento-id.types';

export class HttpRementoIdService implements RementoIdService {
  constructor(private readonly serverUrl: string) {}

  async getId(channelType: RementoIdChannelType, channelValue: string): Promise<string> {
    const url = new URL(`/id/${channelType}/${channelValue}`, this.serverUrl);
    const { data } = await axios<{ id: string }>(url.toString());
    return data.id;
  }
}
