import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { Poll } from '@remento/types/poll';
import { PollVote, PollVoteMutationType } from '@remento/types/poll-vote';
import { notNull } from '@remento/utils/array/notNull';
import { QueryClient } from '@tanstack/react-query';

import { logger } from '@/logger';

import { RequestScope } from '../api.types';
import { EntityCacheManagerService } from '../cache';
import { PersonCacheService } from '../person';
import { UserService } from '../user/user.types';

import {
  AnonymousVote,
  CreatePollData,
  CreatePollVoteData,
  PollAnonymousVoteRepository,
  PollCacheService,
  PollService,
} from './poll.types';

export class DefaultPollCacheService implements PollCacheService {
  constructor(
    private remoteService: PollService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
    private anonymousVoteRepository: PollAnonymousVoteRepository,
    private userService: UserService,
    private personCacheService: PersonCacheService,
  ) {}

  getProjectPolls(projectId: string, scope?: RequestScope): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.POLL, { projectId }, () =>
        this.remoteService.getProjectPolls(projectId, scope),
      ),
    );
  }

  getPoll(pollId: string, scope?: RequestScope): Promise<Poll | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.POLL, pollId, () =>
        this.remoteService.getPoll(pollId, scope),
      ),
    );
  }

  getPollVotes(pollId: string, scope?: RequestScope): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.POLL_VOTE, { pollId }, () =>
        this.remoteService.getPollVotes(pollId, scope),
      ),
    );
  }

  getPollVote(voteId: string, scope?: RequestScope): Promise<PollVote | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.POLL_VOTE, voteId, () =>
        this.remoteService.getPollVote(voteId, scope),
      ),
    );
  }

  async hasVoted(pollId: string, scope?: RequestScope): Promise<boolean> {
    const user = await this.userService.getUser();
    if (user == null) {
      return this.anonymousVoteRepository.hasVoted(pollId);
    }

    const person = await this.personCacheService.getPerson(user.personId);
    if (person == null) {
      throw new NotFoundError('person-not-found', {
        origin: 'entity',
        entityType: EntityType.PERSON,
        entityId: user.personId,
      });
    }

    const pollVotesIds = await this.getPollVotes(pollId, scope);
    const pollVotes = await Promise.all(pollVotesIds.map((id) => this.getPollVote(id, scope)));
    return pollVotes
      .filter(notNull)
      .some((pollVote) => pollVote.personId != null && person.refIds.includes(pollVote.personId));
  }

  async associateAnonymousPollVotes(): Promise<void> {
    const user = await this.userService.getUser();
    if (user == null) {
      return;
    }

    const anonymousVotes = this.anonymousVoteRepository.getVotes();
    if (anonymousVotes.length == 0) {
      return;
    }

    logger.info('ASSOCIATE_ANONYMOUS_POLL_VOTES', { votes: anonymousVotes });

    const pollVotes = await Promise.all(anonymousVotes.map((av) => this.getPollVote(av.voteId)));

    await this.entityCacheManagerService.mutate(
      pollVotes.filter(notNull).map((vote) => ({
        type: EntityType.POLL_VOTE,
        id: vote.id,
        mutations: [
          {
            type: PollVoteMutationType.SET_PERSON,
            value: user.personId,
            vclock: vote.vclock,
            version: vote.version,
          },
        ],
      })),
    );

    this.anonymousVoteRepository.clear();

    logger.info('ASSOCIATE_ANONYMOUS_POLL_VOTES.DONE', { votes: anonymousVotes });
  }

  async createPoll(data: CreatePollData): Promise<void> {
    await this.remoteService.createPoll(data);

    // Invalidate collection.
    await this.entityCacheManagerService.invalidateCollection(EntityType.POLL, {
      projectId: data.projectId,
    });
    await this.entityCacheManagerService.invalidateCollection(EntityType.ACL_GROUP_MEMBER);
  }

  async sendEmailInvite(pollId: string, email: string): Promise<void> {
    const res = await this.remoteService.sendEmailInvite(pollId, email);
    await this.entityCacheManagerService.cacheResponse(res);
  }

  async deletePoll(pollId: string): Promise<void> {
    const poll = await this.getPoll(pollId);
    await this.remoteService.deletePoll(pollId);

    if (poll != null) {
      await this.entityCacheManagerService.invalidateCollection(EntityType.POLL, {
        projectId: poll.projectId,
      });
    }
  }

  async createPollVote(data: CreatePollVoteData): Promise<void> {
    await this.remoteService.createPollVote(data);

    // Invalidate collection.
    await this.entityCacheManagerService.invalidateCollection(EntityType.POLL_VOTE, {
      pollId: data.pollId,
    });
  }

  getAnonymousVote(pollId: string | null): AnonymousVote | null {
    return this.anonymousVoteRepository.getAnonymousVote(pollId);
  }
}
