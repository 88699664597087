import { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { isMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMDialogProvider } from './RMDialog.context';
import { Backdrop, Dialog } from './RMDialogRoot.styles';

type RMDialogContentProps = PropsWithChildren<{
  open: boolean;
  onClose?: () => void;
  variant?: 'full-screen' | 'regular' | 'large' | 'extra-large';
  animation?: 'ltr' | 'rtl' | 'default';
}>;

function RMDialog({ open, onClose, children, variant = 'regular', animation = 'default' }: RMDialogContentProps) {
  const mobile = isMobileViewport();
  const screenVariant = mobile ? `${variant}-mobile` : variant;

  useEffect(() => {
    if (!open) {
      return;
    }

    const keyListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose?.();
      }
    };

    document.addEventListener('keydown', keyListener);
    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, [onClose, open]);

  if (!open) {
    return null;
  }

  return (
    <RMDialogProvider value={{ variant, animation }}>
      <Backdrop onClick={onClose}></Backdrop>
      <Dialog data-variant={screenVariant}>{children}</Dialog>
    </RMDialogProvider>
  );
}

function RMDialogPortal(props: RMDialogContentProps) {
  const portalRoot = document.getElementById('dialog');
  if (!portalRoot) {
    console.error('Missing portal root for dialog');
    return null;
  }

  return createPortal(<RMDialog {...props} />, portalRoot);
}

export const RMDialogRoot = RMDialogPortal;
