import { styled } from '@linaria/react';

import { RMText } from '../RMText/RMText';

export const Header = styled.div`
  top: 0;
  position: sticky;
  background-color: var(--surface);
  z-index: 1;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-xs) var(--spacing-lg) var(--spacing-xs);

  // sticky elements take container padding in consideration
  // this 'after' hides the overflowing content on scrolling
  &:after {
    content: '';
    height: var(--spacing-xl);
    top: calc(var(--spacing-xl) * -1);
    left: 0;
    width: 100%;
    position: absolute;
    background-color: var(--surface);
  }

  body[data-mobile='true'] & {
    padding: var(--spacing-sm) var(--spacing-2xs) var(--spacing-lg) var(--spacing-2xs);

    &:after {
      height: var(--spacing-md);
      top: calc(var(--spacing-md) * -1);
    }
  }

  > *:not(span) {
    align-self: flex-start;
  }
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: var(--justify-content);
  min-height: 3rem;
`;

export const HiddenFiller = styled.div`
  opacity: 0;
  pointer-events: none;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-xl);
  margin: calc(var(--spacing-xl) * -1) calc(var(--spacing-xl) * -1) 0 calc(var(--spacing-xl) * -1);
  margin-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--border-hairline);
  top: calc(var(--spacing-xl) * -1);
  position: sticky;
  background-color: var(--surface);
  z-index: 1;

  // top value set where the sticky component will be attached,
  // but it also takes content padding in consideration, then we need to use negative padding value
  top: calc(var(--spacing-xl) * -1);
  position: sticky;
  background-color: var(--surface);
  z-index: 1;

  button:first-child {
    padding-left: 0;
  }
`;

export const Title = styled(RMText)`
  &[data-padding-left='true'] {
    padding-left: var(--spacing-md);
  }

  body[data-mobile='true'] &[data-padding-left='true'] {
    padding-left: var(--spacing-sm);
  }
`;

export const Message = styled.div`
  padding: 0 var(--spacing-md);

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-sm);
  }
`;
