import { z } from 'zod';

export type Primitive = string | number | boolean | undefined | null;

export enum AuditOperationMetadataType {
  USER = 'user',
  TOKEN = 'token',
}

export interface UserAuditOperationMetadata {
  userId: string;
  date: number;
  type: AuditOperationMetadataType.USER;
}

export interface TokenAuditOperationMetadata {
  tokens: string[];
  date: number;
  type: AuditOperationMetadataType.TOKEN;
}

export type AuditOperationMetadata = UserAuditOperationMetadata | TokenAuditOperationMetadata;

export interface AuditMetadata {
  create: AuditOperationMetadata;
  update?: AuditOperationMetadata;
  delete?: AuditOperationMetadata;
}

export const AuditOperationMetadataSchema = z.object({
  userId: z.string(),
  date: z.number().int().positive(),
});

export const AuditMetadataSchema = z.object({
  create: AuditOperationMetadataSchema,
  update: AuditOperationMetadataSchema.optional(),
  delete: AuditOperationMetadataSchema.optional(),
});

export enum BaseEntityStatus {
  DELETED = 'deleted',
}

export interface BaseEntity<S = unknown> {
  id: string;
  refIds: string[];
  status: S | BaseEntityStatus;
  version: number;
  vclock: number;
  audit: AuditMetadata;
}

export interface BaseMutation<T = Primitive, U = unknown> {
  type: T;
  vclock: number;
  version: number;
  value: U;
}

export interface DeleteMutation extends BaseMutation<'delete', undefined> {
  type: 'delete';
}

export const DeleteMutationSchema = z.object({
  type: z.literal('delete'),
  vclock: z.number().int(),
  version: z.number(),
});
