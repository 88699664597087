/**
 * Return an array of parent path from the provided path. The order of the parent paths is not
 * guaranteed
 *
 * For example, getParentPaths('a.b.c') would return ['a', 'a.b']
 * @param path
 */
export function getParentPaths(path: string): Array<string> {
  const pathParts = path.split('.');
  pathParts.pop();
  return pathParts.map((_, i) => {
    return pathParts.slice(0, i + 1).join('.');
  });
}
