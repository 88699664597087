import { ReactNode } from 'react';
import { faClose, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';

import LogoIcon from '@/assets/logo.svg';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { useIsMobileViewport, useIsTabletViewport } from '@/hooks/useIsMobileViewport';

import { NavbarStatus } from '../../states';

import {
  CloseIcon,
  Logo,
  NavbarHeader,
  NavbarItems,
  NewProjectButton,
  StyledNavbarRoot,
  Wordmark,
} from './NavbarRoot.styles';

export type NavbarPage = 'stories' | 'upcoming-prompts' | 'book-settings' | 'my-account';

interface NavbarProps {
  status: NavbarStatus;
  Items: ReactNode;
  ProjectSelect: ReactNode;
  Account: ReactNode;
  onClose: () => void;
  onChangeStatus: (newStatus: NavbarStatus) => void;
  onAddProject: () => void;
}

export function NavbarRoot({
  status,
  Items,
  ProjectSelect,
  Account,
  onClose,
  onChangeStatus,
  onAddProject,
}: NavbarProps) {
  const isMobile = useIsMobileViewport();
  const isTablet = useIsTabletViewport();

  return (
    <AnimatePresence>
      {status !== 'closed' && (
        <StyledNavbarRoot
          initial={
            status === 'opening' || status === 'closing' ? { translateX: status === 'opening' ? '-100%' : '0%' } : false
          }
          animate={{
            translateX: status === 'closing' ? '-100%' : '0%',
            transition: { duration: 0.25, ease: 'easeOut' },
          }}
          onAnimationComplete={() => {
            if (status === 'opening') {
              onChangeStatus('open');
            } else {
              onChangeStatus('closed');
            }
          }}
        >
          {!isTablet && (
            <>
              <NavbarHeader data-mobile={isMobile}>
                <Logo src={LogoIcon} alt="Remento Logo" mobile={isMobile} />
                <Wordmark color="neutral" />
                {isMobile && (
                  <CloseIcon
                    icon={faClose}
                    tooltip={null}
                    color="white"
                    backgroundColor="transparent"
                    onClick={onClose}
                  />
                )}
              </NavbarHeader>
              <RMSpacer spacing="2xl" direction="column" />
            </>
          )}
          {ProjectSelect}
          <RMSpacer spacing={isMobile ? '2xl' : 'xl'} direction="column" />
          <NavbarItems data-mobile={isMobile}>
            {Items}
            {!isTablet && (
              <NewProjectButton
                leftIcon={faPlus}
                background="outlined"
                color="surface"
                fullWidth
                onClick={onAddProject}
              >
                New project
              </NewProjectButton>
            )}
          </NavbarItems>
          {Account}
        </StyledNavbarRoot>
      )}
    </AnimatePresence>
  );
}
