import { ReactionSentiment } from '@remento/types/reaction';

export type StoryType = 'no-silence' | 'original' | 'highlight';

export type StoryViewerSummaryAction = 'back' | 'close' | 'discard-no' | 'discard-yes' | 'save';

export type StoriesAction = 'download' | 're-record' | 'remove-story';

export type ReactionSource = 'email' | 'story view';

export type StoryPage = 'story' | 'story-standalone' | 'highlight-reel-standalone';

export type StoryShareTarget = 'share-sheet' | 'facebook' | 'twitter' | 'whatsapp' | 'download' | 'clipboard';

export const ReactionSentimentDescription: Record<ReactionSentiment, string> = {
  LOVE: 'love',
  FUNNY: 'laugh',
  SURPRISING: 'surprise',
  TOUCHING: 'holding back tears',
};

export interface StoryViewerAnalytics {
  // Story
  onStoryArrived(): void;
  onStoryWatched(duration: number, type: StoryType): void;
  onStoryShared(page: StoryPage, target: StoryShareTarget): void;
  onStoryMuted(type: 'mute-on' | 'mute-off'): void;
  onStoryLiked(): void;
  onStoryDownloaded(type: StoryType): void;
  onStorySummaryViewed(): void;
  onStorySummaryEdited(): void;
  onStorySummaryActionPerformed(cta: StoryViewerSummaryAction): void;

  // Stories
  onStoriesArrived(): void;
  onStoriesOpened(): void;
  onStoriesActionPerformed(action: StoriesAction): void;

  // Reactions
  onReactionArrived(reactionSource: ReactionSource): void;
  onReactionSent(page: StoryPage, emoji: ReactionSentiment | null, textCustomized: boolean): void;
}
