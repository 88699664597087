import {
  Id,
  toast as reactToast,
  ToastOptions as ToastifyToastOptions,
  ToastPosition,
  UpdateOptions,
} from 'react-toastify';

import { isMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMText } from '../RMText/RMText';

import { RMLoadingToast } from './RMLoadingToast';

export type ToastId = Id;
export type ToastVariant = 'default' | 'success' | 'error';
export type ToastContainerId = 'dialog-panel-toast' | 'root-toast' | 'full-screen-toast';
export interface ToastOptions
  extends Omit<ToastifyToastOptions, 'containerId' | 'type' | 'icon' | 'position' | 'closeButton'> {
  onLoadingCancel?: () => void;
}

export function toast(
  content: string | null | JSX.Element,
  containerId: ToastContainerId = 'root-toast',
  type?: ToastVariant,
  options?: ToastOptions,
) {
  let position: ToastPosition = 'bottom-center';
  if (containerId === 'full-screen-toast') {
    position = isMobileViewport() ? 'bottom-center' : 'top-center';
  }

  const text = (
    <RMText type="sans" size="xs" bold color="on-surface-primary">
      {content}
    </RMText>
  );

  return reactToast(
    options?.isLoading ? <RMLoadingToast onLoadingCancel={options?.onLoadingCancel}>{text}</RMLoadingToast> : text,
    {
      type,
      containerId,
      hideProgressBar: typeof options?.progress === 'undefined',
      autoClose: options?.isLoading ? false : 3000,
      closeButton: false,
      position,
      icon: false,
      ...options,
    },
  );
}

export function updateToast(toastId: Id, containerId: ToastContainerId, options: UpdateOptions) {
  reactToast.update(toastId, {
    ...options,
    containerId,
    render:
      typeof options.render === 'string' ? (
        <RMText type="sans" size="xs" bold color="on-surface-primary">
          {options.render}
        </RMText>
      ) : (
        options.render
      ),
  });
}

export function removeToast(toastId: Id) {
  reactToast.dismiss(toastId);
}
