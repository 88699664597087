import { RMDialogBody } from './RMDialogBody';
import { RMDialogContent } from './RMDialogContent';
import { RMDialogFooter } from './RMDialogFooter';
import { RMDialogHeader } from './RMDialogHeader';
import { RMDialogLoader } from './RMDialogLoader';
import { RMDialogRoot } from './RMDialogRoot';
import { RMDialogTransition } from './RMDialogTransition';

export const RMDialog = {
  Root: RMDialogRoot,
  Content: RMDialogContent,
  Header: RMDialogHeader,
  Body: RMDialogBody,
  Footer: RMDialogFooter,
  Transition: RMDialogTransition,
  Loader: RMDialogLoader,
};
