import { EntityResponse, EntityType } from '@remento/types/entity';
import { ConflictError, ConflictErrorType, NotFoundError } from '@remento/types/error';
import { PersonMutation, PersonMutationType } from '@remento/types/person';
import {
  SetUserEmailMutation,
  SetUserOnboardingHistoryMutation,
  SetUserPhoneMutation,
  SubscriptionRenewalType,
  User,
  UserMutation,
  UserMutationType,
  UserOnboardingActionType,
  UserPhone,
} from '@remento/types/user';
import {
  UserNotificationSettings,
  UserNotificationSettingsMutation,
  UserNotificationSettingsMutationPayload,
  UserNotificationSettingsMutationType,
} from '@remento/types/user-notification-settings';
import AsyncLock from 'async-lock';
import { isAxiosError } from 'axios';
import promiseRetry from 'promise-retry';

import { AnalyticsService, Events } from '@/services/analytics/analytics.types';
import { AuthService } from '@/services/api/auth/auth.types';
import { EntityCacheManagerService, EntityMutation } from '@/services/api/cache';
import { PersonCacheService } from '@/services/api/person';
import { captureException } from '@/utils/captureException';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';

import { UpdateUserData, UserChangeCallback, UserService } from './user.types';

export class UserServiceImpl implements UserService {
  private changeUserObservers: Set<UserChangeCallback> = new Set();
  // The user is undefined if the firebase callback hasn't fired yet. It is null if the user is signed-out.
  private currentUser: undefined | null | User = undefined;
  private authChangedLock = new AsyncLock();

  constructor(
    private authService: AuthService,
    private authorizationService: AuthorizationService,
    private entityCacheManagerService: EntityCacheManagerService,
    private personCacheService: PersonCacheService,
    private analyticsService: AnalyticsService,
  ) {}

  async initialize() {
    this.authService.onAuthChanged(async () => {
      await this.refreshUser();
    });

    await this.refreshUser();
  }

  async updateUserProfile(data: UpdateUserData): Promise<void> {
    if (this.currentUser == null) {
      throw new Error('There is no user logged in.');
    }

    const person = await this.personCacheService.getPerson(this.currentUser.personId);
    if (person == null) {
      throw new Error('No person entity for the signed in user was found.');
    }

    const mutations: EntityMutation<PersonMutation | UserMutation>[] = [
      {
        id: person.id,
        type: EntityType.PERSON,
        mutations: [
          {
            type: PersonMutationType.SET_NAME,
            value: data.name,
            vclock: person.vclock,
            version: person.version,
          },
        ],
      },
      ...this.createSetUserOnboardingHistoryMutation(this.currentUser, UserOnboardingActionType.PHONE_COLLECTED),
    ];

    if (data.termsAccepted) {
      mutations.push(
        ...this.createSetUserOnboardingHistoryMutation(this.currentUser, UserOnboardingActionType.TERMS_ACCEPTED),
      );
    }
    if (data.phone != null) {
      mutations.push(...this.createSetUserPhoneMutation(this.currentUser, data.phone));
    }

    await this.entityCacheManagerService.mutate(mutations);

    await this.refreshUser();
  }

  async getUser(): Promise<User | null> {
    return this.lockUser(async () => {
      if (this.currentUser === undefined) {
        throw new Error('There is no user logged in.');
      }

      return this.currentUser;
    });
  }

  async refreshUser() {
    await this.lockUser(async () => {
      const token = await this.authService.getAuthToken();
      if (token == null) {
        const previousUser = this.currentUser;
        this.currentUser = null;
        if (previousUser != this.currentUser) {
          this.triggerUserChangedListeners();
        }
        return;
      }

      try {
        const { data } = await api.get<EntityResponse>('/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        await this.entityCacheManagerService.cacheResponse(data);

        // TODO - We can remove this after refactoring all endpoints to the new interface
        const entities = 'entities' in data ? data.entities : data;
        const user = entities.user?.[0];
        if (user == null) {
          throw new Error('No user returned in user endpoint');
        }

        const person = await this.personCacheService.getPerson(user.personId);
        if (person == null) {
          throw new Error('No person entity for the signed in user was found.');
        }

        this.currentUser = user;

        try {
          await this.analyticsService.identify(user.ruid, {
            appBookUserId: user.id,
            email: user.communicationChannels.email,
            phone:
              user.communicationChannels.phone != null
                ? `+${user.communicationChannels.phone.countryCode}${user.communicationChannels.phone.number}`
                : null,
            name: person.name?.full,
            firstName: person.name?.first,
            lastName: person.name?.last,
          });
        } catch (error) {
          captureException(error, true);
        }

        this.triggerUserChangedListeners();
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response?.status === 404) {
            // If the user has been deleted, sign out the user
            await this.authService.signOut();
            return;
          }
        }
        throw error;
      }
    });
  }

  async updateOnboardingHistory(action: UserOnboardingActionType, done = true): Promise<void> {
    const user = await this.getUser();
    if (user == null) {
      throw new NotFoundError('user-not-found');
    }

    const mutations = this.createSetUserOnboardingHistoryMutation(user, action, done);
    await this.entityCacheManagerService.mutate(mutations);
    await this.refreshUser();
  }

  private triggerUserChangedListeners() {
    this.changeUserObservers.forEach((callback) => {
      // This should never happen
      if (this.currentUser === undefined) {
        throw new Error('User is undefined when triggering observers');
      }

      callback(this.currentUser);
    });
  }

  onUserChanged(callback: UserChangeCallback): () => void {
    this.changeUserObservers.add(callback);
    return () => this.changeUserObservers.delete(callback);
  }

  async deleteUser(): Promise<void> {
    await this.lockUser(async () => {
      if (this.currentUser == null) {
        throw new Error('There is no user logged in.');
      }

      const token = await this.authService.getAuthToken();
      await api.delete('/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });
  }

  async getUserByPersonId(personId: string, scope?: RequestScope): Promise<EntityResponse> {
    const token = await this.authService.getAuthToken();
    const { data } = await api.get<EntityResponse>(`/people/${personId}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: scope?.signal,
    });
    return data;
  }

  async getUserStorytellers(): Promise<EntityResponse> {
    const token = await this.authService.getAuthToken();
    const { data } = await api.get<EntityResponse>(`/user/storytellers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  }

  async updateSubscriptionPaymentMethod(): Promise<string> {
    const token = await this.authService.getAuthToken();
    const { data } = await api.post<{ link: string }>('/user/subscription/update-payment-method', null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.link;
  }

  async updateSubscriptionRenewalType(renewalType: SubscriptionRenewalType): Promise<void> {
    await promiseRetry(
      async (retry) => {
        try {
          const user = await this.getUser();
          if (user == null) {
            throw new NotFoundError('user-not-found');
          }
          const token = await this.authService.getAuthToken();
          const { data } = await api.post<EntityResponse>(
            '/user/subscription/update-renewal-type',
            {
              type: UserMutationType.SET_SUBSCRIPTION_RENEWAL_TYPE,
              value: renewalType,
              vclock: user.vclock,
              version: user.version,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          await this.entityCacheManagerService.cacheResponse(data);
          await this.refreshUser();
        } catch (error) {
          if (error instanceof ConflictError && error.data?.type === ConflictErrorType.VCLOCK_MISMATCH) {
            await this.refreshUser();
            return retry(error);
          }
          throw error;
        }
      },
      { retries: 2 },
    );
  }

  createSetNotificationSettingsMutation(
    settings: UserNotificationSettings,
    newSettingsPayload: UserNotificationSettingsMutationPayload,
  ): EntityMutation<UserNotificationSettingsMutation>[] {
    return [
      {
        type: EntityType.USER_NOTIFICATION_SETTINGS,
        id: settings.id,
        mutations: [
          {
            type: UserNotificationSettingsMutationType.SET_SETTINGS,
            value: newSettingsPayload,
            vclock: settings.vclock,
            version: settings.version,
          },
        ],
      },
    ];
  }

  async getNotificationSettingsByUserId(userId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/user/${userId}/notifications`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  private lockUser<T>(cb: () => T) {
    return this.authChangedLock.acquire('user', async () => {
      return cb();
    });
  }

  createSetUserEmailMutation(user: User, newEmail: string): EntityMutation<SetUserEmailMutation>[] {
    return [
      {
        type: EntityType.USER,
        id: user.id,
        mutations: [
          {
            type: UserMutationType.SET_EMAIL,
            value: newEmail,
            vclock: user.vclock,
            version: user.version,
          },
        ],
      },
    ];
  }

  createSetUserPhoneMutation(user: User, newPhone: UserPhone | null): EntityMutation<SetUserPhoneMutation>[] {
    return [
      {
        type: EntityType.USER,
        id: user.id,
        mutations: [
          {
            type: UserMutationType.SET_PHONE,
            value: newPhone,
            vclock: user.vclock,
            version: user.version,
          },
        ],
      },
    ];
  }

  createSetUserOnboardingHistoryMutation(
    user: User,
    action: UserOnboardingActionType,
    done = true,
  ): EntityMutation<SetUserOnboardingHistoryMutation>[] {
    if (done == true) {
      this.analyticsService.track(Events.UserOnboardingProgress, { step: action });
    }
    return [
      {
        type: EntityType.USER,
        id: user.id,
        mutations: [
          {
            type: UserMutationType.SET_ONBOARDING_HISTORY,
            value: done
              ? {
                  action,
                  done,
                  timestamp: Date.now(),
                }
              : {
                  action,
                  done,
                },
            vclock: user.vclock,
            version: user.version,
          },
        ],
      },
    ];
  }
}
