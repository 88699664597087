import { useCallback } from 'react';
import { create, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';

import { useQueryParam } from '@/hooks/useQueryParam';

interface FullscreenState {
  forcedFullScreen: boolean;
}

export type FullscreenStore = StoreApi<FullscreenState>;

export function useCreateFullscreenStore(): () => FullscreenStore {
  const [forcedFullScreen, setForcedFullScreen] = useQueryParam('forced-full-screen', 'boolean', false);

  return useCallback(() => {
    return create(
      persist<FullscreenState>(() => ({ forcedFullScreen: false }), {
        name: 'fullscreen-store',
        storage: {
          getItem: () => ({ state: { forcedFullScreen } }),
          setItem: (_, value) => {
            setForcedFullScreen(value.state.forcedFullScreen == false ? null : true);
          },
          removeItem: () => setForcedFullScreen(null),
        },
      }),
    );
  }, [forcedFullScreen, setForcedFullScreen]);
}

export function setForcedFullScreen(store: FullscreenStore, forcedFullScreen: boolean): void {
  store.setState({ forcedFullScreen });
}

export function isForcedFullScreen(store: FullscreenStore): boolean {
  return store.getState().forcedFullScreen;
}
