/* eslint-disable react-refresh/only-export-components */
import { createContext, PropsWithChildren, useContext } from 'react';

import { FullscreenStore } from './fullscreen.state';

const FullscreenStoreContext = createContext<FullscreenStore | null>(null);

export function FullscreenStoreProvider({ value, children }: PropsWithChildren<{ value: FullscreenStore }>) {
  return <FullscreenStoreContext.Provider value={value}>{children}</FullscreenStoreContext.Provider>;
}

export function useFullscreenStore(): FullscreenStore {
  const context = useContext(FullscreenStoreContext);
  if (!context) {
    throw new Error('useFullscreenStore must be used inside a FullscreenStoreProvider');
  }
  return context;
}
