import { EntityType } from '@remento/types/entity';
import { Reaction, ReactionSuggestion } from '@remento/types/reaction';
import { QueryClient } from '@tanstack/react-query';

import { EntityCacheManagerService } from '../cache/entity-cache-manager.types';

import { CreateReactionPayload, ReactionCacheService, ReactionService } from './reaction.types';

export class DefaultReactionCacheService implements ReactionCacheService {
  constructor(
    private remoteService: ReactionService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  getReaction(reactionId: string): Promise<Reaction | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.REACTION, reactionId, () =>
        this.remoteService.getReaction(reactionId),
      ),
    );
  }

  getReactions(recordingId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.REACTION, { recordingId }, () =>
        this.remoteService.getReactions(recordingId),
      ),
    );
  }

  getReactionSuggestion(suggestionId: string): Promise<ReactionSuggestion | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.REACTION_SUGGESTION, suggestionId, () =>
        this.remoteService.getReactionSuggestion(suggestionId),
      ),
    );
  }

  getReactionsSuggestions(recordingId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.REACTION_SUGGESTION, { recordingId }, () =>
        this.remoteService.getReactionsSuggestions(recordingId),
      ),
    );
  }

  async createReaction(payload: CreateReactionPayload): Promise<void> {
    await this.remoteService.createReaction(payload);

    // Invalidate the reactions collection.
    await this.entityCacheManagerService.invalidateCollection(EntityType.REACTION, {
      recordingId: payload.recordingId,
    });
  }
}
