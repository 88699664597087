import { PropsWithChildren } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { useRMDialog } from './RMDialog.context';
import { Content } from './RMDialogContent.styles';

export type RMDialogContentProps = PropsWithChildren<{
  className?: string;
}>;

const animations = {
  mobile: {
    translateY: ['100%', '0%'],
  },
  desktop: {
    opacity: [0, 1],
  },
};

export function RMDialogContent({ className, children }: RMDialogContentProps) {
  const { variant } = useRMDialog();
  const mobile = useIsMobileViewport();

  const screenVariant = mobile ? `${variant}-mobile` : variant;

  return (
    <Content
      key={mobile ? 'mobile' : 'desktop'}
      animate={mobile && variant !== 'full-screen' ? 'mobile' : 'desktop'}
      transition={{ duration: 0.25, ease: 'easeOut' }}
      variants={animations}
      data-mobile={mobile}
      data-variant={screenVariant}
      className={className}
    >
      {children}
    </Content>
  );
}
