export async function writeToClipboard(value: string | PromiseLike<string>): Promise<void> {
  if (typeof window.ClipboardItem === 'undefined') {
    await navigator.clipboard.writeText(await value);
    return;
  }

  // We cannot await before calling clipboard.write in iOS safari, it might be denied.
  // Chrome does not support writing strings using the write api. We need to convert it to a blob.
  let text: Blob | PromiseLike<Blob>;
  if (typeof value !== 'string') {
    text = value.then((textContent) => new Blob([textContent], { type: 'text/plain' }));
  } else {
    text = new Blob([value], { type: 'text/plain' });
  }
  await navigator.clipboard.write([new ClipboardItem({ 'text/plain': text })]);
}
