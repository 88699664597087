import EmailValidator from 'email-validator';
import { z } from 'zod';

import { Form } from '../form/@types';
import { createForm } from '../form/form';

const referralFormSchema = z.object({
  email: z.string().refine(
    (email) => {
      return EmailValidator.validate(email);
    },
    { message: 'Enter a valid email address' },
  ),
});

export type ReferralFormInput = z.input<typeof referralFormSchema>;
export type ReferralFormOutput = z.output<typeof referralFormSchema>;
export type ReferralForm = Form<ReferralFormInput, ReferralFormOutput>;

export function createReferralForm() {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues: { email: '' },
    schema: referralFormSchema,
  });
}
