import { PublicAclGroup, PublicAclGroupMember } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';
import { notNull } from '@remento/utils/array/notNull';
import { QueryClient } from '@tanstack/react-query';

import { EntityCacheManagerService } from '../cache/entity-cache-manager.types';

import { AclCacheService, AclService, ValidateTokenResponse } from './acl.types';

export class DefaultAclCacheService implements AclCacheService {
  constructor(
    private remoteService: AclService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  getCurrentUserAclGroupMembers(): Promise<Array<string>> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.ACL_GROUP_MEMBER, { user: 'signed-in-user' }, () =>
        this.remoteService.getUserGroupMembers(),
      ),
    );
  }

  getAclGroupMember(aclGroupMemberId: string): Promise<PublicAclGroupMember | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.ACL_GROUP_MEMBER, aclGroupMemberId, () =>
        this.remoteService.getGroupMember(aclGroupMemberId),
      ),
    );
  }

  getAclGroup(aclGroupId: string): Promise<PublicAclGroup | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.ACL_GROUP, aclGroupId, () =>
        this.remoteService.getGroup(aclGroupId),
      ),
    );
  }

  async getPrimaryAclGroup(aclGroupIds: Array<string>, entityType: EntityType): Promise<PublicAclGroup | null> {
    const aclGroups = await Promise.all(aclGroupIds.map((aclGroupId) => this.getAclGroup(aclGroupId)));
    return aclGroups.filter(notNull).find((aclGroup) => aclGroup.type === entityType) ?? null;
  }

  async deleteGroupMember(aclGroupId: string, aclMemberId: string): Promise<void> {
    const res = await this.remoteService.deleteGroupMember(aclMemberId);
    await this.entityCacheManagerService.cacheResponse(res);

    // Invalidate the acl-group entity to refresh the members list.
    await this.entityCacheManagerService.invalidateEntity(EntityType.ACL_GROUP, aclGroupId);
  }

  async deleteGroupInvitee(aclGroupId: string, aclGroupInviteeId: string): Promise<void> {
    const res = await this.remoteService.deleteGroupInvitee(aclGroupInviteeId);
    await this.entityCacheManagerService.cacheResponse(res);

    // Invalidate the acl-group-invite collection.
    await this.entityCacheManagerService.invalidateCollection(EntityType.ACL_GROUP_INVITEE, { aclGroupId });
  }

  async joinGroup(token: string): Promise<ValidateTokenResponse> {
    const res = await this.remoteService.joinGroup(token);
    // Invalidate the project cache
    await this.entityCacheManagerService.invalidateCollection(EntityType.PROJECT, {});
    return res;
  }

  async sendEmailInvite(aclGroupId: string, emails: string[]): Promise<void> {
    const res = await this.remoteService.sendEmailInvite(aclGroupId, emails);
    await this.entityCacheManagerService.cacheResponse(res);
  }
}
