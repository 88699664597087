import { AnalyticsService, Events } from '../analytics.types';

import { QuestionnaireAnalyticsService } from './questionnaire-analytics.types';

export class DefaultQuestionnaireAnalyticsService implements QuestionnaireAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onQuestionnaireArrived(): void {
    this.analyticsService.track(Events.QuestionnaireArrive);
  }

  onQuestionnaireQuestionArrived(question: string): void {
    this.analyticsService.track(Events.QuestionnaireQuestionArrive, { question });
  }

  onQuestionnaireQuestionSelected(question: string, option: string): void {
    this.analyticsService.track(Events.QuestionnaireQuestionSelect, { question, option });
  }

  onQuestionnaireQuestionDeselected(question: string, option: string): void {
    this.analyticsService.track(Events.QuestionnaireQuestionDeselect, { question, option });
  }

  onQuestionnaireResultArrived(question: string): void {
    this.analyticsService.track(Events.QuestionnaireResultArrive, { question });
  }

  onQuestionnaireSkipped(): void {
    this.analyticsService.track(Events.QuestionnaireSkip);
  }
}
