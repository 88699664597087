import { useCallback } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

export type NavbarStatus = 'open' | 'opening' | 'closing' | 'closed';

export interface NavbarState {
  projectId: string | null;
  status: NavbarStatus;
}

export type NavbarStore = StoreApi<NavbarState>;

export function createNavbarStore(): NavbarStore {
  return createStore<NavbarState>(() => ({ projectId: null, status: 'closed' }));
}

export function setNavbarProjectId(store: NavbarStore, projectId: string): void {
  store.setState({ projectId });
}

export function getNavbarProjectId(store: NavbarStore): string | null {
  return store.getState().projectId;
}

export function useNavbarProjectId(store: NavbarStore): string | null {
  return useStore(
    store,
    useCallback(({ projectId }) => projectId, []),
  );
}

export function openNavbar(store: NavbarStore): void {
  store.setState({ status: 'opening' });
}

export function closeNavbar(store: NavbarStore): void {
  store.setState({ status: 'closing' });
}

export function setNavbarStatus(store: NavbarStore, status: NavbarStatus): void {
  store.setState({ status });
}

export function getNavbarStatus(store: NavbarStore): NavbarStatus {
  return store.getState().status;
}

export function useNavbarStatus(store: NavbarStore): NavbarStatus {
  return useStore(
    store,
    useCallback((s) => s.status, []),
  );
}
