import { DeepPartial } from '@/modules/form/@types-utils';
import { isInputValue } from '@/modules/form/utils/value';

function isObject(value: unknown): value is NonNullable<object> {
  return value != null && typeof value == 'object' && !Array.isArray(value);
}

function getObjectId(value: unknown): string | number | null {
  if (!isObject(value)) {
    return null;
  }
  // Things get a bit ugly here since we're dealing with unknown array items
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const id = value.id as unknown;
  return typeof id == 'string' || typeof id == 'number' ? id : null;
}

function mergeArrays<T extends unknown[]>(items: T, mergeItems: DeepPartial<T>): T {
  if (mergeItems == null) {
    // There isn't much we can do about this. We have to assume that T is nullable and there is no way
    // to tell typescript.
    return mergeItems as T;
  }
  if (!Array.isArray(mergeItems)) {
    // There isn't much we can do about this. We have to assume that T is a union that supports the value
    // provided as merge item
    return mergeItems as T;
  }
  // Merging arrays is tricky because we need to decide if the array should be changed at all. If it does need
  // to change, we need to make sure is item is merged appropriately if the order changed
  const itemsById = items.reduce((m: Map<string | number, unknown>, i) => {
    const id = getObjectId(i);
    if (id == null) return m;
    else return m.set(id, i);
  }, new Map());
  // Merge the items from the merge array into the items from the original array
  const mergedItems = mergeItems.map((mi) => {
    const id = getObjectId(mi);
    if (id == null || mi == null) return mi;
    const i = itemsById.get(id);
    return i == null ? mi : merge(i, mi);
  }) as T & unknown[];
  // Decide if anything changed at all
  if (items.length !== mergeItems.length) return mergedItems;
  for (let i = 0; i < mergedItems.length; i++) {
    if (items[i] !== mergedItems[i]) {
      return mergedItems;
    }
  }
  return items;
}

export function mergeObjects<T extends object>(obj: T, mergeObj: DeepPartial<T>): T {
  const updatedProps: { [K in keyof T]?: T[K] } = {};
  const deletedProps: Array<keyof T> = [];

  Object.keys(mergeObj).forEach((p) => {
    const prop = p as keyof DeepPartial<T>;
    const value = obj[prop];
    const mergeValue = mergeObj[prop];
    if (value == null) {
      updatedProps[prop] = mergeValue as T[keyof T];
      return;
    }
    if (mergeValue === undefined) {
      deletedProps.push(prop);
      return;
    }
    const mergedValue = merge(value, mergeValue as DeepPartial<T[keyof T]>);
    if (value == mergedValue) {
      return;
    }
    updatedProps[prop] = mergedValue as T[keyof T];
  });
  if (Object.keys(updatedProps).length == 0 && deletedProps.length == 0) {
    return obj;
  }
  const updatedObj: T = {
    ...obj,
    ...updatedProps,
  };
  deletedProps.forEach((prop) => {
    delete updatedObj[prop];
  });
  return updatedObj;
}

export function merge<T>(item: T, mergeItem: DeepPartial<T>): T {
  if (item === mergeItem) {
    return item;
  }
  if (isInputValue(item) || isInputValue(mergeItem)) {
    return mergeItem as T;
  }
  if (Array.isArray(item) && Array.isArray(mergeItem)) {
    return mergeArrays(item, mergeItem as DeepPartial<T & unknown[]>) as T;
  }
  if (!Array.isArray(item) && isObject(item) && isObject(mergeItem)) {
    return mergeObjects(item, mergeItem as DeepPartial<T>) as T;
  }
  throw new Error('Congratulation, you reached unreachable code while merging');
}
