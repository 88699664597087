import { LocalStoreRepository } from '@/services/local/local-store';
import { AuthRepository, AuthStateTransition, AuthStateType } from './auth.types';

export const AUTH_STATE_KEY = '@remento/auth/state';
export const AUTH_PENDING_TRANSITION_KEY = '@remento/auth/pending-transition';
export const AUTH_SESSION_ID_KEY = '@remento/session-id';

export class AuthRepositoryImpl implements AuthRepository {
  constructor(private persistentStorageRepository: LocalStoreRepository) {}

  getPersistedPendingStateTransition(): AuthStateTransition | null {
    return this.persistentStorageRepository.getItem(AUTH_PENDING_TRANSITION_KEY);
  }

  persistStateTransition(transition: AuthStateTransition): void {
    this.persistentStorageRepository.setItem(AUTH_PENDING_TRANSITION_KEY, transition);
  }

  deletePersistentStateTransition(): void {
    this.persistentStorageRepository.removeItem(AUTH_PENDING_TRANSITION_KEY);
  }

  getPersistedState(): AuthStateType | null {
    return this.persistentStorageRepository.getItem(AUTH_STATE_KEY) as AuthStateType | null;
  }

  persistState(state: AuthStateType): void {
    this.persistentStorageRepository.setItem(AUTH_STATE_KEY, state);
  }

  getSessionId(): string | null {
    return this.persistentStorageRepository.getItem(AUTH_SESSION_ID_KEY);
  }

  persistSessionId(sessionId: string): void {
    this.persistentStorageRepository.setItem(AUTH_SESSION_ID_KEY, sessionId);
  }
}
