import EmailValidator from 'email-validator';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

const PersonBodySchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});
const UserBodySchema = z.object({
  person: PersonBodySchema,
  email: z.string().refine(EmailValidator.validate, { message: 'Enter a valid email address' }),
});

export function createOnboardingFormSchema() {
  return z.object({
    recipient: UserBodySchema,
    gift: z.object({
      from: z.string().min(1),
      message: z.string().min(1),
      sendOn: z.string().transform((dateStringified, ctx) => {
        if (!dateStringified) {
          ctx.addIssue({ code: 'invalid_date', message: 'Select today or a future date' });
          return -1;
        }

        const date = new Date(dateStringified);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date.getTime() < today.getTime()) {
          ctx.addIssue({ code: 'invalid_date', message: 'Select today or a future date' });
          return -1;
        }

        return date.getTime();
      }),
    }),
  });
}

export type OnboardingFormInput = z.input<ReturnType<typeof createOnboardingFormSchema>>;
export type OnboardingFormOutput = z.output<ReturnType<typeof createOnboardingFormSchema>>;

export type OnboardingForm = Form<OnboardingFormInput, OnboardingFormOutput>;

export function createOnboardingForm(defaultValues?: DeepPartial<OnboardingFormInput>): OnboardingForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createOnboardingFormSchema(),
  });
}
