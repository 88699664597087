import { z } from 'zod';

import { BaseEntity, BaseMutation } from './base-entity';
import { StoryPerspectiveType } from './story';
import { SubscriptionRenewalType, UserSubscriptionStatus } from './user';

// **** PROJECT **** //

export enum ProjectStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  PAUSED = 'paused',
  COMPLETED = 'completed',
  DELETED = 'deleted',
}

export interface ProjectPromptNotifications {
  /**
   * The cron string that determines the interval at which prompts are sent
   */
  interval: string;

  /**
   * The IDs of the people receiving a notification every week.
   */
  recipientPersonIds: Array<string>;
}

export enum ProjectType {
  BIOGRAPHY = 'biography',
  BABY = 'baby',
}

export interface ProjectGiftSettings {
  /**
   * The message that should be sent to the recipient(s) when
   * the gift is sent
   */
  message: string;

  /**
   * A string that represents who send the gift. It's not strucured
   * to provide additional flexibility, for example Alex, Your Grandchidlren,
   * Nana & Papa etc...
   */
  from?: string;
}

export interface ProjectStatistics {
  /**
   * The number of prompts that were created for the project
   */
  promptsCreated: number;

  /**
   * The number of prompts that were sent to the recipients of the project.
   * This includes prompts sent automatically and manually
   */
  promptsSent: number;

  /**
   * The number of stories that were recorded
   */
  storiesRecorded: number;

  /**
   * The number of seconds of stories that were recorded for the project
   */
  storiesRecordedTime: number;
}

export interface ProjectConfiguration {
  /**
   * The type of project which dictates the rest of the configuration values
   */
  type: 'BABYBOOK' | 'AUTOBIOGRAPHY' | 'BIOGRAPHY';

  /**
   * The time period the project focuses on
   */
  timePeriod: 'PRESENT' | 'PAST';

  /**
   * The type of prompts that should be suggested to the user
   */
  topics: Array<'life' | 'baby'>;

  defaultStoryPerspective: StoryPerspectiveType;
}

export interface ProjectCredit {
  /**
   * The number of free books that can be redeemed for this project
   */
  books: number;

  /**
   * True if there are credits in the project to download the ebook
   */
  ebook?: boolean;
}

export interface Project extends BaseEntity<ProjectStatus> {
  /**
   * The name of the project
   */
  name: string;

  /**
   * The ID of the asset used for the cover of the book
   */
  coverAssetId?: string | null;

  /**
   * Settings related to gifting the messages
   */
  gift?: ProjectGiftSettings;

  /**
   * Notification settings for the project.
   */
  notifications: ProjectPromptNotifications;

  /**
   * The ID of the user that purchased the subscription
   */
  ownerUserId: string;
  /**
   * The ID of the person that purchase the subscription
   */
  ownerPersonId: string;
  /**
   * The timestamp at which the subscription starts
   */
  startsOn: number;
  /**
   * The timestamp at which the subscription ends
   */
  endsOn: number;
  /**
   * The status of the owner's subscription
   */
  subscriptionStatus: UserSubscriptionStatus;
  /**
   * The renewal type of the owner's subscription
   */
  subscriptionRenewalType: SubscriptionRenewalType | null;

  /**
   *
   */
  credit: ProjectCredit | null;

  /**
   * The configuration of the project which dictates the features available to
   * its members.
   */
  configuration: ProjectConfiguration;

  /**
   * A reference to the people that this project is about. It may overlap
   * with the list of recipients
   */
  subjectPersonIds: Array<string>;

  /**
   * Project statistics
   */
  statistics: ProjectStatistics;

  /**
   * A list of ACL groups that grant permissions on the project.
   */
  acl: string[];
}

export enum ProjectShareLinkType {
  PROJECT_RECORD = 'project-record',
}

export enum ProjectMutationType {
  SET_COMPLETED = 'set-completed',
  SET_START_ON = 'set-start-on',
  ADD_PROMPTS = 'add-prompts',
  REMOVE_PROMPTS = 'remove-prompts',
  INCREMENT_RECORDED_STORY = 'increment-recorded-story',
  DECREMENT_RECORDED_STORY = 'decrement-recorded-story',
  SET_GIFT_MESSAGE = 'set-gift-message',
  SET_ACTIVE = 'set-active',
  SET_PAUSED = 'set-paused',
  ADD_SENT_PROMPT = 'add-sent-prompt',
  INCREMENT_RECORDED_TIME = 'increment-recorded-time',
  SET_NAME = 'set-name',
  SET_COVER_ASSET = 'set-cover-asset',
  DECREMENT_CREDIT = 'decrement-project-credit',
  INCREMENT_CREDIT = 'increment-project-credit',
  SET_EBOOK_CREDIT = 'set-ebook-credit',
  SET_DEFAULT_STORY_PERSPECTIVE = 'set-default-story-perspective',
  SET_END_ON = 'set-end-on',
  STATIC_SET_START_ON = 'static-set-start-on',
  SET_SUBSCRIPTION_STATUS = 'set-subscription-status',
  SET_SUBSCRIPTION_RENEWAL_TYPE = 'set-subscription-renewal-type',
}

export type SetProjectCompletedMutation = BaseMutation<ProjectMutationType.SET_COMPLETED, undefined>;
export type SetProjectStartOnMutation = BaseMutation<ProjectMutationType.SET_START_ON, number>;
export type AddProjectPromptMutation = BaseMutation<ProjectMutationType.ADD_PROMPTS, number>;
export type RemoveProjectPromptMutation = BaseMutation<ProjectMutationType.REMOVE_PROMPTS, number>;
export type IncrementProjectRecordedStoryMutation = BaseMutation<
  ProjectMutationType.INCREMENT_RECORDED_STORY,
  undefined
>;
export type DecrementProjectRecordedStoryMutation = BaseMutation<
  ProjectMutationType.DECREMENT_RECORDED_STORY,
  undefined
>;
export type SetProjectGiftMessageMutation = BaseMutation<
  ProjectMutationType.SET_GIFT_MESSAGE,
  { from: string; message: string }
>;
export type SetProjectActiveMutation = BaseMutation<ProjectMutationType.SET_ACTIVE, null>;
export type SetProjectPausedMutation = BaseMutation<ProjectMutationType.SET_PAUSED, null>;
export type AddProjectSentPromptMutation = BaseMutation<ProjectMutationType.ADD_SENT_PROMPT, undefined>;
export type IncrementProjectRecordedTimeMutation = BaseMutation<ProjectMutationType.INCREMENT_RECORDED_TIME, number>;
export type SetProjectNameMutation = BaseMutation<ProjectMutationType.SET_NAME, string>;
export type SetProjectCoverAssetMutation = BaseMutation<ProjectMutationType.SET_COVER_ASSET, string>;
export type DecrementProjectCreditsMutation = BaseMutation<ProjectMutationType.DECREMENT_CREDIT, number>;
export type IncrementProjectCreditsMutation = BaseMutation<ProjectMutationType.INCREMENT_CREDIT, number>;
export type SetProjectEBookCreditMutation = BaseMutation<ProjectMutationType.SET_EBOOK_CREDIT, boolean>;
export type SetDefaultProjectStoryPerspectiveMutation = BaseMutation<
  ProjectMutationType.SET_DEFAULT_STORY_PERSPECTIVE,
  StoryPerspectiveType
>;
export type SetProjectEndOnMutation = BaseMutation<ProjectMutationType.SET_END_ON, number>;
export type StaticSetProjectStartOnMutation = BaseMutation<ProjectMutationType.STATIC_SET_START_ON, number>;
export type SetProjectSubscriptionStatusMutation = BaseMutation<
  ProjectMutationType.SET_SUBSCRIPTION_STATUS,
  UserSubscriptionStatus
>;
export type SetProjectSubscriptionRenewalTypeMutation = BaseMutation<
  ProjectMutationType.SET_SUBSCRIPTION_RENEWAL_TYPE,
  SubscriptionRenewalType | null
>;

export type ProjectMutation =
  | SetProjectCompletedMutation
  | SetProjectStartOnMutation
  | AddProjectPromptMutation
  | RemoveProjectPromptMutation
  | IncrementProjectRecordedStoryMutation
  | DecrementProjectRecordedStoryMutation
  | SetProjectGiftMessageMutation
  | SetProjectActiveMutation
  | SetProjectPausedMutation
  | AddProjectSentPromptMutation
  | IncrementProjectRecordedTimeMutation
  | SetProjectNameMutation
  | SetProjectCoverAssetMutation
  | DecrementProjectCreditsMutation
  | IncrementProjectCreditsMutation
  | SetProjectEBookCreditMutation
  | SetDefaultProjectStoryPerspectiveMutation
  | SetProjectEndOnMutation
  | StaticSetProjectStartOnMutation
  | SetProjectSubscriptionStatusMutation
  | SetProjectSubscriptionRenewalTypeMutation;

export const ProjectMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(ProjectMutationType.SET_START_ON),
    value: z.number().int(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(ProjectMutationType.SET_GIFT_MESSAGE),
    value: z.object({
      from: z.string(),
      message: z.string(),
    }),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(ProjectMutationType.SET_ACTIVE),
    value: z.null(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(ProjectMutationType.SET_PAUSED),
    value: z.null(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(ProjectMutationType.SET_NAME),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(ProjectMutationType.SET_COVER_ASSET),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(ProjectMutationType.SET_DEFAULT_STORY_PERSPECTIVE),
    value: z.nativeEnum(StoryPerspectiveType),
    vclock: z.number(),
    version: z.number(),
  }),
]);

// **** PROMPT **** //

export enum PromptStatus {
  PENDING = 'pending',
  SENT = 'sent',
  DELETED = 'deleted',
  DONE = 'done',
}

export enum PromptType {
  PHOTO = 'photo',
  TEXT = 'text',
}

export interface BasePrompt extends BaseEntity<PromptStatus> {
  /**
   * The ID of the project the prompt belongs to
   */
  projectId: string;

  /**
   * The id of the person that created the prompt.
   */
  requesterPersonId: string;

  /**
   * The ID of the people who should receive the prompt
   */
  recipientPersonIds: string[];

  /**
   * The index of the prompt relative to other prompts. There are no guarantee
   * that indexes are unique or sequential.
   */
  index: number;

  /**
   * The timestamp when the prompt was sent to the recipients
   */
  sentOn?: number | null;

  /**
   * A list of ACL groups that grant permissions on the project.
   */
  acl: string[];
  /**
   * The ID of the poll that created this prompt.
   */
  pollId?: string | null;
}

export interface TextPromptTemplate {
  id: string;
  tagIds: string[];
}

export interface TextPrompt extends BasePrompt {
  type: PromptType.TEXT;
  template: TextPromptTemplate | null;
}

export interface PhotoPrompt extends BasePrompt {
  type: PromptType.PHOTO;
  imagesIds: string[];
}

export type Prompt = TextPrompt | PhotoPrompt;

export enum PromptMutationType {
  SET_SENT = 'set-sent',
  SEND = 'send',
  SET_DONE = 'set-done',
  MOVE = 'move',
  ADD_IMAGE = 'add-image',
  REMOVE_IMAGE = 'remove-image',
}

export type SetPromptSentMutation = BaseMutation<PromptMutationType.SET_SENT, number>;
export type SendPromptMutation = BaseMutation<PromptMutationType.SEND, number>;
export type SetPromptDoneMutation = BaseMutation<PromptMutationType.SET_DONE, null>;
export type MovePromptMutation = BaseMutation<PromptMutationType.MOVE, number>;
export type AddPromptImageMutation = BaseMutation<PromptMutationType.ADD_IMAGE, string>;
export type RemovePromptImageMutation = BaseMutation<PromptMutationType.REMOVE_IMAGE, string>;

export type PromptMutation =
  | SetPromptSentMutation
  | SendPromptMutation
  | SetPromptDoneMutation
  | MovePromptMutation
  | AddPromptImageMutation
  | RemovePromptImageMutation;

export const PromptMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PromptMutationType.MOVE),
    value: z.number().int(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(PromptMutationType.ADD_IMAGE),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(PromptMutationType.REMOVE_IMAGE),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
  z.object({
    type: z.literal(PromptMutationType.SEND),
    value: z.number(),
    vclock: z.number(),
    version: z.number(),
  }),
]);

// **** QUESTION **** //

export enum QuestionStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export enum QuestionType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
  AI_FOLLOW_UP = 'ai-follow-up',
}

export interface Question extends BaseEntity<QuestionStatus> {
  seriesId: string;
  promptId: string;
  templateId: string | null;
  type: QuestionType;
  text: string | null;
  acl: string[];
}

export enum QuestionMutationType {
  SET_TEXT = 'set-text',
}

export type SetQuestionTextMutation = BaseMutation<QuestionMutationType.SET_TEXT, string | null>;

export type QuestionMutation = SetQuestionTextMutation;

export const QuestionMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(QuestionMutationType.SET_TEXT),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
]);
