import { faHome } from '@fortawesome/pro-solid-svg-icons';

import LockIcon from '@/assets/icons/lock.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMError } from '@/components/RMError';
import { RMText } from '@/components/RMText/RMText';

import { SignedInUser } from '../../containers/UnauthorizedPage.container';

import { LogoutLink } from './UnauthorizedPage.styles';

export interface UnauthorizedPageProps {
  isRecordingPage: boolean;
  user: SignedInUser | null;
  onRedirect: () => void;
  onSignOut: (redirect?: boolean) => Promise<void>;
}

export function UnauthorizedPage({ isRecordingPage, user, onRedirect, onSignOut }: UnauthorizedPageProps) {
  return (
    <RMError.Root
      icon={LockIcon}
      title="Access denied"
      Action={
        <RMButton leftIcon={user ? faHome : null} onClick={onRedirect}>
          {user ? 'Return home' : 'Sign in'}
        </RMButton>
      }
      Message={
        <>
          <RMError.Text>
            {isRecordingPage
              ? 'You don’t have access to this recording link. Either tap the link sent to you via email or press “Record” in Remento.'
              : 'You don’t have permission to access this page.'}
          </RMError.Text>
          {user && (
            <RMText align="center" type="sans" size="s" color="on-surface-primary">
              Not{' '}
              <RMText type="sans" bold size="s" color="on-surface-primary">
                {user?.name?.first} ({user.email})
              </RMText>
              ? <br />
              <LogoutLink onClick={() => onSignOut(false)}>Log out</LogoutLink>.
            </RMText>
          )}
        </>
      }
    />
  );
}
