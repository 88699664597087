import { fetchEventSource } from '@microsoft/fetch-event-source';
import { DeleteMutation } from '@remento/types/base-entity';
import { EntityResponse, EntityType } from '@remento/types/entity';
import {
  AddStoryImageMutation,
  RemoveStoryImageMutation,
  SetStorySummaryMutation,
  SetStoryTitleMutation,
  Story,
  StoryDataType,
  StoryGenerateSummaryPayload,
  StoryMutationType,
  StoryShareLinkType,
} from '@remento/types/story';

import { logger } from '@/logger';

import { api } from '../api';
import { RequestScope } from '../api.types';
import { AuthorizationService } from '../authorization';
import { EntityMutation } from '../cache';

import { StoryService } from './story.types';

export class DefaultStoryService implements StoryService {
  constructor(private authorizationService: AuthorizationService) {}

  async getStory(storyId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/stories/${storyId}`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getProjectStories(projectId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/projects/${projectId}/stories`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async getPromptStory(promptId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/prompts/${promptId}/stories`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  async regenerateShareLink(storyId: string): Promise<string> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<{ link: string }>(`/stories/${storyId}/share-link`, null, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data.link;
  }

  async getShareLink(
    storyId: string,
    type: StoryShareLinkType.SOCIAL | StoryShareLinkType.HIGHLIGHT,
    scope?: RequestScope,
  ): Promise<string> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    credentialsForRequest.params.set('type', type);
    const { data } = await api.get<{ link: string }>(`/stories/${storyId}/share-link`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data.link;
  }

  async getStoryRecordByPromptId(promptId: string, scope?: RequestScope): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<EntityResponse>(`/prompts/${promptId}/story/record`, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
      signal: scope?.signal,
    });
    return data;
  }

  createSetStoryTitleMutation(
    story: Story,
    value: string,
    dataType: StoryDataType,
    settings: Record<string, string>,
  ): EntityMutation<SetStoryTitleMutation>[] {
    return [
      {
        type: EntityType.STORY,
        id: story.id,
        mutations: [
          {
            type: StoryMutationType.SET_TITLE,
            value: {
              type: dataType,
              value,
              settings,
            },
            vclock: story.vclock,
            version: story.version,
          },
        ],
      },
    ];
  }

  createSetStorySummaryMutation(
    story: Story,
    value: string,
    dataType: StoryDataType,
    settings: Record<string, string>,
  ): EntityMutation<SetStorySummaryMutation>[] {
    return [
      {
        type: EntityType.STORY,
        id: story.id,
        mutations: [
          {
            type: StoryMutationType.SET_SUMMARY,
            value: {
              type: dataType,
              value,
              settings,
            },
            vclock: story.vclock,
            version: story.version,
          },
        ],
      },
    ];
  }

  createDeleteStoryMutation(story: Story): DeleteMutation {
    return {
      type: 'delete',
      value: undefined,
      vclock: story.vclock,
      version: story.version,
    };
  }

  async deleteStory(projectId: string, mutation: DeleteMutation): Promise<EntityResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<EntityResponse>(`/stories/${projectId}/delete`, mutation, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  createDeleteStoryPhotoMutation(story: Story, removedAssetId: string): EntityMutation<RemoveStoryImageMutation>[] {
    return [
      {
        type: EntityType.STORY,
        id: story.id,
        mutations: [
          {
            type: StoryMutationType.REMOVE_IMAGE,
            value: removedAssetId,
            vclock: story.vclock,
            version: story.version,
          },
        ],
      },
    ];
  }

  createAddStoryPhotoMutation(story: Story, newAssetId: string): EntityMutation<AddStoryImageMutation>[] {
    return [
      {
        type: EntityType.STORY,
        id: story.id,
        mutations: [
          {
            type: StoryMutationType.ADD_IMAGE,
            value: newAssetId,
            vclock: story.vclock,
            version: story.version,
          },
        ],
      },
    ];
  }

  async generateStorySummary(
    storyId: string,
    payload: StoryGenerateSummaryPayload,
    onMessageText: (textChunk: string) => void,
    signal?: AbortSignal,
  ) {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    await fetchEventSource(
      `${api.getUri()}/story/${storyId}/summary/generate?perspective=${payload.perspective}&length=${payload.length}`,
      {
        onmessage({ data }) {
          onMessageText(JSON.parse(data));
        },
        async onopen(res) {
          if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            logger.debug('CLIENT_SIDE_ERROR', res);
            throw new Error('Client side error');
          }
        },
        // This will prevent the connection from stopping receiving events when the page is inactive.
        openWhenHidden: true,
        method: 'GET',
        headers: {
          Authorization: `${credentialsForRequest.headers.Authorization}`,
          'Content-Type': 'text/event-stream',
        },
        signal,
      },
    );
  }
}
