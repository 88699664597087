import { z } from 'zod';

import { BaseEntity, BaseMutation } from './base-entity';

export enum PollVoteStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export interface PollVote extends BaseEntity<PollVoteStatus> {
  pollId: string;
  projectId: string;
  promptId: string;
  personId: string | null;
}

export enum PollVoteMutationType {
  SET_PERSON = 'set-person',
}

export type SetPollVotePerson = BaseMutation<PollVoteMutationType.SET_PERSON, string>;

export type PollVoteMutation = SetPollVotePerson;

export const PollVoteMutationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PollVoteMutationType.SET_PERSON),
    value: z.string(),
    vclock: z.number(),
    version: z.number(),
  }),
]);
