import { SanityClient } from '@sanity/client';

import { CmsService } from '../cms/cms.service';

import { PromptTemplateTag, PromptTemplateTagsService } from './prompt-template-tags.types';

export class PromptTemplateTagsServiceImpl extends CmsService<PromptTemplateTag> implements PromptTemplateTagsService {
  constructor(client: SanityClient) {
    super(client, {
      entity: 'prompt-template-tag',
      additionalFields: {
        id: '_id',
        imageUrl: 'image.asset->url',
      },
      sort: ['position asc'],
    });
  }

  getTags(): Promise<PromptTemplateTag[]> {
    return this.fetchAll();
  }

  async getTag(id: string): Promise<PromptTemplateTag | null> {
    const tags = await this.fetchAll([`_id == "${id}"`]);
    if (tags.length) {
      return tags[0];
    }
    return null;
  }
}
