import createClient, { ClientConfig, SanityClient } from '@sanity/client';

const sanityConfig: ClientConfig = {
  projectId: 'el2u1fuu',
  dataset: import.meta.env.VITE_SANITY_DATASET ?? 'book-dev',
  apiVersion: '2023-08-11',
  useCdn: true,
};

export function createSanityClient(): SanityClient {
  return createClient(sanityConfig);
}
