import { UserIdentifier } from '@/services/api/user/user.types';
import { LocalStoreRepository } from '@/services/local/local-store';

import { RementoIdChannelType, RementoIdService } from '../api/remento-id/remento-id.types';

import { AnalyticsRepository, AnalyticsService, AnalyticsUserProps, Events, Page } from './analytics.types';
import { AnalyticsPropertyKey, AnalyticsPropertyRepository } from './analytics-property/analytics-property.types';

export const LOCAL_STORE_RUID_KEY = 'ruid';
export const LOCAL_STORE_TEMP_RUID_KEY = 'ruid-temp';

export class AnalyticsServiceImpl implements AnalyticsService {
  constructor(
    private analyticsRepositories: AnalyticsRepository[],
    private analyticsPropertyRepository: AnalyticsPropertyRepository,
    private rementoIdService: RementoIdService,
    private localStore: LocalStoreRepository,
    private source: string,
  ) {}

  initialize(userIdentifier: UserIdentifier): void {
    this.analyticsRepositories.forEach((repository) => repository.initialize(userIdentifier));
  }

  async identify(ruid: string, userProps: AnalyticsUserProps, temporary = false): Promise<void> {
    if (temporary) {
      this.localStore.setItem(LOCAL_STORE_TEMP_RUID_KEY, ruid);
    } else {
      this.localStore.setItem(LOCAL_STORE_RUID_KEY, ruid);
      if (this.localStore.getItem(LOCAL_STORE_TEMP_RUID_KEY) != null) {
        // We already have a temporary ID which takes precedence over permanent RUID.
        return;
      }
    }
    this.analyticsRepositories.forEach((repository) => repository.identify(ruid, userProps, true));
  }

  async identifyEmail(email: string, userProps: AnalyticsUserProps) {
    // This method is called when the user checks out or signs-in, any temporary RUID is now irrelevant.
    this.localStore.removeItem(LOCAL_STORE_TEMP_RUID_KEY);
    const ruid = await this.rementoIdService.getId(RementoIdChannelType.EMAIL, email);
    await this.identify(ruid, userProps);
  }

  reset() {
    this.localStore.removeItem(LOCAL_STORE_TEMP_RUID_KEY);
    this.localStore.removeItem(LOCAL_STORE_RUID_KEY);
    this.analyticsRepositories.forEach((repository) => repository.reset());
  }

  track(event: Events, payload?: Record<string, unknown>): void {
    const properties = this.getAllProperties();
    this.analyticsRepositories.forEach((repository) =>
      repository.track(event, {
        ...properties,
        ...payload,
        source: this.source,
      }),
    );
  }

  page(page: Page, payload?: Record<string, unknown>): void {
    const properties = this.getAllProperties();
    this.analyticsRepositories.forEach((repository) =>
      repository.page(page, {
        ...properties,
        ...payload,
        source: this.source,
      }),
    );
  }

  setProperty(key: AnalyticsPropertyKey, value: string): void {
    this.analyticsPropertyRepository.set(key, value);
  }

  setProperties(properties: Record<AnalyticsPropertyKey, string>): void {
    this.analyticsPropertyRepository.multiSet(properties);
  }

  getProperty(key: AnalyticsPropertyKey): string | null {
    return this.analyticsPropertyRepository.get(key);
  }

  getAllProperties(): Record<string, string> | null {
    return this.analyticsPropertyRepository.getAll();
  }

  getRuid(): string | null {
    return this.localStore.getItem(LOCAL_STORE_TEMP_RUID_KEY) ?? this.localStore.getItem(LOCAL_STORE_RUID_KEY);
  }
}
