import { ReactionSentiment } from '@remento/types/reaction';

import { AnalyticsService, Events } from '../analytics.types';

import {
  ReactionSentimentDescription,
  ReactionSource,
  StoriesAction,
  StoryPage,
  StoryShareTarget,
  StoryType,
  StoryViewerAnalytics,
  StoryViewerSummaryAction,
} from './story-viewer-analytics.types';

export class DefaultStoryViewerAnalytics implements StoryViewerAnalytics {
  constructor(private analyticsService: AnalyticsService) {}

  onStoryArrived(): void {
    this.analyticsService.track(Events.StoryViewerArrive, {});
  }

  onStoryWatched(duration: number, type: StoryType): void {
    this.analyticsService.track(Events.StoryViewerWatch, {
      duration,
      type,
    });
  }

  onStoryShared(page: StoryPage, target: StoryShareTarget): void {
    this.analyticsService.track(Events.StoryViewerShare, { page, target });
  }

  onStoryMuted(type: 'mute-on' | 'mute-off'): void {
    this.analyticsService.track(Events.StoryViewerMute, {
      type,
    });
  }

  onStoryLiked(): void {
    this.analyticsService.track(Events.StoryViewerLike, {});
  }

  onStoryDownloaded(type: StoryType): void {
    this.analyticsService.track(Events.StoryViewerDownload, {
      type,
    });
  }

  onStorySummaryViewed(): void {
    this.analyticsService.track(Events.StoryViewerSummaryView, {});
  }

  onStorySummaryEdited(): void {
    this.analyticsService.track(Events.StoryViewerSummaryEdit, {});
  }

  onStorySummaryActionPerformed(cta: StoryViewerSummaryAction): void {
    this.analyticsService.track(Events.StoryViewerSummaryAction, {
      cta,
    });
  }

  onStoriesArrived(): void {
    this.analyticsService.track(Events.StoriesViewerArrive, {});
  }

  onStoriesOpened(): void {
    this.analyticsService.track(Events.StoriesViewerOpen, {});
  }

  onStoriesActionPerformed(action: StoriesAction): void {
    this.analyticsService.track(Events.StoriesViewerAction, {
      action,
    });
  }

  onReactionArrived(reactionSource: ReactionSource): void {
    this.analyticsService.track(Events.ReactionArrive, {
      reactionSource,
    });
  }

  onReactionSent(page: StoryPage, emoji: ReactionSentiment | null, textCustomized: boolean): void {
    const emojiDescription = emoji ? ReactionSentimentDescription[emoji] : null;

    this.analyticsService.track(Events.ReactionSent, {
      page,
      emoji: emojiDescription,
      textCustomized,
    });
  }
}
