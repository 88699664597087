import { PropsWithChildren } from 'react';

import { NavbarContainer } from '@/modules/navbar/containers/Navbar.container';

import { Container, NavbarWrapper } from './AppLayout.styles';

export function AppLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <Container>
      <NavbarWrapper>
        <NavbarContainer />
      </NavbarWrapper>

      {children}
    </Container>
  );
}
