import { SanityClient } from '@sanity/client';

import { CmsService } from '../cms/cms.service';

import { ConversionQuestionnaireService, ConversionQuestionnaireStep } from './conversion-questionnaire.types';

interface ConversionQuestionnaireStepBase {
  name: string;
  position: number;
  questionTitle: string;
  questionSubtitle?: string;
  questionOptionsType: 'radio' | 'checkbox';
  questionOptions: Array<{
    name: string;
    position: number;
    label: string;
  }>;
}

interface ConversionQuestionnaireStepSchemaDefault extends ConversionQuestionnaireStepBase {
  answerType: 'default';
  answerDefault: {
    type: 'default';
    title: string;
    subtitle: string;
    asset: {
      url: string;
      mimeType: string;
    };
    assetPlaybackMode: 'autoplay' | 'controls';
  };
}

interface ConversionQuestionnaireStepResults extends ConversionQuestionnaireStepBase {
  answerType: 'results';
  answerResults: {
    type: 'results';
    title: string;
    subtitle: string;
    reviews: Array<{
      position: number;
      title: string;
      message: string;
      author: string;
      authorAvatar: {
        url: string;
        mimeType: string;
      };
    }>;
    faqs: Array<{
      position: number;
      question: string;
      answer: string;
    }>;
  };
}

type ConversionQuestionnaireStepSchema = ConversionQuestionnaireStepSchemaDefault | ConversionQuestionnaireStepResults;

export class ConversionQuestionnaireServiceImpl
  extends CmsService<ConversionQuestionnaireStepSchema>
  implements ConversionQuestionnaireService
{
  constructor(client: SanityClient) {
    super(client, {
      entity: 'conversion-questionnaire-step',
      additionalFields: {
        id: '_id',
        answerDefault: `answerDefault {
          ...,
          "asset": asset.asset->{url, mimeType}
        }`,
        answerResults: `answerResults {
          ...,
          "reviews": reviews[] {
            ...,
            "authorAvatar": asset.asset->{url, mimeType}
          }
        }`,
      },
    });
  }

  async getAll(): Promise<ConversionQuestionnaireStep[]> {
    const steps = await super.fetchAll();
    const parsedSteps: ConversionQuestionnaireStep[] = steps.map((step) => {
      return {
        name: step.name,
        position: step.position,
        questionTitle: step.questionTitle,
        questionSubtitle: step.questionSubtitle,
        questionOptionsType: step.questionOptionsType,
        questionOptions: step.questionOptions.sort((a, b) => a.position - b.position),
        answer:
          step.answerType === 'default'
            ? {
                type: step.answerType,
                title: step.answerDefault.title,
                subtitle: step.answerDefault.subtitle,
                asset: { ...step.answerDefault.asset, assetPlaybackMode: step.answerDefault.assetPlaybackMode },
              }
            : {
                type: step.answerType,
                title: step.answerResults.title,
                subtitle: step.answerResults.subtitle,
                reviews: step.answerResults.reviews,
                faqs: step.answerResults.faqs,
              },
      };
    });

    return parsedSteps.sort((a, b) => a.position - b.position);
  }
}
