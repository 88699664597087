import { LocalStoreRepository } from './local-store.types';

export class LocalStoreRepositoryWrapper implements LocalStoreRepository {
  constructor(private prefix: string, private repository: LocalStoreRepository) {}

  getItem<T = string>(key: string): T | null {
    return this.repository.getItem(this.getItemKey(key));
  }

  setItem<T = string>(key: string, value: T): void {
    this.repository.setItem(this.getItemKey(key), value);
  }

  removeItem(key: string): void {
    this.repository.removeItem(this.getItemKey(key));
  }

  clear(): void {
    this.repository.clear(this.prefix);
  }

  keys(): string[] {
    return this.repository.keys(this.prefix);
  }

  entries<T = string>(): [string, T][] {
    return this.repository.entries(this.prefix);
  }

  private getItemKey(key: string) {
    return `${this.prefix}/${key}`;
  }
}
